.container {
    width: 100%;
    padding: 0 12px;
    margin: auto;

    span {
        color: #ff0086;
        margin-left: 8px;
        padding-bottom: 8px;
        display: inline-block;
        font-size: 12px;
    }

    h3 {
        font-size: 24px;
        margin-bottom: 24px;
    }

    textarea {
        width: 100%;
        background-color: #fff;
        height: 176px;
        border-radius: 4px;
        padding: 8px 12px;
    }
}
.label {
    margin-top: 24px;
    display: inline-block;
}

.gender_select {
    width: 100%;
}
.checkbox_container {
    label {
        margin-left: 0px;
        color: #fff;
        a {
            color: #ff0086;
            margin-left: 0px;
        }
    }
    input {
        margin-left: 0px;
        margin-right: 10px;
    }
    color: initial;
}
.btn_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.file_container {
    width: 100%;
    margin: 16px 0px;
    display: flex;
    align-items: center;

    p {
        min-width: 100px;
        flex: 1;
        height: 36px;
        padding: 0px 12px;
        background-color: #fff;
        color: #222;
        border-radius: 4px;
        display: flex;
        align-items: center;
    }

    button {
        width: 96px;
        height: 36px;
        background-color: #444;
        color: #fff;
        border-radius: 18px;
        margin-left: 10px;
        cursor: pointer;
    }
}

.file {
    height: 36px;
    width: 96px;
    overflow: hidden;
    margin-left: -96px;
    border-radius: 18px;
    opacity: 0;
    cursor: pointer;
}

.email_input {
    width: 100%;
    height: 36px;
    padding: 0px 12px;
    background-color: #fff;
    color: #222;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.title_input {
    width: 100%;
    height: 36px;
    padding: 0px 12px;
    background-color: #fff;
    color: #222;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.privacy_btn_container {
    margin-top: 60px;

    p {
        color: #aaa;
    }
}

.privacy_btn {
    width: 200px;
    background-color: transparent;
    padding: 0px 14px;
    border-radius: 30px;
    border: 1px solid #fff;
    margin: 16px 0px 32px;
}

.submit_btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px 0px;
}

.submit_btn {
    background-color: #ff0086;
    width: 158px;
    height: 42px;
    border-radius: 30px;
    margin: 0px 6px;
    color: #fff;
}

.cancel_btn {
    background-color: #555;
    width: 158px;
    height: 42px;
    border-radius: 30px;
    // border: 1px solid #fff;
    margin: 0px 6px;
}

.row {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    width: 320px;
}
