.progress_bar {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #444;
    border-radius: 4px;

    & .progress_bar_inner {
        height: 8px;
        border-radius: 4px;
        background-color: #ff0086;
        position: relative;
    }
}
