.agree_list {
    margin-bottom: 40px;
}

.agree_list .agree_box {
    display: flex;
    padding: 12px 0;
    border-bottom: 1px solid #151515;
}

.white .agree_list .agree_box {
    border-bottom: 1px solid #f3f3f3;
}

.agree_list .agree_box:last-of-type {
    border-bottom: 0;
}

.agree_list .agree_box input {
    width: 0 !important;
    height: 0 !important;
    border: 0;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    font-size: 0;
    line-height: inherit;
    letter-spacing: 0;
    color: transparent;
}

.agree_list .agree_box input[type="checkbox"] + label {
    display: block;
    position: relative;
    width: 100%;
    padding-left: 34px;
}

.agree_list .agree_box input[type="checkbox"] + label .left_box {
    display: inline-block;
    width: calc(100% - 36px);
    vertical-align: top;
    text-align: left;
    font-size: 16px;
    line-height: 36px;
    letter-spacing:-.3px;
    color:#fff;
}

.white .agree_list .agree_box input[type="checkbox"] + label .left_box {
    color:#000;
}

.agree_list .agree_box input[type="checkbox"] + label:after {
    content: '';
    width: 24px;
    height: 24px;
    border: 1px solid #444;
    border-radius: 4px;
    position: absolute;
    top: 6px;
    left: 0;
}

.agree_list .agree_box input[type="checkbox"]:checked + label:after {
    content: '';
    border: 0;
    background: url('/assets/img/btnCheckboxOn.png') no-repeat;
    background-size: contain;
}