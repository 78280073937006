// CheckPaymentInfo
.wrapper {
    width: 100%;
    padding: 100px 12px 36px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .title {
        width: 100%;
        font-size: 3.57em;
        font-weight: 900;
        text-align: center;
        color: #232323;
    }

    .announce {
        width: 100%;
        padding: 52px 0 32px;
        font-size: 1.78em;
        text-align: center;
        color: #232323;
    }
}

.form {
    width: 100%;
    padding: 0 0 48px;

    .next_button {
        width: 100%;
        padding: 20px 0;
        border-radius: 28px;
        margin: 40px 0 0;

        .next_button_text {
            font-size: 2.14em;
            font-weight: 900;
            color: #fffefe;
        }
    }
}

@media (max-width: 768px) {
    .wrapper {
        padding: 50px 6px 18px;

        .title {
            font-size: 1.78em;
        }

        .announce {
            padding: 26px 0 16px;
            font-size: 0.89em;
        }
    }

    .form {
        padding: 0 0 24px;

        .next_button {
            padding: 10px 0;
            border-radius: 14px;
            margin: 20px 0 0;

            .next_button_text {
                font-size: 1.07em;
            }
        }
    }
}

// CheckPaymentInfoBox
.info_wrapper {
    width: 100%;
    padding: 48px 40px;
    border: 1px solid #434343;
    border-radius: 28px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 44px;

    .detail_wrapper {
        width: 100%;

        .type {
            font-size: 1.78em;
            color: #999999;
        }

        .detail {
            padding: 16px 0 0;
            font-size: 2.14em;
            font-weight: bold;
            color: #232323;
        }

        .price_wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .tax_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tax_plus {
            padding: 0 16px;
            font-size: 2.14em;
            color: #232323;
        }

        .tax_type {
            font-size: 1.78em;
            text-align: center;
            color: #999999;
        }

        .tax_detail {
            padding: 16px 0 0;
            font-size: 2.14em;
            font-weight: bold;
            text-align: center;
            color: #232323;
        }
    }
}

@media (max-width: 768px) {
    .info_wrapper {
        padding: 24px 20px;
        border-radius: 14px;
        gap: 22px;

        .detail_wrapper {
            .type {
                font-size: 0.89em;
            }

            .detail {
                padding: 8px 0 0;
                font-size: 1.07em;
            }
        }

        .tax_wrapper {
            .tax_plus {
                padding: 0 8px;
                font-size: 1.07em;
            }

            .tax_type {
                font-size: 0.89em;
            }

            .tax_detail {
                padding: 8px 0 0;
                font-size: 1.07em;
            }
        }
    }
}

.dimm {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.7;
    z-index: 99;
}

.loading {
    display: block;
    margin: 300px auto;
    width: 84px;
    height: 84px;

    path {
        fill: #ff0086;
    }
}
