.background_transparent {
    background-color: transparent;
}

.wrapper {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info {
    padding: 20px 0;
}

.text_congrate {
    width: 100%;
    height: 30px;
    text-align: center;
    font-size: 20px;
}

.text_congrate_blank {
    width: 100%;
    height: 30px;
}

.text_item {
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 24px;
}

.text_item_blank {
    width: 100%;
    height: 40px;
}

.link {
    width: 100%;
}

.btn {
    width: 100%;
    border-radius: 50px;
}
