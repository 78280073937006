.wrapper {
    width: 100%;
    padding: 16px 12px 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}
