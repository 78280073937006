.day_list {
    position: fixed;
    width: 100%;
    max-width: 768px;
    height: 72px;
    margin: 184px 0 0;
    background-color: #252525;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.day_badge {
    min-width: 64px;
    min-height: 48px;
    border-radius: 24px;
    cursor: pointer;
    transition-duration: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #444;
    }
}
.active {
    background-color: #fff;
    &:hover {
        opacity: 1;
        background-color: #fff;
    }
}
