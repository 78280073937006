.picker_month {
  color: black;

  &.react-datepicker__month-text--keyboard-selected {
    color: white;

    &:hover {
      color: black;
    }
  }
}
