.container {
    padding: 16px 12px;

    span {
        color: #ff0086;
        margin-left: 10px;
        padding-bottom: 8px;
        display: inline-block;
    }
}

.title {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .title {
        margin-bottom: 30px;
    }
}

.withdrawal {
    text-align: right;
    color: #666;
    font-size: 14px;
    padding-bottom: 14px;
}

.main {
    margin-bottom: 60px;
}

.table_header {
    display: flex;
    align-items: center;
    background-color: #222;
    height: 48px;

    p {
        font-size: 13px;
        color: #555;
        font-weight: 600;

        &:nth-child(1) {
            width: 88px;
            text-align: center;
        }

        &:nth-child(2) {
            flex-grow: 1;
            text-align: center;
        }

        &:nth-child(3) {
            width: 128px;
            text-align: center;
        }
    }
}

@media (max-width: 768px) {
    .table_header {
        p {
            &:nth-child(1) {
                width: 40px;
            }

            &:nth-child(3) {
                width: 80px;
            }
        }
    }
}

.single_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 59px;
    border-bottom: 1px solid #222;
    min-width: 0;

    p {
        margin: 0px;
        color: #999;

        &:nth-child(1) {
            width: 88px;
            text-align: center;
            flex-shrink: 0;
        }

        &:nth-child(2) {
            flex-grow: 1;
            color: #fff;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &:nth-child(3) {
            width: 128px;
            text-align: center;
            flex-shrink: 0;
        }
    }
}

@media (max-width: 768px) {
    .single_item {
        height: 80px;
        border-bottom: 1px solid #444;

        p {
            &:nth-child(1) {
                width: 40px;
            }

            &:nth-child(2) {
                white-space: wrap;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }

            &:nth-child(3) {
                width: 80px;
            }
        }
    }
}
