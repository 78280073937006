/* For all <code> */
code {
    font-family: MyFancyCustomFont, monospace;
    font-size: inherit;
    background: #dfe3cc;
    word-wrap: break-word;
    box-decoration-break: clone;
    padding: 0.1rem 0.3rem 0.2rem;
    border-radius: 0.5rem;
}

* {
    box-sizing: border-box;
}

body {
    background-color: black;
}

.editor-input-area {
    width: 100%;
    height: 600px;
    flex: auto;
    padding: 20px 24px;
    background-color: #222;
    color: white;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media (max-width: 768px) {
    .editor-input-area {
        height: 100%;
        min-height: calc(100vh - 78px);
    }
}

.editor-input-area::placeholder {
    font-size: 14px;
    color: #666;
}
.editor-input-area ol > li {
    list-style: decimal;
}
.editor-input-area ul > li {
    list-style: initial;
}
/* width */
.editor-input-area::-webkit-scrollbar {
    width: 4px;
}
/* Handle */
.editor-input-area::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 4px;
}
/* Handle on hover */
.editor-input-area::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

blockquote {
    background: black;
    border-left: 3px solid rgb(102, 102, 102);
    margin: 1.5em 0px;
    padding: 0.5em 15px;
}

blockquote p {
    margin: 0;
}

.selection {
    position: relative;
    background-color: rgba(0, 122, 255, 0.2);
}

.selection:before {
    background-color: #007aff;
    content: '';
    height: 100%;
    left: -1px;
    position: absolute;
    top: 0;
    width: 2px;
    border-radius: 1px;
}

.selection:after {
    background-color: #007aff;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    right: -1px;
    width: 2px;
    border-radius: 1px;
}

.focus {
    position: relative;
}

.focus:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: inset 0 0 0 4px #ff0086;
}

.image-tooltip {
    display: flex;
    position: absolute;
    border: 2px solid #999;
    background-color: black;
    border-radius: 4px;
    left: 0px;
    z-index: 2;
    padding: 4px;
}

.image-tooltip-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.link-element {
    display: inline;
    position: relative;
}

.link-element .link-popup {
    position: absolute;
    top: 30px;
    left: 0;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 6px 10px;
    gap: 10px;
    border-radius: 3px;
    border: 1px solid lightgray;
    z-index: 2;
}

.link-popup a {
    display: flex;
    align-items: center;
}

.link-popup button {
    border: none;
    background: none;
    cursor: pointer;
}

.image-wrapper {
    border-radius: 8px;
    overflow: hidden;
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
}

.emoji-wrapper {
    position: relative;
    width: 120px;
    height: 100px;
}

.emoji-wrapper > img {
    width: 100%;
    height: 100%;
}

.vote-wrapper {
    /* caret-color: transparent; */
}

.program-wrapper {
}

.program-component {
    border-radius: 4px;
    background-color: #222;
    padding: 16px;
    position: relative;
    min-height: 152px;
    width: 100%;
    height: 100%;
    margin: 24px 0;
}

.program-component > img {
    position: absolute;
    left: 16px;
    top: 16px;
    width: 85px;
    height: 120px;
    display: block;
}

.program-component > div {
    width: 100%;
    height: 100%;
    padding-left: 101px;
}

.program-component .program-title {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: -0.3px;
    color: #ffffff;
}

.program-component .program-actor {
    margin-bottom: 8px;
}

.program-component > div p {
    margin: 0;
    width: 100%;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: -0.3px;
    color: rgb(102, 102, 102);
}

.episode-wrapper .episode-thumbnail {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 16 / 9;
}

.episode-component {
    width: 100%;
    height: 100%;
    margin: 24px 0;
    border-radius: 4px;
    overflow: hidden;
    background-color: #222;
}

.episode-component > img {
    width: 100%;
    aspect-ratio: 16/9;
    display: block;
}

.episode-component > div {
    padding: 16px;
    position: relative;
    min-height: 70px;
}

.episode-component .episode-title {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: -0.3px;
    color: #ffffff;
}

.episode-component > div p {
    margin: 0;
    /* width: 100%; */
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: -0.3px;
    color: rgb(102, 102, 102);
}

.episode-broadcast-time:after {
    /* content: '전체 보러가기'; */
    position: absolute;
    right: 16px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAQBAMAAADHbRP9AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAPUExURWdnZ0dwTGZmZmhoaGZmZos+Rn0AAAAEdFJOU48AemXuocRcAAAAKklEQVQI12MQFBRkgGEjIFZhFGQQcADyWRgZBAWcIBjIBomB5IyQ1AMxAH+kA/uVWQfTAAAAAElFTkSuQmCC')
        no-repeat;
    background-position-x: 100%;
    padding-right: 14px;
}

.flex {
    display: flex;
}
.justify-beteween {
    justify-content: space-between;
}
.justify-end {
    justify-content: flex-end;
}

.submit-button {
    background-color: #ff0086 !important;
    border-radius: 8px;
    padding: 4px 12px;
    color: white;
    margin: 4px;
}
