//DropdownMenu
.dropdown {
    border-radius: 4px;
    box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.25);
    background-color: #333;
    width: fit-content;
    height: fit-content;
}

.dropdown_motion {
    position: absolute;
}

.bottom {
    right: 0;
}

.left {
}

.right {
    right: 0;
}

//DropdownItem
.menu_item {
    img {
        margin-right: 8px;
    }
}
