.loader {
    width: 50px;
    height: 50px;
    margin: 0 auto 1rem;
    border: 3px solid white;
    border-radius: 50%;
    border-left-color: #ff0086;
    -webkit-animation: spin 1000ms infinite linear;
    -moz-animation: spin 1000ms infinite linear;
    -ms-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    100% {
        -ms-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
