.wrapper {
    width: 100%;
    padding: 96px 12px 0;
}

.title_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
        color: white;
    }
}

.header {
    width: 100%;
    margin: 0;
}

@media (max-width: 768px) {
    .wrapper {
        padding: 64px 12px 0;
    }
}
