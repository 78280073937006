.history_section {
    max-width: 450px;
    height: calc(100% - 50px);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    display: inline-flex;
    align-items: center;
    margin-bottom: 24px;

    h2 {
        font-size: 24px;
        display: inline-block;
        margin-right: 24px;
    }

    .point_box {
        padding-left: 24px;
        background: url(../../assets/icon/icon-point-20-circle.svg) no-repeat;
        font-size: 24px;
        color: #ffcc00;
        line-height: 20px;
        height: 20px;
        width: 20px;
        background-size: 20px;
        display: inline-block;
        letter-spacing: -0.3px;
        font-weight: 600;
    }
}
