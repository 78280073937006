.box {
    width: 100%;
    min-height: 100px;
    flex: 1;
    background-color: #141414;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.title_wrapper {
    width: 100%;
    height: 58px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close_icon {
        cursor: pointer;
    }
}

//ChannelChatBox
.chat_list {
    height: calc(100% - 38px);
    padding: 0 14px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    flex: 1;
}

.content {
    max-width: 300px;
    word-break: break-all;
}

.chat_item {
    margin-bottom: 12px;
}

.profile_image {
    margin-right: 12px;
}

.chat_body {
    flex: 1;
}

.chat_input_box {
    width: 100%;
    height: auto;
    padding: 12px;
    background-color: #1f1f1f;

    .form_wrapper {
        display: flex;
        align-items: center;
        width: 100%;

        .input_wrapper {
            display: flex;
            align-items: center;
            flex: 1;
            margin: 0 8px 0 0;
            padding: 8px 16px;
            border-radius: 18px;
            background-color: #fcfcfc;
        }

        .subtitle_input {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        input {
            flex: 1;
        }

        .send_icon {
            display: block;
        }

        button:disabled {
            cursor: not-allowed;
        }
    }
    .emoji_icon {
        cursor: pointer;
    }
}
