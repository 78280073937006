.stage_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stage_info_right {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 8px;

    .stage_title {
        font-size: 14px;
    }

    .remain {
        font-size: 16px;
        font-weight: bold;
    }
}

.wrapper {
    position: relative;
}

.stage_image {
    width: 100%;
    aspect-ratio: 4 / 5;
    border-radius: 8px;
    background-color: #161616;
    object-fit: cover;
}

.image {
    width: 500px;
}

@media (max-width: 1024px) {
    .image {
        width: 200px;
    }
}

.title {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    color: white;
    font-size: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_button_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
