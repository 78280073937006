.vote_section {
    width: 100%;
    padding: 220px 12px 0;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.comment_input_wrapper {
    width: 100%;
    height: 60px;
    padding: 0;
    margin: 0;
    background-color: #141414;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    .comment_input {
        width: 100%;
        max-width: 768px;
        padding: 0 12px;
    }
}

.wrapper {
    padding: 0 12px;
    margin: 0;
}

.comments_wrapper {
    padding: 0 12px;
    margin: 0;
}

@media (max-width: 768px) {
    .comment_input_wrapper {
        position: fixed;
        bottom: 0;
    }

    .comments_wrapper {
        margin: 0 0 60px;
    }
}
