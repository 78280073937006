// OnlineSection
.info_wrap {
    margin-bottom: 60px;
}

.visual_bg {
    display: block;
    width: 640px;
    height: 360px;
    margin: 0 auto 24px;
    object-fit: cover;
    border-radius: 8px;
}

.txt_box {
    text-align: center;

    h4 {
        margin-bottom: 5px;
        font-size: 32px;
        font-weight: bold;
    }

    p {
        margin-bottom: 10px;
        font-size: 14px;
    }
}

.date {
    font-size: 12px;
    font-weight: 400;
    color: #999;
}

.badge {
    display: block;
    margin: 13px auto 0;
    padding: 5px;
    width: 60px;
    height: 30px;
    border-radius: 15px;
    font-size: 12px;
    background-color: #aaa;
}

.vote_on {
    color: #fff;
    background-color: #ff0086;
}

.vote_list_wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    margin: 0 -16px 24px;
}

// AlbumCard
.album {
    width: calc(100% / 4 - 32px);
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px;
}

.cover {
    width: 240px;
    height: 240px;
    object-fit: cover;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.song {
    margin: 8px 0;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.artist {
    font-size: 14px;
    color: #999;
    margin-bottom: 8px;
}

.vote_btn {
    width: 100px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #f9f9f9;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: transparent;
}

.completed {
    border: 1px solid #00ff96;
    color: #000;
    background-color: #00ff96;
}
