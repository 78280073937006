.wrapper {
    width: 100%;
    padding: 128px 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 60px;

    .header {
        width: 100%;
        padding: 0 12px;
    }
}

.plans_section {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}

.plans {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.plan {
    cursor: pointer;
    width: 100%;
    padding: 18px 18px 18px 32px;
    border-radius: 8px;
    border: solid 1px transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:last-of-type {
        margin-right: 0;
    }

    li {
        display: flex;
        justify-content: space-between;

        span:last-of-type {
            color: #00f6ff;
        }
    }
}

.plan_type {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.plan_list_item {
    text-align: start;
    list-style: disc;
    word-break: break-all;
    color: #999999;
    font-size: 13px;
    line-height: 20px;
    word-break: keep-all;
    word-wrap: break-word;
}

.plan_price {
    margin-top: 15px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.3px;
    text-align: center;
    color: #666666;

    span {
        color: #ff0086;
        font-weight: bold;
    }
}

.info_section {
    width: 100%;
    margin: 0;
}

@media (max-width: 768px) {
    .wrapper {
        gap: 30px;
    }

    .info_section {
        padding: 0;
    }
}

.info_box {
    display: flex;
    padding: 12px;
    border-radius: 8px;
    background-color: #191919;
    justify-content: flex-start;
    align-items: flex-start;

    .left_box {
        width: 50%;
        border-right: 1px solid #151515;
    }

    .right_box {
        width: 50%;

        .right_box_title {
            margin-bottom: 20px;
        }

        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        div.price_box {
            padding: 6px 0;

            div {
                line-height: 36px;
            }
        }
    }
}

@media (max-width: 768px) {
    .plans {
        flex-direction: column;
    }

    .info_box {
        flex-direction: column;
        gap: 12px;

        .left_box {
            width: 100%;
        }

        .right_box {
            width: 100%;
            padding: 0 12px;
        }
    }
}

.footer {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    margin-bottom: 90px;

    button {
        width: 100%;
        border-radius: 0px;
        height: 48px;
    }
    a {
        width: 100%;
        border-radius: 0px;
        height: 48px;
    }
}

.cancel_btn {
    width: 240px;
    height: 46px;
    margin-right: 16px;
}

.submit_btn {
    width: 240px;
    height: 46px;
}
