.wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.main {
    width: 100%;
    max-width: 768px;
    flex: 1;
}

.player_main {
    width: 100%;
    flex: 1;
}
