@charset "utf-8";

/* (국문) Noto Sans KR */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700;900&display=swap');
/* (영문) Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

/*
** Font Family Info **

** Noto Sans KR | 'Noto Sans KR', sans-serif;
** font weight:
** Regular - 400;
** Medium - 500;
** Bold - 700;
** Black - 900;
*/
/*
** Poppins | 'Poppins', sans-serif;
** font weight:
** Regular - 400;
** Semi Bold - 600;
** Bold - 700;
*/
$main: #ff0086;
$sub: #35ccff;
$yellow: #ffe000;
$white: #fff;
$black: #000;
$gray: #999;
$purple: #a900ff;

* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

html {
    overflow-y: scroll;
    scroll-behavior: smooth;
}

html {
    width: 100%;
    height: auto;
    font-size: 14px;
    word-break: keep-all;
    font-family: 'Noto Sans JP', 'Poppins', 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif;
    font-weight: 400;
    color: #fff;
    background: transparent;
    letter-spacing: -0.3px;
}

body {
    width: 100%;
    height: auto;
    font-size: 14px;
    word-break: keep-all;
    font-family: 'Noto Sans JP', 'Poppins', 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif;
    font-weight: 400;
    color: #fff;
    background: #141414;
    letter-spacing: -0.3px;
    overflow-x: hidden;
}

/* HTML5 새 요소 초기화 */
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

textarea {
    resize: none;
}

address,
main {
    font-style: normal;
}

table,
input,
textarea,
select,
button,
pre {
    font-size: 1rem;
    line-height: 1.3;
    color: #000;
    border-radius: 0;
    outline: none;
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    margin: 0;
}

pre {
    white-space: pre-line;
}

ul,
ol,
li {
    list-style: none;
    padding: 0;
    margin: 0;
}

input,
select,
button {
    vertical-align: middle;
    font-family: 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif;
}

button,
select {
    cursor: pointer;
    text-align: center;
}

table,
th,
td {
    border-spacing: 0;
    border-collapse: collapse;
    vertical-align: middle;
    text-align: left;
}

input,
textarea {
    border: none;
    font-family: 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif;
}

input[type='text']::-ms-clear {
    display: none;
}

select {
    border: none;
    font-family: 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif;
}

input:focus,
select:focus,
textarea:focus {
    // border: none;
}

section {
    max-width: 768px;
    margin: 0 auto 60px;
    padding: 0 12px;
    width: 100%;
}

.section_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

input::placeholder {
    color: rgba(0, 0, 0, 0.5);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
    color: white;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

a,
a:link,
a:hover,
a:focus,
a:active,
a:visited {
    outline: none;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

a:focus {
    text-decoration: none;
}

hr {
    display: none;
    height: 0;
}

select::-ms-expand {
    display: none;
}

// react-slick
.slider_wrapper {
    width: 100%;
}

// slick dots
ul.slick-dots {
    width: 100%;

    & li {
        display: inline-block;
        margin-right: 12px;
        width: 48px;
        height: 4px;

        & button {
            padding: 0;
            display: block;
            width: 100%;
            height: 100%;
            color: transparent;
            text-indent: -9999px;
            overflow: hidden;
            background-color: rgba(255, 255, 255, 0.2);
            transition: 0.3s;
        }
    }

    & .slick-active button {
        background-color: white;
    }
}

.slick-list {
    margin: 0;
}

.slick-track {
    margin: 0;
}

input,
textarea,
button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
}

#root {
    //min-height: calc(100vh - 95px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
}

.ReactModalPortal {
    position: relative;
    z-index: 100;
}

.portal_dialog {
    position: relative;
    z-index: 1000;
}

.highlight_wrapper {
    width: 100%;
    margin-top: 160px;
}