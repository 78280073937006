.card {
    width: 408px;
    padding: 32px 40px;
    margin: 32px auto;
    border-radius: 8px;
    background-color: #222;
}
.cardlong {
    width: 408px;
    height: 478px;
    padding: 32px 40px;
    margin: 32px auto;
    border-radius: 8px;
    background-color: #222;
}
.cardshort {
    width: 408px;
    height: 178px;
    padding: 32px 40px;
    margin: 32px auto;
    border-radius: 8px;
    background-color: #222;
}
.row {
    width: 100%;
}
.input {
    width: 328px;
    height: 36px;
    margin: 8px 0 0;
    padding: 8px 12px;
    border-radius: 2px;
    background-color: #fff;
}
.center {
    margin: 0 auto 0 0;
}
.button {
    width: 100%;
}
.notibox {
    margin-top: 16px;
}
.notibox ol li {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 20px;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    margin-bottom: 2px;
}
.notibox ol li:before {
    content: '';
    width: 4px;
    height: 4px;
    background-color: #aaa;
    top: 7px;
    left: 6px;
    position: absolute;
    border-radius: 50%;
}
.notitxt {
    font-size: 12px;
    line-height: 20px;
    color: #666;
    letter-spacing: -0.3px;
}
