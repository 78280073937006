.settings_pane {
    width: 100%;
    border-top: 1px solid #2a2a2a;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-of-type {
        border-bottom: 1px solid #2a2a2a;
    }
}
