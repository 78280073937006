.horizontal {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid;
    background-color: transparent;
    margin: 16px 0px;
}
.vertical {
    height: 100%;
    width: 1px;
    border-right: 1px solid;
    background-color: transparent;
    margin: 0px 16px;
}
