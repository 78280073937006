.fc_signup_box {
    margin-bottom: 40px;
}

.fc_signup_box .select_box {
    margin-bottom: 12px;
    display: flex;
}

.fc_signup_box .select_box:last-of-type {
    margin-bottom: 0;
}

.fc_signup_box .select_box input {
    width: 0 !important;
    height: 0 !important;
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    font-size: 0;
    line-height: inherit;
    letter-spacing: 0;
    color: transparent;
}

.fc_signup_box .select_box input[type='radio'] + label {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 20px 24px;
    border: 1px solid #444;
    border-radius: 8px;
}

.white .fc_signup_box .select_box input[type='radio'] + label {
    border: 1px solid #eee;
}

.fc_signup_box .select_box input[type='radio']:checked + label {
    border: 2px solid #ff0086;
    padding: 19px 23px;
}

.fc_signup_box .select_box input[type='radio'] + label div:first-of-type {
    flex-grow: 1;
}

.fc_signup_box .select_box input[type='radio'] + label .right_box {
    width: 32px;
    display: inline-block;
    vertical-align: top;
    align-self: center;
}

.fc_signup_box .select_box input[type='radio'] + label .right_box .chk_icon {
    width: 100%;
    height: 32px;
    border-radius: 50%;
    background-color: #333;
}

.white .fc_signup_box .select_box input[type='radio'] + label .right_box .chk_icon {
    background-color: #f3f3f3;
}

.fc_signup_box .select_box input[type='radio']:checked + label .right_box .chk_icon {
    background: url('/assets/img/buttonCheckRound.png') no-repeat #ff0086 center;
    background-size: 24px;
}

.plan_tit {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .plan_name {
        width: 60%;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #fff;
    }

    .plan_price {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.3px;
            color: #666;
            white-space: nowrap;
        }
    }
}

.white .plan_tit strong {
    color: #000;
}

ol.clear li {
    position: relative;
    float: left;
    width: 100%;
    padding-left: 16px;
    margin-bottom: 4px;
    text-align: left;
    font-size: 13px;
    line-height: 16px;
    color: #999;
}

ol.clear li:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    top: 6px;
    left: 6px;
    border-radius: 50%;
    background-color: #aaa;
}

.left_box {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    .plan_name {
        width: 100%;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #fff;
    }

    .plan_price {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .price {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.3px;
        }

        .plan_vat {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #999;
        }
    }
}

.white .left_box strong {
    color: #000;
}

.p_color {
    color: #ff009f !important;
}
