.link{
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner {
    width: 100%;
    margin: 0;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}

.title_wrapper {
    width: 100%;
    height: 72px;
    padding: 16px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
    color: black;
    font-size: 14px;
    font-weight: 500;
}
