.card_layer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card_box {
    position: relative;
    width: 100%;
    padding: 16px 4px 0;
}

.ranking_card {
    margin-bottom: 26px;
}

.effect {
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-16px);
    }
}

.image_wrapper {
    position: relative;
}

.image {
    display: block;
    position: relative;
    width: 100%;
    aspect-ratio: 46 / 65;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 8px;

    [class~='checked'] {
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 48px;
            height: 48px;
            background: url(../../assets/img/icon-checker-check.png) no-repeat;
            background-size: contain;
            z-index: 2;
            transform: translate(-50%, -50%);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 0, 134, 0.75);
            z-index: 1;
        }
    }
}

// LandscapeEpisodeCard
.landscape_card_box {
    position: relative;
    width: 100%;
    padding: 16px 4px 0;
}

.landscape_image {
    display: block;
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 8px;

    [class~='checked'] {
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 48px;
            height: 48px;
            background: url(../../assets/img/icon-checker-check.png) no-repeat;
            background-size: contain;
            z-index: 2;
            transform: translate(-50%, -50%);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 0, 134, 0.75);
            z-index: 1;
        }
    }
}

.imagechecked {
    position: relative;
    width: 100%;
    aspect-ratio: 46 / 65;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 8px;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 48px;
        height: 48px;
        background: url(../../assets/img/icon-checker-check.png) no-repeat;
        background-size: contain;
        z-index: 2;
        transform: translate(-50%, -50%);
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 134, 0.75);
        z-index: 1;
    }
}

.blur {
    width: 100%;
    height: calc(100% - 30px);
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0.7));
    z-index: 1;
    border-radius: 8px;
}

.title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
}

.titlefont {
    width: 100%;
    color: #fff;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.3px;
    margin-top: 8px;
}

.rank {
    position: absolute;
    bottom: -26px;
    left: 0;
    display: flex;
    align-items: baseline;
    z-index: 2;
}

.number {
    display: block;
    font-size: 32px;
    margin-right: 8px;
    font-weight: bold;
    color: #fff;
}

.status {
    display: flex;
    align-items: center;
}

.rank_icon {
    margin-right: 4px;
}

.N {
    font-size: 14px;
    font-weight: 600;
    color: #ff0086;
    height: 24px;
}

.H {
    font-size: 14px;
    font-weight: 600;
    color: #555;
}

.D {
    font-size: 14px;
    font-weight: 600;
    color: #555;
}

.U {
    font-size: 14px;
    font-weight: 600;
    color: #ff0086;
}

.mobile_image {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    aspect-ratio: 16 / 9;
}

.portrait_image {
    aspect-ratio: 46 / 65;
}

.mobile_title {
    width: 100%;
    padding: 4px 0 0;
    font-size: 20px;
}

@media (max-width: 768px) {
    .mobile_title {
        font-size: 16px;
    }
}
