.agree_box {
    display: flex;
    padding: 6px 0;
    border-bottom: 1px solid #151515;
}

.agree_box input {
    width: 0 !important;
    height: 0 !important;
    border: 0;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    font-size: 0;
    line-height: inherit;
    letter-spacing: 0;
    color: transparent;
}

.agree_box input[type="checkbox"] + label {
    display: block;
    width: 100%;
    padding-left: 34px;
    position: relative;
}

.agree_box input[type="checkbox"] + label .left_box {
    display: inline-block;
    width: calc(100% - 36px);
    vertical-align: top;
    text-align: left;
    font-size: 1em;
    line-height: 36px;
    letter-spacing:-.3px;
    color:#fff;
}

.agree_box input[type="checkbox"] + label .left_box span {
    display: inline-block;
    font-size: 1em;
    line-height: 36px;
    letter-spacing: -.3px;
    color: #999
}

.p_color {
    color: #ff0086 !important;
}

.agree_box input[type="checkbox"] + label .right_box {
    display: inline-block;
    width: 36px;
    height: 36px;
    vertical-align: top;
    text-align: right;
}

.link_button {
    display: block;
    font-size: 1em;
    line-height: 36px;
    letter-spacing: -0.3px;
    color: #999;
}

.agree_box input[type="checkbox"] + label:after {
    content: '';
    width: 24px;
    height: 24px;
    border: 1px solid #444;
    border-radius: 4px;
    position: absolute;
    top: 6px;
    left: 0;
}

.agree_box input[type="checkbox"]:checked + label:after {
    content: '';
    border: 0;
    background: url('/assets/img/btnCheckboxOn.png') no-repeat;
    background-size: contain;
}