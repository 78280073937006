.container {
    width: 100%;
    padding: 0px 12px;
}

.action_bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    button {
        height: 36px;
        color: #fff;
    }
}

.action_bar_left {
    button {
        font-size: 14px;
        line-height: 36px;
        color: #666;
        position: relative;
        margin: 0 12px;
        letter-spacing: -0.3px;
        vertical-align: top;

        &:not(:last-child)::before {
            content: '';
            width: 1px;
            height: 12px;
            background-color: #333;
            position: absolute;
            right: -12px;
            top: 12px;
        }

        span:nth-child(2) {
            font-size: 14px;
            display: inline-block;
            margin-left: 4px;
            color: #ff0086;
            letter-spacing: -0.3px;
            font-weight: 600;
        }
    }
}

.active {
    color: white !important;
}

.action_bar_right {
    button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #ff0086;

        svg {
            margin-right: 10px;
        }
    }
}

.table {
    color: white;
    width: 100%;
    margin: 0px auto;
    margin-bottom: 20px;

    td {
        text-align: center;

        &:nth-child(1) {
            width: 150px;
        }
        &:nth-child(2) {
            text-align: start;
        }
        &:nth-child(3) {
            width: 100px;
        }
        &:nth-child(4) {
            width: 120px;
            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0px auto;
            }
        }
    }

    tr {
        height: 58px;
        border-bottom: 1px solid #222;

        td:nth-child(1) {
            color: #999;
        }

        td:nth-child(2) {
            cursor: pointer;
        }

        td:nth-child(3) {
            font-size: 14px;
            line-height: 58px;
            color: #999;
            letter-spacing: -0.3px;
            text-align: center;
        }

        td:nth-child(4) {
            height: 58px;
        }
    }
}

.empty {
    position: relative;
    height: 400px;
    width: 100%;
    border-bottom: 1px solid #222;

    div {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
            margin-top: 10px;
            font-size: 16px;
            line-height: 28px;
            color: #333;
            letter-spacing: -0.3px;
            text-align: center;
        }
    }
}

.q {
    display: inline-block;
    width: 14px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    color: #ff0086;
    letter-spacing: -0.3px;
    font-family: 'Poppins', 'Noto Sans KR', sans-serif;
    margin-right: 6px;
}

.table_header {
    width: 100%;
    height: 48px;
    background: #222;

    .table_header_wrapper {
        width: 100%;

        th {
            font-size: 13px;
            font-weight: bold;
            line-height: 48px;
            color: #555;
            letter-spacing: -0.3px;
            text-align: center;
        }
    }
}
