.dark {
    min-height: 100vh;
    overflow: scroll;
    background-color: black;
    color: white;
}

.white {
    min-height: 100vh;
    overflow: scroll;
    background-color: white;
    color: black;
}

.sub_tit {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -.3px;
    color: #666;
}

.white .sub_tit {
    color: #999;
}

.fc_signup_result {
    min-height: 428px;
}

.bot_btn {
    position: relative;
    margin-top: 24px;
}

.bot_btn .submit_btn {
    display: block;
    width: 100%;
    border-radius: 28px;
    background-color: #ff0086;
    text-align: center;
    font-size: 16px;
    line-height: 56px;
    letter-spacing: -.3px;
    color: #fff;
}

.loading_modal_container {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: black;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 50px;
    height: 50px;
    margin: 0 auto 1rem;
    border: 3px solid white;
    border-radius: 50%;
    border-left-color: #ff0086;
    -webkit-animation: spin 1000ms infinite linear;
    -moz-animation: spin 1000ms infinite linear;
    -ms-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    100% {
        -ms-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}