.header {
    padding: 16px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(127, 44, 240);
}

.title {
    text-align: center;
    font-size: 18px;
    white-space: pre-line;
}

.blank {
    width: 42px;
}
