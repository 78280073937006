.wrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .term_wrapper {
        width: 100%;
        max-width: 768px;
        padding: 24px 12px 36px;

        .term {
            width: 100%;
            font-size: 1.5em;
            color: #232323;
            white-space: pre-line;
            word-break: break-all;
        }
    }
}

@media (max-width: 768px) {
    .wrapper {
        .term_wrapper {
            padding: 12px 6px 18px;

            .term {
                font-size: 0.75em;
            }
        }
    }
}
