.plan_modal_container {
    padding: 24px;
    border-radius: 8px;
    background-color: #222 !important;
}

.chatroom_title {
    width: 327px;
    font-size: 28px !important;
    word-break: break-all;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.blank {
    height: 24px;
}

.hashtag {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    margin: 4px;
    border-radius: 20px;
    border: solid 1px #fff;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
        border-color: #ff0086;
        color: #ff0086;
    }
    &.sm {
        height: 26px;
        margin: 0 4px 0 0;
        padding: 4px 12px 4px 12px;
        border-radius: 20px;
        border: 1px solid #666;
        font-size: 11px;
        color: #666;
        &:hover {
            border-color: #ff0086;
            color: #ff0086;
        }
    }
    &.xs {
        border: 1px solid #666;
        color: #666;
        font-size: 11px;
        height: 22px;
        &:hover {
            border-color: #ff0086;
            color: #ff0086;
        }
    }
}

.btn_wrap {
    width: 100%;
}

.btn_wrap .btn {
    width: 100%;
}
