.header {
    width: 100%;
    max-width: 768px;
    padding: 36px 36px 0;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    .logo_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .back_button {
            cursor: pointer;
        }

        .blank {
            width: 42px;
        }
    }

    .title {
        text-align: center;
        font-size: 2.85em;
        font-weight: bold;
        line-height: 1.1;
        color: #074575;
        white-space: pre-line;
        word-break: keep-all;
        word-wrap: break-word;
    }
}

@media (max-width: 768px) {
    .header {
        padding: 18px 18px 0;
        gap: 20px;

        .title {
            font-size: 1.42em;
        }
    }
}
