.collection_main {
    width: 100%;
    padding: 64px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.wrapper {
    width: 100%;
    padding: 0 12px;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
        min-width: 100px;
        flex: 1;
    }
}

.edit_opts {
    display: flex;
    align-items: center;
}

.header_opts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 8px;
}

.header_edit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.inline {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add_inventory {
    width: 325px;
    border-radius: 8px;
    text-align: center;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        color: black;
        margin-top: 1rem;
    }
}

.inventory_input {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    input {
        font-size: 20px;
        padding: 0 10px;
    }

    i {
        padding: 0.7rem;
        line-height: 0;
    }
}

.nude_btn {
    width: auto;
    height: auto;
    background: none;
    padding: 0 0.5rem;
}

.disabled_icon {
    path {
        fill: lightgray;
    }
}

.inventory_btns {
    display: flex;
    width: 100%;

    button {
        flex-grow: 1;
        border-radius: 0 0 0 8px;
        padding: 15px 0;
        height: auto;

        &:last-child {
            border-radius: 0 0 8px 0;
        }
    }
}

.secondary_btn {
    background: #999;
}

/* styles to center align prefix icon in inline button */
.pre_icon {
    line-height: 0;
}

.gray {
    color: #666;
}

.add_button {
    border-radius: 22px;
    padding: 0 10px;
    width: auto;
    line-height: 0;

    span {
        margin: 0 5px;
    }

    path {
        fill: white;
    }
}

.image_collection_wrapper {
    width: 100%;
    padding: 0 12px;
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
}

.collection_wrapper {
    width: 100%;
    padding: 0 12px;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
}

@media (max-width: 768px) {
    .image_collection_wrapper {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
    }
    .collection_wrapper {
        grid-template-columns: repeat(1, minmax(100px, 1fr));
    }
}

.collection_card {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
}

.image_collection_card {
    width: 100%;
    aspect-ratio: 1 / 1;
    padding: 0.5rem;
    display: flex;
    align-items: center;
}

.card_placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 52px 0;
    color: #ffffff4d;
}

.card {
    position: relative;
    padding: 12px;
    background-color: #222;
    border-radius: 8px;
    flex-grow: 1;
    transition: 0.3s;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image_card {
    position: relative;
    background-color: #222;
    border-radius: 8px;
    flex-grow: 1;
    transition: 0.3s;
    height: 100%;
}

.image_card_img {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
    object-fit: cover;
}

.checkbox_wrapper {
    position: absolute;
    left: 8px;
    top: 8px;
}

.edit_post {
    margin-left: 35px;
}

.edit_vod {
    margin: 0 35px;
}

.checkbox {
    position: absolute;
    left: -32px;
    top: 50%;
    transform: translateY(-50%);
}

.handle {
    position: absolute;
    right: -32px;
    top: 50%;
    transform: translateY(-50%);
}

.card_content {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.card_head_link {
    cursor: pointer;
}

.card_title {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.card_description {
    display: flex;
    color: #777;
    margin-top: 4px;
}

.card_userid {
    height: 20px;
    margin: 0 12px 0 4px;
    opacity: 0.6;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.3px;
    color: #fff;
}

.card_img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
}

.card_thumb {
    width: 140px;
    height: 80px;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .card_thumb {
        width: 100px;
        height: 60px;
    }
}

.card_none {
    background-repeat: no-repeat;
    background-image: url(../../../assets/img/iconCollectionNone.svg);
    background-position: 50% 50%;
    background-color: #151515;
}

.rounded_btn {
    padding: 16px 80px;
    width: auto;
    border-radius: 25px;
    min-height: 40px;
}

.container {
    width: 100%;
    padding: 0 40px;
}

.head_wrap {
    display: flex;
    justify-content: space-between;
}

.flex_center {
    display: flex;
    align-items: center;
}

.separator {
    display: inline-block;
    height: 12px;
    border-right: 1px solid #333;
    padding-left: 12px;
}
