.wrapper {
    width: 100%;
    padding: 96px 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    .title {
        width: 100%;
        padding: 0 12px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        padding: 64px 0 0;
    }
}

.list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .setting_item {
        width: 100%;
        height: 60px;
    }
}

.playback_preferences {
    padding-top: 48px;

    h3 {
        font-size: 24px;
    }

    p {
        padding: 24px 0;
        color: #666;
    }
}
