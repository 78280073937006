.wrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #f0f0f0;
    line-height: 1;
    color: #232323;
    white-space: pre-line;
    word-break: break-word;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.promotion_wrapper {
    width: 100%;
    max-width: 768px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
