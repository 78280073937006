.card {
    width: 100%;
    max-width: 408px;
    padding: 28px 40px 34px;
    border-radius: 8px;
    background-color: #222;
    margin: 0 auto;
    @media (max-width: 768px) {
        padding: 12px;
    }
}
.input {
    width: 100%;
    // max-width: 328px;
    height: 36px;
    margin: 8px 0 0;
    padding: 8px 12px;
    border-radius: 2px;
    background-color: #fff;
}
.input_pwd {
    width: 100%;
    // width: 328px;
    height: 36px;
    margin: 4px 0;
    padding: 8px 12px;
    border-radius: 2px;
    background-color: #fff;
}

.submit_btn {
    width: 100%;
    // width: 328px;
    color: white;
    font-weight: 600;
    height: 42px;
    padding: 12px 18px;
    margin-top: 12px;
    border-radius: 21px;
    background-color: #ff0086;
}
.custom_calendar {
    text-align: left;
    width: 100%;
    // width: 328px;
    height: 36px;
    margin: 8px 0 16px;
    padding: 6px 6px 6px 12px;
    border-radius: 2px;
    background-color: #fff;
}
.button {
    width: 102px;
    height: 36px;
    padding: 8px 14px;
    border-radius: 8px;
    border: solid 1px #444;
    color: white;
}
.active {
    border-radius: 8px;
    background-color: #ff0086;
}

//SignEmailConfirm
.auth_input {
    width: 246px;
    height: 36px;
    padding: 8px 12px;
    border-radius: 2px;
    background-color: #fff;
    margin: 12px 10px 12px 0px;
}
.auth_btn {
    width: 72px;
    height: 36px;
    padding: 8px 12px;
    border-radius: 18px;
}
.paragraph {
    width: 308px;
    word-break: break-all;
    margin-bottom: 24px;
}
.re_verify {
    width: 100%;
    margin: 12px 0;
}
