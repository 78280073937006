//McountDown
.confirmation {
    max-width: none;
    width: 100%;
    padding: 48px 0;
    background-color: #222;
    margin-bottom: 0;
}
.wrapper {
    width: 1280px;
    padding: 0 48px;
    margin: auto;
    h5 {
        margin-bottom: 18px;
        font-size: 16px;
        font-weight: 600;
    }
}
.list {
    list-style: inside;
    li {
        margin-bottom: 2px;
        padding-left: 20px;
        position: relative;
        font-size: 13px;
        font-weight: 400;
        color: #999;
    }
    li:before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        position: absolute;
        top: 8px;
        left: 6px;
        border-radius: 2px;
        background-color: #aaa;
    }
}
.info {
    display: flex;
    align-items: center;
    margin: 16px auto 0px;
    padding: 0 48px;
    max-width: 1280px;
    width: 100%;
}
.count_txt {
    margin-right: 8px;
}
.sec_max {
    margin: 0 auto 0px;
    padding: 0 48px;
    max-width: 1280px;
    width: 100%;
}
//McountDownHeader
.date_count {
    display: inline-block;
    margin: 8px 0;
    font-size: 14px;
    color: #ff0086;
    i {
        display: inline-block;
        margin-right: 6px;
        width: 12px;
        height: 12px;
        background-image: url('/assets/img/icon_clock_12_pink.png');
        vertical-align: middle;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}
