.wrapper {
    width: 100%;
    margin: 220px 0 0;
}

.program_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 768px) {
    .program_list {
        grid-template-columns: repeat(3, 1fr);
    }
}
