// CandidatePlainCard
.candidate_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

// PlainCard
.plain_card_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.modal {
        border-radius: 16px;
        background-color: #222;
    }
}

.title_wrapper {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;

    &.modal {
        padding: 0 1rem;
    }

    .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

.plain_card {
    position: relative;
    width: 100%;
    border: 1px solid #222;
    border-radius: 14px;
    aspect-ratio: 16 / 9;
    overflow: hidden;

    &.modal {
        border: 0;
        border-radius: 14px 14px 0 0;
    }

    & .img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    & .blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        & .short {
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 24px;
        }

        & .fill {
            min-height: 22%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    & .voted_count {
        position: absolute;
        top: 8px;
        left: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        z-index: 3;

        & > img {
            width: 48px;
            height: 48px;
        }

        & > span {
            font-size: 17px;
            font-weight: 700;
        }
    }
}
