// UserInfo
.user_info {
    display: flex;
    align-items: center;
    position: relative;
}

.row {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.nickname {
    margin: 0px 2px 0px 8px;
}

@media (max-width: 768px) {
    .nickname {
        display: none;
    }
}

.log_on {
    right: 0;
}

.person_icon {
    cursor: pointer;
}

.layer {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.dropdown_icon {
    position: relative;
    height: 24px;
}

.notification {
    width: 332px;
    max-height: 423px;
    border-radius: 8px;
    box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.25);
    background-color: #444;
    padding: 10px 0;
    position: relative;

    .container {
        height: 403px;
        overflow-y: scroll;
        overflow-x: hidden;

        .item {
            align-items: flex-start;
            padding: 12px 16px;
            opacity: 0.3;

            &:hover {
                background-color: #333333;
            }

            .header {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: -0.3px;
                color: rgba(255, 255, 255, 0.5);
                margin-bottom: 5px;

                .spot {
                    margin-left: 8px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #ff0086;
                }
            }

            p {
                word-break: break-word;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: -0.3px;
                color: #fff;
            }

            span {
                color: #ff0086;
            }
        }

        .unread {
            opacity: 1;
        }
    }
}

.image_wrapper {
    padding-left: 24px;
}

@media (max-width: 768px) {
    .image_wrapper {
        padding: 7px;
    }
}
