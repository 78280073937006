.search_icon {
    width: 42px;
    height: 42px;
}

.off {
    display: none;
}

.layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    z-index: -1;
}

.search_bar {
    background-color: #141414;
}

@media (max-width: 768px) {
    .search_bar {
        position: absolute;
        left: 24px;
        width: calc(100% - 132px);
    }
}

.searchbar_wrapper {
    position: relative;
}

.form {
    width: 100%;
    height: 42px;
    padding: 0 16px 0 0;
    border-radius: 21px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search_input {
        min-width: 10%;
        flex: 1;
    }
}

.inner_value_reset {
    width: 20px;
    height: 20px;
}
