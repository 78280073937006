//ProgramSection
.title_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    span {
        color: #aaa;
    }
}

.program_list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 16px;
}

.aggregation_period {
    display: flex;
    align-items: center;
    color: #ff0086;
    font-size: 14px;
    margin-bottom: 42px;

    i {
        display: inline-block;
        margin-right: 6px;
        width: 12px;
        height: 12px;
        background-image: url(../../assets/img/icon_clock_12_pink.png);
        vertical-align: middle;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

// RankingPageHeader
.icon_box {
    width: 77px;
    height: 26px;
    border-radius: 75px;
    margin-left: 8px;
    background-color: #ff0086;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img {
    height: 14px;
    vertical-align: middle;
}

.txt {
    font-size: 11px;
    font-weight: 600;
    color: white;
}

.icon {
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 12px;
    height: 12px;
    color: transparent;
    background-image: url('/assets/img/icons_inbutton_livewatch.png');
}

.artists_list {
    margin: 0 -8px;
}

//ArtistCard
.list_card {
    width: calc(100% / 3 - 8px);
    margin: 0 8px;
    padding: 22px 32px 32px;
    border-radius: 8px;
    background-color: #222;
}
