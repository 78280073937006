.section_wrapper {
    width: 100%;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.title_wrapper {
    width: 100%;
}

// MyArtistCard
.wrapper {
    width: 100%;
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.artist_card {
    width: 100%;
    max-width: 288px;
    aspect-ratio: 8 / 11;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
}

.img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: -1;
}

.blur {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .short {
        height: 40px;
    }

    .fill {
        height: 68px;
    }
}

.content {
    padding : 0 0 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 3;
    gap: 8px;
}

.pmail_icon {
    margin-left: 8px;
    align-self: flex-end;
}

.link {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 24px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 4px;
}

.follower_count {
    opacity: 0.5;
    font-size: 12px;
    width: fit-content;
}

.buttons {
    width: 100%;
    padding: 0 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
}

.button_wrapper {
    width: 100%;
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;

    .text {
        font-size: 12px;
        text-align: center;
        word-break: break-all;
    }
}
