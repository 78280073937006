.a-checkbox {
    &__label {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__input {
        width: 0;
        margin: 0;
        opacity: 0;

        &:checked {
            + .a-checkbox__frame {
                background-color: #fff;
                border: none;

                .a-checkbox__icon {
                    font-size: 20px;
                    position: absolute;
                    top: calc(50% - 10px);
                    left: calc(50% - 10px);
                    display: block;
                    color: white;
                }
            }
        }
    }

    &__frame {
        position: relative;
        box-sizing: border-box;
        display: block;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #c9c9c9;
        border-radius: 4px;
        transition: background-color 0.1s linear;
    }

    &__icon {
        display: none;
    }

    &__text {
        font-size: 16px;
        font-weight: bold;
        display: block;
        line-height: 1.5em;
        margin-left: 16px;
    }
}
