.arrow {
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: transparent;
    text-indent: -9999px;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 28px;
    background-size: 36px;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: rgba(51, 51, 51, 0.4);
    transition: 0.3s;
    z-index: 4;
    cursor: pointer;

    &:hover {
        background-color: rgba(51, 51, 51, 0.9);
    }
}

.xs {
    width: 14px;
    height: 14px;
}

.sm {
    width: 34px;
    height: 34px;
}

.md {
    width: 54px;
    height: 54px;
}

.lg {
    width: 64px;
    height: 64px;
}

.xl {
    width: 74px;
    height: 74px;
}

.prev {
    left: -12px;
    background-image: url(../../assets/img/btn_chart_slide_left_active.png);
}

.next {
    right: -12px;
    background-image: url(../../assets/img/btn_chart_slide_right_active.png);
}

.is_available {
    cursor: not-allowed;
}
