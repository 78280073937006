//AllArtist
.result_section {
    display: flex;
    flex-direction: column;
}

.result {
    display: flex;
    align-items: center;
    position: relative;
}

.result_count {
    color: #ff0086;
}

.txt {
    color: #fff;
}

.input {
    width: 252px;
    height: 36px;
    padding: 8px 16px;
    border-radius: 18px;
    background-color: #fff;
    margin-left: auto;
}

.search_icon {
    margin: 0 8px;
    cursor: pointer;
}

.recommend_artist {
    display: flex;
    margin-top: 8px;
    margin-bottom: 32px;
}

.badge {
    margin-right: 8px;
    cursor: pointer;
}

//Filter
.filter_icon {
    background-color: rgba(0,0,0,0.5);
    border-radius: 50%;
    cursor: pointer;
}

.h5 {
    margin-bottom: 6px;
}

.dropdown_motion {
    position: absolute;
    right: 0;
    width: 340px;
    padding: 14px 24px 20px;
    border-radius: 8px;
    box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.25);
    background-color: #333;
}

.filter_btns {
    display: flex;
    margin: 0px -4px 16px;
}

.filter_btn {
    margin: 0px 4px;
}

.btns {
    display: flex;
    margin: -4px;
}

.btn {
    width: 100%;
    margin: 4px;
}

//AritstSection
.artist_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -8px;
}
