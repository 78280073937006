.row {
    display: flex;
}

.col {
    display: flex;
    flex-direction: column;
}

.space-between {
    justify-content: space-between;
}
.flex-end {
    justify-content: flex-end;
}
.flex-start {
    justify-content: flex-start;
}
.center {
    justify-content: center;
}

.align-center {
    align-items: center;
}
.align-flex-end {
    align-items: flex-end;
}
.align-flex-start {
    align-items: flex-start;
}
.align-baseline {
    align-items: baseline;
}

.nowrap {
    flex-wrap: nowrap;
}
.revert {
    flex-wrap: revert;
}
.wrap {
    flex-wrap: wrap;
}
.wrap-reverse {
    flex-wrap: wrap-reverse;
}

.interactive {
    cursor: pointer;
}

.hidden_scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
}

.gap-1 {
    gap: 0.25rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-3 {
    gap: 0.75rem;
}
.gap-4 {
    gap: 1rem;
}
.gap-5 {
    gap: 1.25rem;
}
.gap-6 {
    gap: 1.5rem;
}
.gap-7 {
    gap: 1.75rem;
}
.gap-8 {
    gap: 2rem;
}
.gap-9 {
    gap: 2.25rem;
}
.gap-10 {
    gap: 2.5rem;
}
