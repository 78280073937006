.section {
    padding-top: 224px;
    margin-bottom: 60px;
}

.empty {
    width: 100%;
    height: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.3px;
    text-align: center;
    color: #fff;
}

.no_contents {
    border-radius: 8px;
    background-color: #222;
}

.flex_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}

//BestPostCard
.best_post_card {
    margin: 8px;
}

.cover {
    width: 224px;
    height: 224px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 16px;
}

.post_thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px 8px 0 0;
    z-index: -3;
    background-color: #333333;
    background-image: url(../../assets/img/imgBrandMnet.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.blur {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
    z-index: -2;
}

.ribbon {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 24px;
}

.wrote_date {
    font-size: 12px;
}
.post_title {
    width: 100%;
    min-width: 62px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 1;
    word-break: break-all;
}
.info {
    width: 224px;
    height: 100px;
    background-color: white;
    border-radius: 0 0 8px 8px;
    padding: 16px;
}

.stat {
    display: flex;
    align-items: center;
}

.count_txt {
    margin: 0 6px 0 2px;
}

//PostsTab
.post_wrapper {
    width: 100%;
    padding: 222px 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .post_tab_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        .tag_filter_wrapper {
            width: calc(100% - 140px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;

            .tag_filter_title {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .tag_list_wrapper {
                width: 100%;
            }

            .tag_list {
                display: flex;
                flex-wrap: wrap;
                gap: 4px;
            }
        }
    }
}

.hashtag {
    width: fit-content;
    padding: 4px 12px;
    border: 1px solid #aaaaaa;
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    color: #aaaaaa;
    transition: 0.4s;
    white-space: nowrap;

    &:hover {
        border-color: #444;
        color: #444;
    }
}

.active {
    border-color: #ff0086;
    color: #ff0086;
}

.select {
    width: 140px;
}

.post_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
