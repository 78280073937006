.wrapper {
    width: 300px;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.info_wrapper {
    width: 100%;
    padding: 20px;
}

.title {
    padding: 10px 0 0;
    text-align: center;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
}

.ott {
    display: flex;
    flex-direction: column;
}

.ott_title {
    padding: 0 0 6px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
}

.ott_plan_info {
    display: flex;
}

.ott_plan_name {
    color: #000000;
    font-size: 24px;
}

.ott_point_guide {
    display: flex;
    flex-direction: column;
}

.ott_point_guide_text_list {
    padding-bottom: 20px;
}

.ott_point_guide_text_list:last-of-type {
    padding-bottom: 0;
}

.ott_point_guide_text_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ott_point_guide_text {
    color: #000000;
    font-size: 12px;
    white-space: pre-line;
    word-break: break-all;
}

.point_text {
    color: #ff0000;
}

.ok_btn {
    border-radius: 0 0 10px 10px;
}
