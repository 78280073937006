.vod_lists {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.vod_list_title_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vod_list {
    width: 100%;;
}
