.section {
    margin: 128px 0 400px;
}

.card {
    width: 408px;
    height: 313px;
    padding: 32px 40px;
    margin: 32px auto;
    border-radius: 8px;
    background-color: #222;
}
.row {
    width: 100%;
}
.center {
    margin: 0 auto 0 0;
}
.button {
    width: 100%;
}
.finish_cont {
    margin-top: 65px;
    text-align: center;
}
.finish_txt {
    margin-bottom: 48px;
}
.select_result {
    margin: 60px 0 72px;
    display: flex;
}
.left_result {
    display: inline-block;
    width: 50%;
    border-right: 1px solid #222;

    strong {
        display: block;
        font-size: 24px;
        line-height: 28px;
        color: #fff;
        letter-spacing: -0.3px;
        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
    span {
        display: block;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.3px;
        color: #aaa;
        margin-top: 6px;
        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
    ol {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 8px;
        margin: 32px 0 0;
        padding: 0;

        li {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 144px;
                border-radius: 8px;
            }

            p {
                font-size: 16px;
                line-height: 25px;
                color: #fff;
                // letter-spacing: -.3px;
                margin-top: 12px;
            }
        }
    }
}
.right_result {
    display: inline-block;
    width: 50%;

    strong {
        display: block;
        font-size: 24px;
        line-height: 28px;
        color: #fff;
        letter-spacing: -0.3px;
        @media (max-width: 768px) {
            font-size: 16px;
        }
    }

    span {
        display: block;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.3px;
        color: #aaa;
        margin-top: 6px;
        @media (max-width: 768px) {
            font-size: 12px;
        }
    }

    ol {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
        margin: 32px 0 0;
        padding: 0;

        li {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 144px;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
            }

            p {
                font-size: 16px;
                line-height: 25px;
                color: #fff;
                letter-spacing: -0.3px;
                margin-top: 12px;
            }
        }
    }
}

@media (max-width: 768px) {
    .section {
        margin: 64px 0 60px;
    }

    .finish_cont {
        margin-top: 32px;
    }

    .select_result {
        margin: 30px 0 36px;
    }

    .finish_txt {
        margin-bottom: 24px;
    }

    .left_result {
        ol {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
    .right_result {
        ol {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}
