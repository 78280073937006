.select_wrapper {
    position: relative;
    width: 144px;
    height: 36px;
    padding: 6px 12px;
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: -0.3;
    color: #ffffff;
    border-radius: 4px;
    text-align: left;
    display: flex;
    align-items: center;

    &:after {
        content: '';
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background: url(../../../../assets/icon/icon-input-dropdown-down-on-black.svg) no-repeat;
    }

    .picker {
        color: white;
    }
}

.settings_pane {
    border-top: 1px solid #2a2a2a;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;

    &:last-of-type {
        border-bottom: 1px solid #2a2a2a;
    }
}

.au_img {
    width: 40px;
}

.docomo_img {
    width: 64px;
    background-image: url(/assets/img/logo-checkout-docomo-dark.png);
    background-size: contain;
}

.white .docomo_img {
    background-image: url(/assets/img/logo-checkout-docomo.png);
    background-size: contain;
}

.au_img {
    width: 40px;
}

.soft_bank_img {
    width: 103px;
    background-image: url(/assets/img/logo-checkout-soft-bank-dark.png);
    background-size: contain;
}

.white .soft_bank_img {
    background-image: url(/assets/img/logo-checkout-soft-bank.png);
    background-size: contain;
}

.linepay_img {
    width: 64px;
    background-image: url(/assets/img/logo-checkout-linepay-dark.png);
    background-size: contain;
}

.white .linepay_img {
    background-image: url(/assets/img/logo-checkout-linepay.png);
    background-size: contain;
}

.webmoney_img {
    width: 110px;
}

.pay_pay_img {
    width: 56px;
    background-image: url(/assets/img/logo-checkout-pay-pay-dark.png);
    background-size: contain;
}

.white .pay_pay_img {
    background-image: url(/assets/img/logo-checkout-pay-pay.png);
    background-size: contain;
}

.list_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.history {
    width: 100%;
    padding: 12px;
    border: 0;

    :last-of-type {
        border: 0;
    }
}
