.emoticon_list {
    width: 384px;
    border-radius: 8px;
    background-color: #333;

    .menu {
        height: 64px;
        display: flex;
        align-items: center;
        padding: 0 12px;

        > div {
            cursor: pointer;

            & + div {
                margin-left: 8px;
            }

            img {
                width: 42px;
                height: 42px;
                object-fit: contain;
            }
        }
    }

    .select {
        border-top:1px solid #444;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 22px;
        justify-content: space-between;
        height: 160px;
        overflow: scroll;

        > div {
            border-radius: 8px;
            background-color: #444;
            margin-bottom: 12px;
            cursor: pointer;

            img {
                width: 76px;
                height: 76px;
            }
        }
    }
}
