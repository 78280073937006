.post_wrapper {
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: #222;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;

    &:hover {
        transform: translateY(-12px);
    }
}

.row_1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;

        .title {
            width: 100%;
            font-size: 14px;
            font-weight: bold;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
        }
    }

    .thumbnail {
        width: 88px;
        height: 88px;
        object-fit: cover;
        position: relative;
        border-radius: 8px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.row_2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    .extra_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2px;
    }
}
