.xs {
    width: 20px;
    height: 20px;
}

.sm {
    width: 28px;
    height: 28px;
}

.md {
    width: 32px;
    height: 32px;
}

.lg {
    width: 36px;
    height: 36px;
}

.xl {
    width: 42px;
    height: 42px;
}

.xxl {
    width: 48px;
    height: 48px;
}

.auto {
    width: 100%;
    aspect-ratio: 1 / 1;
}

.user_profile {
    display: flex;
    align-items: center;
    gap: 4px;
}

.image {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    background-image: url(../../assets/icon/default_profile.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    overflow: hidden;
}

.cursor {
    cursor: pointer;
}
