//ChannelPlayer
.section_bg {
    width: 100%;
    height: 100vh;
    padding: 64px 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .player_section {
        width: 100%;
        aspect-ratio: 16 / 9;
        padding: 0;
        margin: 0;
        position: relative;
    }

    .info_wrapper {
        width: 100%;
        margin: 0;
        overflow: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
    }
}

.player_wrapper {
    display: flex;
}

.video_wrapper {
    width: 784px;
    height: 440px;
    margin-right: auto;
    position: relative;
}

.subtitles_input_wrapper {
    width: 100%;
    max-width: 1280px;
    height: 60px;
    padding: 0;
    margin-right: auto;
    position: relative;
}

//ChannelInfo
.active {
    svg {
        fill: #ff0086;
    }

    p {
        color: #ff0086;
    }
}

//Outlets
.box {
    width: 384px;
    height: 440px;
    padding: 14px;
    background-color: #141414;
    position: relative;
}

//NextScheduleListBox
.wrapper {
    width: 384px;
    height: 440px;
    overflow: hidden;
    padding: 14px;
    border: 1px solid #666;
    margin-left: 16px;
}

.hidden_scroll_list {
    width: calc(100% + 17px);
    max-height: 362px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding-right: 15px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
}

//ChannelChatBox
.chat_list {
    height: calc(100% - 38px);
    padding-bottom: 60px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.chat_item {
    margin-bottom: 12px;
}

.profile_image {
    margin-right: 12px;
}

.chat_body {
    flex: 1;
}

.content {
    max-width: 300px;
    word-break: break-all;
}

.chat_input_box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    background-color: #1f1f1f;
    height: 60px;

    .form_wrapper {
        display: flex;
        align-items: center;
        width: 100%;

        .input_wrapper {
            display: flex;
            align-items: center;
            flex: 1;
            height: 36px;
            margin: 0 8px 0 0;
            padding: 8px 16px;
            border-radius: 18px;
            background-color: #fcfcfc;
        }
        input {
            flex: 1;
        }
        .send_icon {
            display: block;
        }
        button:disabled {
            cursor: not-allowed;
        }
    }
    .emoji_icon {
        cursor: pointer;
    }
}

//ChannelEPGBox
.epg_list {
    height: calc(100% - 38px);
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.schedule_item {
    position: relative;
    height: 84px;
    padding: 14px 20px;
    border: none;
    border-top: 1px solid #666;
    border-radius: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    &:hover {
        border-color: #666;
    }
}

.schedule_img {
    width: 100px;
    height: 56px;
    object-fit: cover;
}

.default_image {
    width: 100px;
    height: 56px;
    object-fit: contain;
    padding: 16px;
    background-color: #aaa;
}

.schedule_live_badge {
    position: absolute;
    top: 14px;
    left: 20px;
}

.schedule_info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    margin-left: 8px;
}

.schedule_title {
    width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// EvnetDetailInfobox
.info_box {
    width: 384px;
    height: 440px;
    padding: 14px 14px;
    background-color: #151515;
}
.artist_list {
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.artist_item {
    margin: 4px 8px;
}
.artist_title {
    max-width: 56px;
    word-break: break-all;
    text-align: center;
}

// ChannelVideoRoot
.dimm {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}
