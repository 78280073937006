.container {
    padding: 0px 12px;
}

.question_container {
    padding-bottom: 24px;
    border-bottom: 1px solid #222;

    p,
    strong {
        color: #666;
        margin-bottom: 8px;
        font-size: 14px;
    }
}

.question_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.question_box h3 {
    font-size: 24px;
    letter-spacing: 3px;
}
.question_container .question_box a {
    color: #00c2ff;
}
.header_date {
    margin-top: 9px;
}

.answer_container {
    padding: 24px 0px;
    border-bottom: 1px solid #222;
}

.commnet_container {
    margin: 24px auto;
    border-bottom: 1px solid #222;
    h3 {
        font-size: 24px;
        line-height: 34px;
    }
}
.commnet_section {
    margin-top: 24px;
}
.comment_header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 48px;
    padding: 0px 24px;
    background-color: #222;
    color: #aaa;

    p {
        font-size: 13px;
    }
}

.comment_body {
    padding: 24px;
}

.comment_body p {
    color: #eee;
}

.button_container {
    display: flex;
    align-items: center;
    justify-content: center;
    // border-top: 1px solid #222;
    margin-top: 24px;
    padding: 0px 0px 90px;
}

.button {
    width: 240px;
    height: 54px;
    border-radius: 40px;
    background-color: transparent;
    border: 1px solid #fff;
    &:hover {
        background-color: #fff;
        color: #000;
        transition: all 0.15s ease-in;
    }
}

// CommentForm
.form_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #222;
}
.form_container h3 {
    font-size: 24px;
    margin-bottom: 24px;
}
.form_container textarea {
    background-color: #fff;
    height: 176px;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 12px;
}

.btn_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .btn_container {
        flex-direction: column;
    }
}

.file_container {
    margin-block: 16px;
    display: flex;
    align-items: center;
}

.file_container p {
    width: 100%;
    height: 36px;
    padding: 0px 12px;
    background-color: #fff;
    color: #222;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.file_container button {
    width: 96px;
    height: 36px;
    background-color: #444;
    color: #fff;
    border-radius: 18px;
    margin-left: 10px;
    cursor: pointer;
}

.file {
    height: 36px;
    width: 96px;
    overflow: hidden;
    margin-left: -96px;
    border-radius: 18px;
    opacity: 0;
    cursor: pointer;
}

.submit_btn {
    background-color: #ff0086;
    width: 72px;
    height: 36px;
    border-radius: 30px;
    color: #fff;
}
