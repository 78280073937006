.banner_wrapper {
    width: 100%;
    padding-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.effect {
    transition: all 0.3s ease;
    &:hover {
        transform: translateY(-16px);
    }
}

.banner {
    width: 100%;
    aspect-ratio: 16 / 9;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 8px;
}
