.wrapper {
    display: inline-block;
    width: 500px;
    margin: 0 auto;
}

.main_title {
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    text-align: center;
    white-space: pre-line;
}

.info {
    width: 100%;
    margin: 16px 0 60px;
    font-size: 13px;
    line-height: 20px;
    color: #aaa;
    text-align: center;
    white-space: pre-line;
}

.content_wrapper {
    margin-bottom: 33px;
}

.label {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
}

.result_text {
    margin-top: 12px;
    font-size: 14px;
    color: #ff0086;
    line-height: 20px;
    padding: 12px 14px;
    border-radius: 2px;
    border: 1px solid #333;
}

.button_wrapper {
    margin: 60px 0;
    > * {
        margin: 0 6px;
        width: calc(50% - 12px);
        border-radius: 30px;
    }
}

//SetActionContent
.checkWrap {
    margin-top: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.check_label {
    line-height: 20px;
    font-size: 14px;
    color: #fff;
}
