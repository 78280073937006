.artist_card_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
}

@media (max-width: 768px) {
    .artist_card_list {
        grid-template-columns: repeat(2, 1fr);
    }
}
