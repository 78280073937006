.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

.image {
    width: 100%;
    border-radius: 8px;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}

@media (max-width: 1024px) {
    .anchor_wrapper {
        padding: 0;
    }
}
