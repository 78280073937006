.wrap {
    min-height: 100vh;
    overflow: scroll;
    background: #000;
}

.white .wrap {
    background: #fff;
}

.privacy_wrap {
    padding: 102px 24px 28px;
}

.privacy_wrap p {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: #aaa;
    word-break: break-all;
}

.white .privacy_wrap p {
    color: #666;
}