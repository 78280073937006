//Videoroot
.video_root_wrapper {
    position: relative;
}

//SkipeButton & PreviewSkipButton
.skip_btn {
    position: absolute;
    height: 36px;
    font-size: 13px;
    left: 2.5%;
    bottom: 18%;
    z-index: 3;
    padding: 8px;
    border-radius: 4px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-color: rgba(68, 68, 68, 0.7);
    color: white;
}
