.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
}

.title_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}

.title {
    flex: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
}

.badge {
    width: 60px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description {
    word-break: break-word;
    white-space: pre-line;
}

.banner_img {
    width: 100%;
    aspect-ratio: 9/2;
    object-fit: contain;
}
