.wrapper {
    width: 100%;
    max-width: 768px;
    height: 64px;
    padding: 10px 24px;
    background-color: #141414;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left {
    display: flex;
    align-items: center;
}

.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav_item {
    width: 84px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;

    .event_img {
        width: 100%;
        object-fit: none;
    }
}

@media (max-width: 768px) {
    .nav_item {
        right: 126px;
    }
}
