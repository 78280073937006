.button {
    border-radius: 4px;
    text-align: center;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
    }
}
.main {
    background-color: #ff0086;
    color: #fff;
}
.sub {
    border: 1px solid #00ff96;
    color: #141414;
    background-color: #00ff96;
}
.black {
    border: 1px solid #fff;
    color: #fff;
    background-color: #141414;
}
.gray {
    border: 1px solid #bbb;
    color: #bbb;
    background-color: #141414;
}
// addeded a new variant with gray bg and no border
.passive {
    color: #fff;
    background-color: #999;
}

.xs {
}
.sm {
}
.md {
    min-width: 100px;
    min-height: 40px;
    font-size: 14px;
    font-weight: 600;
}
.lg {
    font-size: 16px;
}
.xl {
}

// InlineBtn
.inline_btn {
    display: inline-flex;
    color: #999;
    align-items: center;
    padding-left: 1rem;
}
