.sub_header {
    margin-top: 0;
    margin-bottom: 42px;
    border-top: 0;
    padding-top: 16px;
}

.go_to_today {
    padding: 8px;
}

.event_card {
    max-width: 100%;
    margin-bottom: 16px;
}

.date {
    width: 88px;
    height: 112px;
    padding: 32px 24px;
    border-radius: 8px 0 0 8px;
    background-color: #333;
}

.content {
    // width: 1096px;
    flex-grow: 1;
    height: 112px;
    padding: 26px 20px 26px 24px;
    border-radius: 0 8px 8px 0;
    background-color: #222;
}

.aritst_title {
    margin-right: 8px;
}

.album_image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 8px;
}

.today {
    background-color: #ff0086;
}
