.player_section {
    width: 100%;
    height: 100vh;
    padding: 64px 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .player_wrapper {
        width: 100%;
        padding: 0;
        margin: 0;
        position: relative;
    }

    .info_wrapper {
        width: 100%;
        margin: 0;
        overflow: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
    }
}

@media (max-width: 768px) {
    .player_section {
        .info_wrapper {
            gap: 8px;
        }
    }
}

//PlayerDetailVOD
.nav_list {
    height: 61px;

    [class~='active'] {
        svg {
            fill: #ff0086;
        }

        p {
            color: #ff0086;
        }
    }
}

.box {
    width: 100%;
    padding: 0 12px;
    position: relative;
}

.external_link {
    display: inline-block;
    background-color: #ff0086;
    padding: 12px 24px;
    margin-top: 24px;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    font-weight: 600;
}

//EpisodeListBox
.episode_btn {
    flex: 1;
    padding: 14px 0;
    background-color: #222;
}

.back_btn_box {
    display: flex;
    align-items: center;
    background-color: #222;
}

.comment_btn {
    flex: 1;
    padding: 14px 0;
    background-color: #111;
}

.wrapper {
    flex: 1;
    overflow: hidden;
    padding: 14px;
}

.hidden_scroll_list {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

//EpisodeItem

.episode_item {
    padding: 14px;
    border-bottom: 1px solid #666;
    border-radius: 4px;
}

.playing {
    border: 1px solid #ff0086;
}

.title {
    width: 170px;
}

.edit_input_wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.spacer {
    flex: 1;
    position: relative;
}

.stat_text {
    margin: 0 24px 0 2px;
}

//CommentRead
.comment_read_box {
    flex: 1;
    position: relative;
    height: 100%;
}

.read_comment_list {
    height: 300px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }
}
.icon {
    cursor: pointer;
}

//DeatialInfoBox
.info_box {
    width: 384px;
    height: 440px;
    padding: 14px 14px;
    background-color: #151515;
}

.sub_title {
    margin-bottom: 6px;
}

.sub_content {
    color: #aaa;
}
.artist_list {
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -8px;
}

.artist_item {
    margin: 4px 8px;
}

.artist_title {
    max-width: 56px;
    word-break: break-all;
    text-align: center;
    color: #aaa;
    font-size: 12px;
}
