.container {
    padding: 0px 12px;
    margin-bottom: 60px;
}

@media (max-width: 1024px) {
    .container {
        margin-bottom: 30px;
    }
}

.title {
    margin-bottom: 20px;
}

.navigation {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .nav_item {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .category_title {
            padding: 0 10px;
            border-left: 1px solid #333;
            color: #666;

            &.active {
                color: #fff;
            }
        }

        &:nth-child(1) {
            .category_title {
                border: none;
                padding-left: 0px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .navigation {
        flex-wrap: nowrap;
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .nav_item {
            height: 60px;
            white-space: nowrap;
        }
    }
}

.table_header {
    display: flex;
    align-items: center;
    background-color: #222;
    height: 48px;
}
.table_header p {
    font-size: 13px;
    color: #555;
    &:nth-child(1) {
        width: 220px;
        text-align: center;
    }
    &:nth-child(2) {
        flex-grow: 1;
    }
    line-height: 48px;
}

.frequently_asked_questions {
    margin-bottom: 100px;
}
.frequently_asked_questions h3 {
    margin: 60px 0 24px;
    width: 100%;
    border-bottom: 1px solid #222;
    font-size: 24px;
}
