.userInfo {
    h3 {
        font-size: 24px;
    }
}

.wrapper {
    width: 100%;
    padding: 96px 12px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

@media (max-width: 768px) {
    .wrapper {
        padding: 64px 12px 0;
    }
}

.tab_buttons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .tab_button {
        width: 100%;
        flex: 1;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
            border-bottom: 1px solid white;
        }
    }
}

.payment_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
}
