// PopularCommunitySection
.main_popular_community {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

.title_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
    span {
        font-size: 14px;
        color: #aaa;
    }
}

// CommunityCard
.community_card {
    padding: 0 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.community_thumbnail {
    width: 100%;
    position: relative;
}

.rank {
    position: absolute;
    bottom: -4px;
    left: 0;
    font-size: 32px;
    font-weight: bold;
}

.caption {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 14px;
    text-align: center;
    color: #aaa;
    max-width: 100px;
}

.heart {
    margin-left: auto;
}
