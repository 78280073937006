.label {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.small {
    padding: 0.25rem 0.4rem;
    font-size: 0.25rem;
    font-weight: 500;
    border-radius: 4px 0 4px 0;
}

.medium {
    padding: 0.5rem 0.8rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 8px 0 8px 0;
}

@media (max-width: 500px) {
    .medium {
        padding: 2px 6px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 8px 0 4px 0;
    }
}
