.div_background {
    width: 408px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 60px 0 24px;
    background: #000;
}

.white .div_background {
    background: #fff;
}

.h2_title {
    width: 100%;
    margin: 0px 24px 16px 24px;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #fff;
    flex-grow: 1;
}

.white .h2_title {
    color: #000;
}

.div_card_box {
    padding: 0 24px;
}