.wrap {
    min-height: 100vh;
    background: #000;
    overflow: scroll;
}

.white .wrap {
    background: #fff;
}

.fc_signup {
    padding: 84px 24px 0;
}

.fc_signup h2 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #fff;
}

.white .fc_signup h2 {
    color: #000;
}

.fc_signup h2:first-of-type {
    margin-bottom: 16px;
}
    
// 다음 button
.fc_signup .next_btn {
    display: block;
    width: 100%;
    height: 56px;
    border-radius: 28px;
    margin-bottom: 24px;
    background: #ff0086;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.3px;
    color: #fff;
}

.fc_signup p.noti_txt {
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: #fff;
    word-break: keep-all;
    word-wrap: break-word;
}

.white .fc_signup p.noti_txt {
    color: #000;
}
