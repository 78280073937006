.renderer {
    all: initial;
    all: unset;
    width: 100%;

    ul > li {
        list-style: disc;
        margin-left: 14px;
    }
    ol > li {
        list-style: decimal;
        margin-left: 14px;
    }
}

.post_section_wrapper {
    width: 100%;
    padding-top: 222px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.post_header {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .header_info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        .header_left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;

            .views {
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }
    }
}

.post {
    width: 100%;
    margin: 0 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    .back_button_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .go_back {
            padding: 12px 82px;
        }
    }

    .status_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .status_right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 24px;

            .collection {
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
}

.comment_input_section {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding: 0;
    margin: 0;
    background-color: #141414;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    .comment_input {
        width: 100%;
        max-width: 768px;
        padding: 0 12px;
    }
}

.popup {
    background-color: #666666;
    width: 152px;

    p {
        display: block;
        padding: 12px 16px;
        height: 48px;
        cursor: pointer;
    }
}
