.tag_box {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .tag_button {
        padding: 4px 12px;
        border: 1px solid #aaaaaa;
        border-radius: 14px;
        transition: 0.4s;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .title {
            color: #aaaaaa;
            font-size: 10px;
            font-weight: 600;
            transition: 0.4s;
        }

        &:hover {
            border-color: #444 !important;

            .title {
                color: #444 !important;
            }
        }
    }

    .community_title_button {
        border: 1px solid #ff0086;

        .community_title {
            color: #ff0086;
        }
    }
}
