.wrapper {
    width: 100%;
    height: 100vh;
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 8px;
    font-size: 20px;
}

.info_wrapper {
    flex: 1;
}

.submit_button {
    width: 100%;
    height: 56px;
    padding: 8px 0;
    border-radius: 24px;
}

.loading_modal_container {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: black;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 50px;
    height: 50px;
    margin: 0 auto 1rem;
    border: 3px solid white;
    border-radius: 50%;
    border-left-color: #ff0086;
    -webkit-animation: spin 1000ms infinite linear;
    -moz-animation: spin 1000ms infinite linear;
    -ms-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    100% {
        -ms-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
