.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.channel_wrapper {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.channel_info {
    width: 100%;
    height: 82px;
    padding: 12px 16px;
    background-color: #1f1f1f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.img_wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
}

.img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 9;
}

.live_badge {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 54px;
    height: 28px;
}

.progress_bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    background-color: #ff0086;
    max-width: 100%;
}

.text {
    flex: 1;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

// ChannelCard
.title_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}
