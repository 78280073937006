.wrapper {
    width: 300px;
    padding: 24px;
    background-color: #252525;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
}

.header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .tag {
        font-size: 1.14em;
        font-weight: bold;
    }
}

.text {
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: white;
}

.twit_button {
    width: 100%;

    .button {
        width: 100%;
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
