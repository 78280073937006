.user_name_wrapper {
    display: flex;
    align-items: center;
}

.certi_icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
}

.exit {
    opacity: 0.5;
}

.cursor {
    cursor: pointer;
}
