.card_img_box {
    vertical-align: top;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card_img_box .card_img input[type='radio'] {
    width: 0 !important;
    height: 0 !important;
    padding: 0;
    border: 0;
    border-radius: 0;
    margin: 0;
    color: transparent;
    background-color: transparent;
    font-size: 0;
    line-height: inherit;
    letter-spacing: 0;
}

.card_img_box .card_img input[type='radio'] + label {
    display: block;
    position: relative;
    width: 100%;
    height: 44px;
    border: 2px solid transparent;
    border-radius: 5px;
    text-align: center;
}

.card_img_box .card_img input[type='radio']:checked + label {
    border: 2px solid #ff0086;
    border-radius: 5px;
}

.inp_box {
    margin-top: 24px;
}

.inp_box input {
    width: 100%;
    height: 40px;
    padding: 0 12px;
    border: 1px solid #eee;
    margin-top: 12px;
    background-color: #fff;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: -0.3px;
    color: #000;
}

.inp_box p {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: #fff;
}

.white .inp_box p {
    color: #000;
}

.inp_box .card_num_1 input {
    width: 25%;
    height: 40px;
    margin-top: 12px;
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: -0.3px;
    color: #000;
}

.inp_box .card_num_1 input.big_card_number {
    width: 50%;
}

.card_num_1 input:nth-child(1) {
    padding-left: 12px;
    border-top: 1pt solid #eee;
    border-bottom: 1pt solid #eee;
    border-left: 1px solid #eee;
    border-right: 0;
}

.card_num_1 input:nth-child(2) {
    border-top: 1pt solid #eee;
    border-bottom: 1pt solid #eee;
    border-left: 0;
    border-right: 0;
}

.card_num_1 input:nth-child(3) {
    border-top: 1pt solid #eee;
    border-bottom: 1pt solid #eee;
    border-left: 0;
    border-right: 0;
}

.card_num_1 input:nth-child(4) {
    padding-left: 12px;
    border-top: 1pt solid #eee;
    border-bottom: 1pt solid #eee;
    border-left: 0;
    border-right: 1px solid #eee;
}

.inp_box .card_num_2 input {
    width: 25%;
    height: 40px;
    margin-top: 12px;
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: -0.3px;
    color: #000;
}

.card_num_2 input:nth-child(1) {
    padding-left: 12px;
    border-top: 1pt solid #eee;
    border-bottom: 1pt solid #eee;
    border-left: 1px solid #eee;
    border-right: 0;
}

.card_num_2 input:nth-child(2) {
    width: 50%;
    border-top: 1pt solid #eee;
    border-bottom: 1pt solid #eee;
    border-left: 0;
    border-right: 0;
}

.card_num_2 input:nth-child(3) {
    padding-left: 12px;
    border-top: 1pt solid #eee;
    border-bottom: 1pt solid #eee;
    border-left: 0;
    border-right: 1px solid #eee;
}

.inp_box .card_num_3 input {
    width: 25%;
    height: 40px;
    margin-top: 12px;
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: -0.3px;
    color: #000;
}

.card_num_3 input:nth-child(1) {
    padding-left: 12px;
    border-top: 1pt solid #eee;
    border-bottom: 1pt solid #eee;
    border-left: 1px solid #eee;
    border-right: 0;
}

.card_num_3 input:nth-child(2) {
    width: 50%;
    border-top: 1pt solid #eee;
    border-bottom: 1pt solid #eee;
    border-left: 0;
    border-right: 0;
}

.card_num_3 input:nth-child(3) {
    padding-left: 12px;
    border-top: 1pt solid #eee;
    border-bottom: 1pt solid #eee;
    border-left: 0;
    border-right: 1px solid #eee;
}

.row_box .inp_box {
    display: inline-block;
    vertical-align: top;
    width: 66.6666%;
}

.row_box .inp_box select {
    width: calc(50% - 6px);
}

.inp_box select {
    width: 100%;
    height: 40px;
    padding: 0 12px;
    border: 1px solid #eee;
    margin-top: 12px;
    background-color: #fff;
    text-align: left;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: -0.3px;
    color: #000;
}

.inp_box select:nth-child(3) {
    margin-left: 12px;
}

.row_box .inp_box:nth-child(2) {
    width: calc(33.3334% - 12px);
    margin-left: 12px;
}

.inp_box select * {
    font-size: 14px;
}

.row_box .noti_box {
    margin-top: 24px;
}

.row_box .noti_box p {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.3px;
    color: #666;
    word-break: keep-all;
    word-wrap: break-word;
}

.white .row_box .noti_box p {
    color: #999;
}

.row_box .noti_box p strong {
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    color: #666;
    letter-spacing: -0.3px;
}

.row_box .noti_box p strong {
    color: #999;
}

.inp_box .noti_txt {
    display: block;
    position: relative;
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.3px;
    color: #999;
}

.inp_box .noti_txt:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 0 6px;
    background-color: #aaa;
    vertical-align: middle;
}

.next_btn {
    display: block;
    width: 100%;
    height: 56px;
    border-radius: 28px;
    margin-top: 36px;
    background-color: #ff0086;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.3px;
    color: #fff;
}

.card_noti_box {
    margin-top: 24px;
}

.card_noti_box p {
    display: block;
    position: relative;
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.3px;
    color: #999;
    word-break: keep-all;
    word-wrap: break-word;
}

.word_break {
    white-space: pre-line;
    word-break: break-all;
}
