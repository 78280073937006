.modal_container {
    font-family: 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif;
    border-radius: 8px;
    padding: 24px;
    background-color: #222;
    text-align: center;
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;

        .wrapper {
            width: 100%;
            color: white;
            font-size: 18px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .change_plan_wrapper {
                width: 100%;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;

                .prev_plan {
                    color: white;
                }

                .arrow {
                    color: #666;
                }

                .change_plan {
                    color: #ff0086;
                }
            }
        }

        .feature_map_wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .feature_map {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 0 24px;
                line-height: 24px;

                span:last-of-type {
                    color: #00f6ff;
                }
            }
        }
    }

    .footer {
        width: 100%;

        button {
            font-size: 12px;
            margin-bottom: 12px;
            width: 100%;
            height: auto;
            border-radius: 34px;
            padding: 12px 0;

            span {
                font-weight: 400;
            }
        }

        .register_btn {
            border: 1px solid rgba(255, 255, 255, 0.2);
            background-color: transparent;
        }
    }
}
