//ChattingLandscape
.chatting_landscape {
    margin: 62px 16px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 360px;
    height: 85%;
    background-color: transparent;
    z-index: 3;
}
.chat_list {
    flex: 1;
    width: 100%;
    height: 90%;
    overflow: scroll;
    flex-direction: column-reverse;
    align-items: flex-end;
}
.chat_item {
    max-width: 330px;
    margin-bottom: 4px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 8px 16px 7px 12px;
    border-radius: 4px;
}
.profile_image {
    margin-right: 12px;
}
.chat_body {
    flex: 1;
}
.content {
    max-width: 300px;
    word-break: break-all;
}

//epglandscape
.landscape {
    position: absolute;
    top: 0;
    right: 0;
    width: 360px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 3;
}
