.wrapper {
    width: 100%;
    max-width: 768px;
    padding: 222px 15px 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.title {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background-color: #434343;
    color: white;

    &::placeholder {
        color: #ffffff4d;
    }
}

.select {
    width: 100%;
}

.textarea {
    width: 100%;
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    background-color: #434343;
    color: white;
}

.select_wrapper {
    width: 100%;
}

.select_button {
    width: 100%;
    padding: 6px 12px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.button_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.button {
    flex: 1;
}

.cancel_button {
    width: 100%;
}
