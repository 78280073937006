.appearance_section {
    padding-top: 220px;
}

.appearance_container {
    margin: 0 -8px;
}

.img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 24px;
}

.duration {
    color: #aaa;
}
