.card_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin: 24px 0;
    & > li {
        width: 100%;
        min-height: 244px;
    }
}

@media (max-width: 768px) {
    .card_list {
        grid-template-columns: repeat(1, 1fr);
    }
}

.selector {
    z-index: 3;
}

.link {
    width: 100%;
}

.frame {
    height: 100%;
    border-radius: 8px;
    background-color: #222;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
}

.card_img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: block;
    background-color: gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    box-sizing: border-box;
}

.card_info {
    width: 100%;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 16px;
}

.point {
    font-size: 16px;
    color: #ff0086;
}
