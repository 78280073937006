.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .title_wrapper {
        width: 100%;
        height: 58px;
        padding: 0 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
    }
}

.hidden_scroll_list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    box-sizing: content-box; /* So the width will be 100% + 17px */
}
