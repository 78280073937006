.collection_card {
    position: relative;
    transition: all 0.3s ease;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    &:hover {
        transform: translateY(-4px);
    }
}

.collection_card_add {
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #ff0086;
    border-radius: 8px;

    &:hover {
        transform: none;
    }
}

.collection_card_add::after {
    content: '';
    width: 32px;
    height: 32px;
    background: url(https://stam-p.com/@project/m3/img/icon-collection-new-web.png) no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.collection_card_add_title {
    padding-left: 3px;
}

.image {
    display: block;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #444444;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 8px;
}

.card_title {
    width: 100%;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.active:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 0, 134, 0.75);
    border-radius: 8px;
    z-index: 2;
}

.active:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    background-image: url(../../assets/icon/icon-checker-check-copy.svg);
    background-size: contain;
    z-index: 2;
    transform: translate(-50%, -50%);
}

.active:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 0, 134, 0.75);
    z-index: 1;
    border-radius: 8px;
}
