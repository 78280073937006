.wrapper {
    width: 100%;
    padding: 16px 0 0;
}

//Block
.separator {
    display: inline-block;
    height: 12px;
    border-right: 1px solid #333;
    padding-left: 12px;
}

.collection_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 32px;
}

@media (max-width: 768px) {
    .collection_wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}

.collection_card {
    width: 100%;
    display: flex;
    align-items: center;
}

// BlockCard
.block_card {
    padding: 24px;
    background-color: #222;
    border-radius: 8px;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    display: flex;

    img {
        border-radius: 50%;
        margin-right: 12px;
    }

    div {
        display: flex;
        align-items: center;
    }

    span {
        font-size: 16px !important;
        color: white !important;
        margin-left: 12px !important;
        font-weight: bold !important;
        letter-spacing: -0.3px !important;
    }
}
