.section {
    width: 100%;
    padding: 96px 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .title {
        width: 100%;
        padding: 0 12px;
    }
}

@media (max-width: 768px) {
    .section {
        padding: 64px 0 0;
    }
}

.button {
    width: 100%;
    height: 60px;
    padding: 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .setting_title {
        min-width: 100px;
        flex: 1;
        font-size: 1.14em;
        font-weight: bold;
    }

    .right_img {
        width: 36px;
        height: 36px;
    }
}
