.wrapper {
    width: 300px;
    padding: 12px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.header {
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.target_user_id {
    text-align: center;
    color: black;
    font-weight: bold;
}

.button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .button_text {
        color: #ff0086;
    }

    .cancel_button_text {
        color: #aaa;
    }
}
