.plan_modal_container {
    border-radius: 8px;
    background-color: #222;
}

.head {
    padding: 16px 24px;
}

.plan_modal_footer {
    display: flex;
    flex: 1;
    width: 100%;

    button {
        width: 100%;
        border-radius: 0px;
        height: 48px;
    }
}

.cancel_btn {
    background-color: #666666;
}
