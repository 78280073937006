// SearchView
.search_view_box {
    position: absolute;
    left: 0;
    top: 60px;
    width: 332px;
    padding: 10px 0;
    border-radius: 8px;
    box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.25);
    background-color: #444;
}

.text {
    font-size: 14px;
    margin-left: 4px;
}

// SearchViewItem
.item {
    :hover {
        background-color: #333;
        transition: all 0.2s ease-in-out;
    }
}

.wrraper {
    display: flex;
    padding: 8px 12px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.left {
    display: flex;
    align-items: center;
}

.artist {
    color: #ff0086;
    font-weight: 600;
}
