// VoteSection
.section_title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
    span {
        font-size: 14px;
        color: #aaa;
    }
}

.vote_card {
    aspect-ratio: 10 / 8;
    border-radius: 8px;
    background-color: #222;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;

    .title_wrapper {
        width: 100%;
        flex: 1;
        padding: 8px 36px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .vote_title {
            width: 100%;
            flex: 1;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.3;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            word-break: break-all;
        }
    }
}

@media (max-width: 768px) {
    .vote_card {
        aspect-ratio: 10 / 9;
    }
}

@media (max-width: 500px) {
    .vote_card {
        aspect-ratio: 1 / 1;
    }
}

// VoteCard
.apply_title_wrapper {
    padding: 12px 0;
}

.candidate_wrapper {
    width: 100%;
    padding: 0 24px 12px;
}

.vote_image {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}

// VotePoolCard
.vote_pool_image {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}

// VotePoolNextPool
.next_pool_wrapper {
    width: 100%;
    padding: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .next_pool {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        .next_pool_info {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 8px;
        }
    }
}

// Candidate
.candidate {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    color: white;
    border-top: 1px solid #333;
    padding: 12px 0;

    .candidate_index {
        font-size: 16px;
        font-weight: 600;
    }

    .candidate_title {
        flex: 1;
        font-size: 14px;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .image_wrapper {
        border-radius: 25px;
    }
}

.custom_title {
    min-height: 42px;
    display: flex;
    align-items: center;
}
