.wrapper {
    width: 100%;
    min-height: 100vh;
    padding: 220px 0 60px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
}

@media (max-width: 768px) {
    .wrapper {
        padding: 184px 0 30px;
        gap: 12px;
    }
}

.title_wrapper {
    width: 100%;
    padding: 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.artist_info_wrapper {
    width: 100%;
    padding: 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    .artist_info {
        min-width: 100px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;

        .title {
            font-size: 1.14em;
            font-weight: bold;
        }

        .date {
            color: #666666;
            font-size: 1em;
        }
    }
}

.praline_title_wrapper {
    width: 100%;
    padding: 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .praline_title {
        min-width: 100px;
        flex: 1;
        font-size: 1.71em;
        font-weight: bold;
    }

    .copy_button {
        min-width: 10px;
        min-height: 10px;
        padding: 8px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .copy_text {
            font-size: 0.85em;
        }
    }
}

.body {
    width: 100%;
    min-height: 100px;
    flex: 1;
    padding: 0 12px;
}

.twitter_button_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .twitter_button {
        padding: 12px 24px;
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;

        .twitter_button_text {
            font-size: 1.14em;
            text-align: center;
            white-space: pre-line;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
}
