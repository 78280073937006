@import 'styles/App.scss';

.wrapper {
    padding: 36px 12px;

    .announce_wrapper {
        margin: 40px 0 0;
    }
}

@media (max-width: 768px) {
    .wrapper {
        padding: 18px 6px;

        .announce_wrapper {
            margin: 20px 0 0;
        }
    }
}

// Method
.method_wrapper {
    width: 100%;
    padding: 84px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .method_title {
        font-size: 3.57em;
        font-weight: 900;
        text-align: center;
        color: #232323;
    }

    .method_list {
        width: 100%;
        padding: 64px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 36px;

        li {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .method_label {
                width: 100%;
                padding: 0 12px;
                font-size: 1.78em;
                color: #232323;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }

        [type~='radio'] {
            border: 1.5px solid gray;
            width: 21px;
            height: 21px;
            border-radius: 50%;
            background-color: transparent;
            margin-right: 10px;

            &:checked {
                border: 6px solid $main;
                background-color: white;
            }
        }
    }
}

@media (max-width: 768px) {
    .method_wrapper {
        padding: 42px 0 0;

        .method_title {
            font-size: 1.78em;
        }

        .method_list {
            padding: 32px 0 0;

            li {
                .method_label {
                    padding: 0 6px;
                    font-size: 0.89em;
                }
            }
        }
    }
}

// MethodDetail
.next_button {
    width: 100%;
    padding: 20px 0;
    border-radius: 28px;
    margin: 40px 0 0;

    .next_button_text {
        font-size: 2.14em;
        font-weight: 900;
        color: #fffefe;
    }
}

@media (max-width: 768px) {
    .next_button {
        padding: 10px 0;
        border-radius: 14px;
        margin: 20px 0 0;

        .next_button_text {
            font-size: 1.07em;
        }
    }
}

.method_detail_wrapper {
    width: 100%;
    padding: 28px 12px 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;

    .method_logo {
        margin: 0 0 0 28px;
    }

    .method_detail_text {
        width: 100%;
        padding: 0 12px;
        font-size: 1.5em;
        line-height: 1.4;
        color: #232323;
        white-space: pre-line;
        word-break: break-word;
    }
}

@media (max-width: 768px) {
    .method_detail_wrapper {
        padding: 14px 6px 0;
        gap: 12px;

        .method_logo {
            margin: 0 0 0 14px;
        }

        .method_detail_text {
            padding: 0 6px;
            font-size: 0.75em;
        }
    }
}
