.figure {
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader {
    path {
        fill: #ff0086;
    }
}
