// PopularCommunitySection
.main_popular_community {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

.list_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
}

.card_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.rank {
    width: 24px;
    font-size: 18px;
    font-weight: bold;
}

.title {
    font-size: 14px;
    font-weight: bold;
}

.more_button {
    width: 100%;
    padding: 14px;
    border: 1px solid #333333;
    border-radius: 8px;
    display: flex;
    justify-content: center;
}

.more_text {
    font-size: 14px;
    font-weight: bold;
    color: white;
}
