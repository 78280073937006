.menu_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .menu {
        white-space: nowrap;
    }

    [class~='active'] {
        .menu {
            opacity: 1;
        }
    }
}

@media (max-width: 768px) {
    .menu_wrapper {
        flex-wrap: nowrap;
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.menu {
    opacity: 0.6;
    transition: 2s;

    &:hover {
        opacity: 0.9;
    }
}
