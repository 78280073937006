.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.apply_footer {
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .status_wrapper {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ott_effect_wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .ott_effect {
            white-space: nowrap;
        }
    }
}
