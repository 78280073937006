$md-radio-checked-color: white;
$md-radio-border-color: #666;
$md-radio-size: 20px;
$md-radio-checked-size: 8px;
$md-radio-ripple-size: 15px;

@keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
    }

    50% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
    }

    100% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
    }
}

.radioButton {
    margin: 16px 0;
    display: inline-block;
    input[type='radio'] {
        display: none;

        &:checked + label:before {
            border-color: #ff0086;
            animation: ripple 0.2s linear forwards;
            background: #ff0086;
        }

        &:checked + label:after {
            transform: scale(1);
        }
    }

    label {
        display: inline-block;
        height: $md-radio-size;
        position: relative;
        padding: 0 ($md-radio-size + 10px);
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;

        &:before,
        &:after {
            position: absolute;
            content: '';
            border-radius: 50%;
            transition: all 0.3s ease;
            transition-property: transform, border-color;
        }

        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 1px solid $md-radio-border-color;
        }

        &:after {
            top: 7px;
            left: 7px;
            width: $md-radio-checked-size;
            height: $md-radio-checked-size;
            transform: scale(0);
            background: $md-radio-checked-color;
        }
    }
}
