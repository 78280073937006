.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
}

.method {
    width: 100%;
    padding: 40px 0;
}

.method_info_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.method_control_box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

@media (max-width: 1024px) {
    .method_control_box {
        flex-direction: column;
    }
}

.method_control_btn {
    padding-left: 0;
    color: #aaa;
}
