.pagination {
    padding-top: 24px;
    text-align: center;

    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        ol {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        ol li {
            display: inline-block;
            margin: 0 2px;
            padding: 0 !important;
            width: 36px;
            height: 36px;
            position: relative;
            cursor: pointer;
        }
    }
}

@media (max-width: 1024px) {
    .pagination ol {
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}

.active {
    border: 1px solid #ff0086 !important;
    color: #ff0086 !important;
    cursor: default;
}
.pagination ol li div {
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid #333;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 35px;
    color: #fff;
    background-color: transparent;
    transition: 0.3s;
}
.pagination ol li div i {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 6px 12px;
    background-position: center center;
}
.prev_icon {
    background-image: url(/assets/img/icon_pagination_next.png);
    transform: rotate(180deg);
    &.disabled {
        background-image: url(/assets/img/icon_pagination_prev_disabled.png);
        transform: none;
    }
}
.next_icon {
    background-image: url(/assets/img/icon_pagination_next.png);
    &.disabled {
        background-image: url(/assets/img/icon_pagination_prev_disabled.png);
        transform: rotate(180deg);
    }
}
