.frame {
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
}

.card {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
        width: 100%;
        height: 100%;
        aspect-ratio: 16 / 9;
        object-fit: cover;
    }
}

.info {
    width: 100%;
    padding: 12px;
    background-color: #222;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

    .info_header {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;

        .next_artist_img {
            width: 40px;
        }
    }

    .title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

// VotePoolNextPool
.next_pool_wrapper {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .next_pool_info {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

// VotePoolRemainInfo
.remain_info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .live_image {
        width: 20px;
        height: 20px;
    }

    .remain_time {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
    }
}
