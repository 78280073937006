.share_dialog {
    background-color: white;
    padding: 20px 24px 24px 24px;
    width: 388px;
    border-radius: 8px;

    .title {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        //line-height: 1.43;
        letter-spacing: -0.3px;
        color: #222;

        span {
            flex: 1;
        }

        .close {
            width: 28px;
            height: 28px;
            cursor: pointer;
            background-image: url(../../../assets/icon/btn-navbar-close-copy.svg);
        }
    }

    .buttons {
        margin-top: 20px;
        margin-bottom: 32px;

        button + button {
            margin-left: 8px;
        }
    }

    .url {
        .input {
            padding: 0 12px;
            height: 36px;
            flex: 1;
            display: flex;
            align-items: center;
            border-radius: 2px;
            background-color: #f9f9f9;
            margin-right: 4px;
        }

        input {
            flex: 1;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.3px;
            color: rgba(0, 0, 0, 0.5);
        }

        button {
            width: 80px;
            height: 36px;
            border-radius: 2px;
            background-color: #555;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.3px;
            text-align: center;
            color: #fff;
        }
    }
}

@media (max-width: 1024px) {
    .share_dialog {
        width: 300px;
    }
}
