.live_badge {
    width: fit-content;
    height: fit-content;
    opacity: 0.9;
    border-radius: 14px;
    background-image: linear-gradient(to right, #ff0086 0%, #7900ff 100%);
    box-shadow: 0 0 0 0 rgba(255, 0, 104, 1);
    transform: scale(1);
    animation: pulse 2s infinite;

    &.xs {
        padding: 0 4px;
    }

    &.sm {
        padding: 0 6px;
    }
    &.md {
        padding: 2px 10px;
    }
    &.lg {
        padding: 4px 14px;
    }
}
.circle {
    border-radius: 50%;
    margin-right: 4px;
    background-color: #fff;

    &.xs {
        width: 4px;
        height: 4px;
        margin-right: 2px;
    }

    &.sm {
        width: 6px;
        height: 6px;
    }
    &.md {
        width: 8px;
        height: 8px;
    }
    &.lg {
        width: 10px;
        height: 10px;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 0, 104, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 0, 104, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 0, 104, 0);
    }
}
