.profile_bar_wrapper {
    position: fixed;
    width: 100%;
    max-width: 768px;
    padding: 12px 24px;
    border-radius: 8px 8px 0 0;
    margin-top: 124px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    z-index: 20;

    .title_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .wrapper {
            min-width: 100px;
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;

            .title {
                display: -webkit-box;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                font-size: 18px;
                font-weight: bold;
            }
        }

        .img_button_wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;

            .nav_link {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;

                .img {
                    height: 24px;
                    aspect-ratio: 1 / 1;
                    cursor: pointer;
                }
            }
        }
    }

    .list {
        width: 100%;
        flex-wrap: nowrap;
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.spot {
    position: absolute;
    top: 0;
    right: -4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ff0086;
}
