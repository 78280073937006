.roulette_container {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: contain;
    pointer-events: none;
}

.rotation_container {
    position: absolute;
    top: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wheel_canvas {
    width: 92%;
    height: 92%;
}
