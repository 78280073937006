.accordion_item {
    background-color: transparent;

    .button {
        width: 100%;
        padding: 16px 0px;
        border-bottom: 1px solid #222;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .qna_info {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .category {
                width: 220px;
                color: #999;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .question {
                flex: 1;
                text-align: left;

                .q {
                    color: #ff0086;
                }

                .question_text {
                    margin-left: 8px;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .accordion_item {
        .button {
            align-items: center;

            .qna_info {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                .category {
                    width: 100%;
                    text-align: start;
                    word-break: break-all;
                }

                .question {
                    .question_text {
                        word-break: break-all;
                    }
                }
            }
        }
    }
}

.question_text_active {
    text-decoration: underline;
    text-underline-position: under;
}

.answer_wrapper {
    overflow: hidden;
    transition: all 0.2s ease;
}

.answer {
    background-color: #222;
    padding: 24px 64px 24px 220px;

    span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #aaa;
        position: relative;
        word-break: keep-all;
        background-color: #222;
    }
}

@media (max-width: 1024px) {
    .answer {
        padding: 15px;
        white-space: pre-line;
        word-break: break-all;
    }
}

.open {
    transform: rotate(180deg);
    transition: all 0.2s;
}

.close {
    transform: rotate(0deg);
    transition: all 0.2s;
}
