.wrapper {
    max-width: 100%;

    .img-wrapper {
        max-width: 100%;
    }
}

.rounded {
    &.sm {
        border-radius: 2px;
    }
    &.xs {
        border-radius: 4px;
    }
    &.md {
        border-radius: 6px;
    }
    &.lg {
        border-radius: 8px;
    }
    &.xl {
        border-radius: 10px;
    }
    &.xxl {
        border-radius: 12px;
    }
}
