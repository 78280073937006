.daily_text_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timer {
    font-size: 16px;
    color: yellow;
}
