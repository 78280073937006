.apply_card_list {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.apply_number_wrapper {
    display: flex;
    flex-direction: column;
}

.modal_wrapper {
    z-index: 6;
}

.apply_check_modal_wrapper {
    padding: 10px;
    border-radius: 8px 8px 0 0;
    background-color: white;
}

.cancel_btn {
    border-radius: 0 0 0 8px;
}

.ok_btn {
    border-radius: 0 0 8px 0;
}

.modal_btn {
    width: 100%;
    margin-top: 10px;
    border-radius: 50px;
}
