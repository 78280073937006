.pay_window {
    width: 100%;
    height: auto;
    padding: 32px;
}
.pay_box {
    border-bottom: 1px solid #151515;
    width: 100%;
}

.next_button {
    width: 100%;
    height: 56px;
    padding: 8px 0;
    border-radius: 24px;
    margin-bottom: 24px;
}
