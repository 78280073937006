//FeedMain
.only_twitter {
    width: 100%;
    padding-top: 222px;
}

.link {
    width: calc(1280px / 4);
    text-align: center;
    padding: 12px 0;
    font-weight: bold;
    font-size: 16px;
}

.feed_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

//FeedCard
.feedcard {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #222;
    padding: 24px 30px 24px;
    border-radius: 8px;
}

.artist_info {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.col {
    display: flex;
    flex-direction: column;
}

.artist {
    margin-right: auto;
    margin-left: 8px;
}

.body {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.image {
    border-radius: 8px;
    margin-top: 16px;
}

.iframe_wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}

.date {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.3px;
    text-align: right;
    color: #444;
}
