.dialog {
    width: 400px;
    border-radius: 8px;
    overflow: hidden;

    h2 {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: -0.3px;
        text-align: center;
        color: #000;
        margin: 16px 24px;
    }

    .content {
        min-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            width: 100%;
            padding: 0 24px;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: -0.3;
            color: #000000;
            word-break: break-all;
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        height: 48px;

        button {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.3px;
            text-align: center;
            color: #fff;
        }

        .default {
            background-color: #ff0086;
        }

        .cancel {
            background-color: #999999;
        }
    }
}

@media (max-width: 768px) {
    .dialog {
        width: 290px;
        .content {
            min-height: 120px;
        }
    }
}
