// ChatroomsSection
.title_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
    span {
        color: #aaa;
    }
}
.list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
}
// ChatroomCard
.main_fan_community_list {
    width: calc(100% / 4 - 24px);
    height: 284px;
    margin: 12px;
    border-radius: 8px;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &:hover {
        .back {
            transform: scale(1.2);
        }
    }
}
.front {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}
.top {
    overflow-y: auto;
}
.title {
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    color: #fff;
    word-break: keep-all;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.hashtag {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7;
    color: #fff;
    opacity: 0.5;
    margin-right: 8px;
    transition: 0.3s;
    &:hover {
        color: #ff0086;
    }
}
.bottom {
    display: flex;
    width: 100%;
}
.writer_nickname {
    margin-right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.writer {
    font-size: 12px;
    font-weight: 500;
    line-height: 32px;
    color: #fff;
}
.back {
    width: 100%;
    height: 100%;
    position: relative;
    transition: ease-in-out 0.3s;
    z-index: -1;
}
.backgroud {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
}
.img_area {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}
