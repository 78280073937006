.mobile_layout {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.web_layout {
    width: 100%;
    height: calc(100vh - 500px);
    min-height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    width: 100%;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    font-size: 18px;
}

.back_btn {
    width: calc(100% - 60px);
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.back_btn_img {
    height: 42px;
    padding-right: 18px;
}

.title {
    width: 100%;
}

.history_btn {
    width: 60px;
    height: 100%;
    color: #ffffff;
    font-size: 14px;
    text-decoration: underline;
}
