.selected_image_lable {
    cursor: pointer;
    width: 104px;
    height: 104px;
    margin: 0 auto 18px;
    border-radius: 52px;
    background-color: #555;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.selected_image {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 52px;
    object-fit: cover;
}
.camera_icon_bg {
    position: absolute;
    bottom: 0;
    right: 0;
}
.input_wrapper {
    position: relative;
}
.input {
    width: 328px;
    height: 36px;
    margin: 8px 0 8px;
    padding: 8px 64px 8px 8px;
    border-radius: 4px;
    background-color: #fff;
}
.title_length {
    position: absolute;
    right: 8px;
}
.button {
    width: 142px;
}
//Modal
.box {
    width: 408px;
    height: 524px;
    padding: 32px 40px 34px;
    border-radius: 8px;
    background-color: #222;
}

.modal_box {
    padding-top: 24px;
    border-radius: 8px 8px 0 0;
    background-color: #fff;
}
.imgae_presets_list {
    display: flex;
    flex-wrap: wrap;
    width: 336px;
}

//CircleImage
.circle_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.circle_image {
    width: 72px;
    height: 72px;
    margin: 20px;
    border-radius: 50%;
}
.circle_layer {
    position: absolute;
    background-color: #ff0086;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    width: 72px;
    height: 72px;
    margin: 20px;
    border-radius: 50%;
    z-index: 1;
}

.file_input {
    width: 72px;
    height: 72px;
    margin: 20px;
    border-radius: 50%;
    border: 1px solid #666;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.cancle {
    height: 48px;
    width: 50%;
    border-radius: 0 0 0 8px;
    background-color: #999;
    color: white;
    font-weight: bold;
}
.ok {
    width: 50%;
    height: 48px;
    border-radius: 0 0 8px 0;
    background-color: #ff0086;
    color: white;
    font-weight: bold;
}

/////
.image_input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}
