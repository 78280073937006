.main_blog_list {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  position: relative;
  top: 0;
  border-radius: 8px;
  background-color: #222;
  transition: 0.2s;

  &:hover {
    top: -8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  }
}

.row_1 {
  display: flex;
  justify-content: space-between;
}

.inner_col_2 {
  display: flex;
  flex-direction: column;
}

.border_tag {
  width: fit-content;
  margin-bottom: 8px;
  padding: 4px 12px;
  border: 1px solid #444;
  border-radius: 14px;
  font-size: 10px;
  font-weight: 600;
  color: #444;
  transition: 0.4s;

  &:hover {
    color: #999;
    border-color: #999;
  }
}

.title,
.txt {
  max-width: 426px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.3s;
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
}

.txt {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #aaa;
}

.user_info {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 8px 0;
}

.user_title {
  margin: 0 8px;
  color: #aaa;
}

.title_class_name{
  margin: 0 0 0 8px;
}

.write_date {
  margin-left: 8px;
  color: #666;
}

.thumbnail_box {
  width: 88px;
  height: 88px;
  object-fit: cover;
  position: relative;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.row_2 {
  display: flex;
  align-items: center;
}

.count_txt {
  margin-right: 8px;
}
