.apply_card_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.only_btn {
    width: 100%;
    min-height: 62px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.apply_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .img {
        width: 100%;
        aspect-ratio: 16 / 9;
        border-radius: 4px;
        object-fit: cover;
        filter: brightness(70%);
    }

    .button {
        width: 100%;
        height: 48px;
        border-radius: 4px;
        background-color: #ff0086;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .disable_button {
        background-color: #666666;
    }
}

.apply_number_wrapper {
    display: flex;
    flex-direction: column;
}
