.section {
    padding: 222px 15px 0;
}

.section_title {
    margin-bottom: 32px;
    font-size: 32px;
    font-weight: 700;
    color: #fff;
}

.title_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.select_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    gap: 16px;
}

.select {
    min-width: 200px;
    flex-grow: 1;
    z-index: 11;
}

.submit_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.loading{
    width: 20px;
}
