/* Footer */
.footer {
    display: flex;
    flex-direction: column;
    color: #555;
    font-size: 14px;
    width: 100%;
    max-width: 1280px;
    padding: 0 48px;
    margin: 60px auto 48px;
}

.row_1 {
    margin-bottom: 30px;
}

.row_2 {
    color: #555;
}

.row_3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left {
    display: flex;
    flex-direction: column;
}

.link_name {
    margin-right: 10px;
    color: #555;
}

.right {
    display: flex;
}

.link_icon {
    width: 42px;
    height: 42px;
}

@media (max-width: 768px) {
    .footer {
        display: none;
    }
}

.mobile_footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
    width: 100%;
    max-width: 768px;
    padding: 0 0 60px;
    margin: 0;

    .row_1 {
        display: flex;
        flex-direction: column;
        margin: 0;
        text-align: center;

        a {
            span {
                margin: 0;
            }
        }
    }

    .row_2 {
        display: flex;
        flex-direction: column;
        padding: 0;
        text-align: center;
        color: #555;
    }

    .row_3 {
        display: flex;
        justify-content: center;
        padding: 0 28px;

        .mobile_image {
            margin-right: 10px;

            img {
                width: 100px;
            }
        }

        .mobile_image:last-of-type {
            margin-right: 0;
        }
    }

    .row_4 {
        display: flex;
        justify-content: center;
        gap: 8px;
    }

    .row_5 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
