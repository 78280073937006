.payment_header {
    width: 100%;
    height: 60px;
    position: fixed;
    background-color: #000;
    top: 0;
    z-index: 10;
    display: flex;
}

.white .payment_header {
    background-color: #fff;
}

.payment_header button.cancel_btn {
    width: 42px;
}

.payment_header label {
    padding-top: 15px;
    margin-right: 52px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #fff;
    flex-grow: 1;
}

.white .payment_header label {
    color: #000;
}
