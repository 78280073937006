.wrapper {
    width: 100%;
    padding: 20px 24px;
    border-radius: 8px;
    background-color: #222;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
}

.title_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.title {
    font-size: 16px;
    font-weight: bold;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.master {
    color: #ff0086;
    font-size: 12px;
}

.info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    .member {
        font-size: 12px;
        color: #aaa;
    }

    .time {
        font-size: 12px;
        color: #636363;
    }
}

.hashtag_list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.hashtag {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    margin: 4px;
    border-radius: 20px;
    border: solid 1px #fff;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
        border-color: #ff0086;
        color: #ff0086;
    }
    &.sm {
        height: 26px;
        margin: 0 4px 0 0;
        padding: 4px 12px 4px 12px;
        border-radius: 20px;
        border: 1px solid #666;
        font-size: 11px;
        color: #666;
        &:hover {
            border-color: #ff0086;
            color: #ff0086;
        }
    }
    &.xs {
        border: 1px solid #666;
        color: #666;
        font-size: 11px;
        height: 22px;
        &:hover {
            border-color: #ff0086;
            color: #ff0086;
        }
    }
}

.hashtag_small {
    height: 26px;
    margin: 0 4px 0 0;
    padding: 4px 12px 4px 12px;
    border-radius: 20px;
    border: 1px solid #666;
    font-size: 11px;
    color: #666;
}
