.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px;
    border-radius: 8px;
    background-color: #222;
    margin: 32px auto 32px;
}

@media (max-width: 768px) {
    .wrapper {
        padding: 12px;
    }
}

.profile {
    position: relative;
    margin-bottom: 18px;

    &_inner {
        position: relative;
        width: 104px;
        height: 104px;
        margin: 0 auto;
    }

    &_image {
        border-radius: 50%;
        width: 104px;
        height: 104px;
        background-color: #151515;
    }
}

.file_choice_button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 36px;
    height: 36px;
    background: url('https://stam-p.com/@project/m3/img/btn-chat-edit-photo.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}

.form {
    position: relative;
    display: block;

    &_group {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        input {
            border-radius: 2px;
        }
    }

    &_label {
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        padding-bottom: 2px;
        display: block;
    }

    &_password {
        width: 90px;
        line-height: 36px;
        text-align: center;
        border-radius: 20px;
        background-color: #444;
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        margin-top: 6px;
    }
}

.choices {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    width: calc(100% + 10px);

    > li {
        width: 33.3333%;
        padding: 0 5px;
    }

    &_item {
        display: block;
        font-size: 12px;
        line-height: 36px;
        border: 1px solid #444;
        border-radius: 8px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        cursor: pointer;
    }

    &_item_selected {
        border-color: #ff0086;
        background-color: #ff0086;
    }
}

.button_wrapper {
    margin-top: 16px;

    > * {
        margin: 0 6px;
        width: calc(50% - 12px);
        border-radius: 30px;
        font-weight: 400;
        font-size: 14px;
    }
}

.letter_counter {
    position: absolute;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: -0.26px;
    text-align: right;
    right: 12px;
    top: 40px;
}

.datepicker_wrapper {
    height: 36px;
    position: relative;

    input {
        display: block;
        width: 100%;
        padding: 8px 12px;
    }

    :global {
        .react-datepicker-wrapper {
            width: 100%;
            height: 36px;
            border-radius: 2px;
            background-color: #fff;
        }
    }
}

.datepicker_icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 34px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    &_small_size {
        width: 328px;
    }

    &_title {
        font-size: 16px;
        line-height: 24px;
        color: #000;
        text-align: center;
        padding: 16px 24px;
        font-weight: 500;
    }

    &_action {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_info {
        font-size: 12px;
        line-height: 24px;
        color: #666;
        margin: 16px auto 24px;
        text-align: center;
    }

    &_buttons {
        > * {
            width: 50%;
            margin: 0;
            border-radius: 0;
            height: 48px;

            &:first-child {
                border-radius: 0 0 0 8px;
            }

            &:last-child {
                border-radius: 0 0 8px 0;
            }
        }
    }

    &_list {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 16px;

        > li {
            padding: 12px 0;
            position: relative;
            width: 33.3333%;
            display: flex;
            justify-content: center;
        }
    }

    &_check {
        position: absolute;
        width: 30px;
        opacity: 0;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
    }

    &_radial_item {
        width: 72px;
        height: 72px;
        border-radius: 72px;
        cursor: pointer;
        background-color: #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            transition: all 0.2s ease-in-out;
            opacity: 0;
            visibility: hidden;
            background-color: #ff0086;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &_radial_item_selected {
        &::before {
            opacity: 1;
            visibility: visible;
        }

        .modal_check {
            opacity: 1;
        }
    }
}

.small_upload {
    width: 72px;
    height: 72px;
    border-radius: 72px;
    background-color: #fff;

    &_wrapper {
        position: relative;
        border: 1px solid #ddd;
        border-radius: 72px;
    }

    &_icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        svg {
            fill: #fff;
            width: 30px !important;
            height: 30px !important;
            transition: all 0.2s ease-in-out;
        }

        img {
            width: 30px;
        }
    }

    &_photo {
        position: absolute;
        left: 0;
        top: 0;
        width: 72px;
        height: 72px;
        border-radius: 72px;
        pointer-events: none;
    }

    &_after {
        display: none;
    }

    &_uploaded {
        .small_upload_before {
            display: none;
        }

        .small_upload_after {
            display: block;
        }
    }
}

.body {
    padding: 15px 24px;
}

.password_modal {
    width: 400px;
    border-radius: 8px;
    overflow: hidden;
}

.password_modal_title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.3px;
    text-align: center;
    color: #000;
    margin-top: 16px;
}

.input_field {
    height: 36px;
    border-radius: 2px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 12px;

    > div > div {
        top: 0px;
    }

    input {
        width: 100%;
        padding: 0px 12px;
        height: 100%;
    }

    img {
        width: auto;
        height: auto;
        opacity: 0.6;
    }
}

.error {
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    color: #ff0086;
    margin-bottom: 15px;
}

.withdraw_btn {
    width: 328px;
    height: 42px;
    margin: 0 auto 24px;
}
