// ChannelContents
.highlight_wrapper {
    width: 100%;
    margin: 280px 0 0;
}

.channel_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 40px;
    column-gap: 16px;
}

@media (max-width: 768px) {
    .channel_list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
