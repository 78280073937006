.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .heart{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
    }
}
