.wrapper {
    width: 100%;
}

.order_data {
    display: none;
}

.button {
    width: 100%;
    padding: 20px 0;
    border-radius: 28px;

    .button_text {
        font-size: 2.14em;
        font-weight: bold;
        color: #fffefe;
    }
}

@media (max-width: 768px) {
    .button {
        padding: 10px 0;
        border-radius: 14px;

        .button_text {
            font-size: 1.07em;
        }
    }
}
