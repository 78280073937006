.footer {
    display: flex;
    flex-direction: column;
}

.modal_button_box {
    display: flex;
    flex: 1;
    width: 100%;

    a {
        width: 50%;

        .button {
            width: 100%;
            border-radius: 0 0 8px 0;
            height: 48px;
        }
    }
}

.cancel_btn {
    width: 50%;
    border-radius: 0 0 0 8px;
    background-color: #666666;
}

.ok_btn {
    width: 100%;
    border-radius: 0 0 8px 8px;
    background-color: #666666;
}

.display_none {
    padding: 10px 0;
    color: #ffffff;
    display: flex;
}
