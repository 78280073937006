.hashtag {
    padding: 2px 8px;
    border: 1px solid #aaaaaa;
    border-radius: 10px;
}

.title {
    color: #aaaaaa;
    font-size: 8px;
    font-weight: bold;
}
