.normal {
    font-weight: normal;
}
.medium {
    font-weight: 500;
}
.bold {
    font-weight: bold;
}
.thin {
    font-weight: lighter;
}

.xs {
    font-size: 10px;
}
.sm {
    font-size: 12px;
}
.md {
    font-size: 14px;
}
.lg {
    font-size: 16px;
}
.xl {
    font-size: 18px;
}

.main {
    color: #ff0086;
}
.sub {
    color: #35ccff;
}
.white {
    color: #fff;
}
.black {
    color: #000;
}
.gray {
    color: #999;
}
.yellow {
    color: #ffe000;
}
.purple {
    color: #a900ff;
}
.dark_gray {
    color: #777;
}
.brownish_gray {
    color: #666;
}
.gray_06 {
    color: rgba(255, 255, 255, 0.6);
}
.aaa {
    color: #aaa;
}
.comment_blue {
    color: #004ccd;
}
.red {
    color: red;
}

.opacity_xs {
    opacity: 0.4;
}
.opacity_sm {
    opacity: 0.5;
}
.opacity_md {
    opacity: 0.6;
}
.opacity_lg {
    opacity: 0.8;
}
.opacity_xl {
    opacity: 1;
}

.center {
    text-align: center;
}
.start {
    text-align: start;
}
.end {
    text-align: end;
}

.ellipsis {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.interactive {
    cursor: pointer;
}
