.wrap {
    width: 100%;
    max-width: 768px;
    min-height: 100vh;
    padding: 60px 12px 0;
    overflow: scroll;
    background: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.white .wrap {
    background: #fff;
}

.payment_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;
}

.ul {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.on {
    flex-grow: 1;
    flex-basis: 141px;
    height: 46px;
    border-bottom: 2px solid #fff;
    font-size: 15px;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: -0.3px;
    color: #fff;
    text-align: center;
}

.white .on {
    border-bottom: 2px solid #000;
    color: #000;
}

.off {
    flex-grow: 1;
    flex-basis: 141px;
    height: 46px;
    font-size: 15px;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: -0.3px;
    color: #aaa;
    text-align: center;
}

.filter_wrapper {
    width: 100%;
}

.button {
    border-radius: 0;
}
.button:first-child {
    border-radius: 8px 0 0 8px;
}
.button:last-child {
    border-radius: 0 8px 8px 0;
}
