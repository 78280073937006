.alert {
    width: 288px;
    border-radius: 8px;
    background-color: #222222;
    padding: 18px 28px 32px;

    .profile {
        margin-top: 14px;
        position: relative;

        div {
            width: 104px;
            height: 104px;
        }
    }

    .level {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -22px;
        border-radius: 4px;
        background-color: #00ff47;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.3px;
        text-align: center;
        color: #000;
        padding: 4px;
    }

    p {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.3px;
        text-align: center;
        color: #fff;
    }

    button {
        width: 100%;
        height: 42px;
        margin-top: 24px;
        border-radius: 28px;
        background-color: #ff0086;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.3px;
        text-align: center;
        color: #fff;
    }
}
