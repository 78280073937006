.card {
    width: 408px;
    padding: 32px 40px;
    margin: 32px auto;
    border-radius: 8px;
    background-color: #222;
}
.row {
    width: 100%;
}
.center {
    margin: 0 auto 0 0;
    flex: 1;
}
.button {
    width: 100%;
}
.subtitle {
    margin-bottom: 24px;
}
