.contents {
    width: 100%;
    padding: 220px 12px 0;

    h2 {
        margin: 0 0 12px;
    }

    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .episode_list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .post_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    .return {
        border-radius: 30px;
        padding: 12px;
        width: 240px;
        margin: 64px 0;
        align-self: center;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 768px) {
    .list {
        grid-template-columns: repeat(2, 1fr);
    }
}

.genre_list {
    padding-top: 0;
}

.content_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    h2 {
        font-size: 32px;
        font-weight: 700;
    }
}

.content_list_container {
    margin: 0 -8px;
}
