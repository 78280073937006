.info_box {
    width: 100%;
    min-height: 100px;
    flex: 1;
    border-radius: 8px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.header {
    width: 100%;
    padding: 12px;
    background-color: #222222;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        font-size: 1.14em;
        font-weight: bold;
    }

    .close_icon {
        cursor: pointer;
    }
}

.body {
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .descript_wrapper {
        width: 100%;
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;

        .sub_title {
            font-size: 1.14em;
        }

        .artist_list {
            width: 100%;
            overflow-x: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            display: flex;
            justify-content: flex-start;
            align-items: center;

            .artist_item {
                padding: 0 8px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8px;

                .artist_title {
                    width: 100%;
                    text-align: center;
                    color: #aaaaaa;
                    font-size: 1em;
                    text-overflow: ellipsis;
                }
            }
        }

        .descript {
            font-size: 1em;
            white-space: pre-line;
            word-break: break-all;
        }
    }
}
