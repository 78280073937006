.div_background {
    min-height: 100vh;
    padding: 60px 0 24px;
    background: #000;
}

.white .div_background {
    background: #fff;
}

.payment_cont {
    margin: 0 auto 60px;
    padding: 0 48px;
    max-width: 1280px;
    width: 100%;
}

.payment_cont .finish_cont {
    position: relative;
    margin: 160px auto;
}

.payment_cont .finish_cont .finish_box {
    text-align: center;
    padding-top: 136px;
    background: url('/assets/img/icon-complete.png') no-repeat center top; 
    background-size: 104px;
}

.payment_cont .finish_cont .finish_box p {
    font-size: 24px;
    line-height: 40px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 4px;
}

.payment_cont .finish_cont .finish_box span {
    font-size: 16px;
    line-height: 20px;
    display: block;
    color: #aaa;
    letter-spacing: -.3px;
}

.payment_cont .fix_btn {
    position: relative;
    width: 240px;
    margin: 42px auto 0;
}

.payment_cont .fix_btn .submit_btn {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -.3px;
    line-height: 56px;
    border-radius: 28px;
    background-color: #ff0086;
    color: #fff;
    text-align: center;
}
