//TapHeader
.tab_header_two_tabs {
    padding: 0px;

    h3 {
        margin-right: 5px;
    }
}

.tab_button {
    width: 33% !important;
    cursor: pointer;
}

.tab_btn_active {
    border-color: white !important;
}

// AddButton
.title_right_node {
    flex: 1;
    display: flex;
    justify-content: space-between;

    span {
        color: #ff0086;
        font-weight: 600;
        font-size: 16px;
        line-height: 200%;
    }
}

.add_button_box {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
        color: #ff0086;
    }

    svg {
        margin-left: 10px;
        cursor: pointer;
    }
}

// Posts
.post_list_wrapper {
    width: 100%;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.post_list {
  width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    gap: 8px;
}

@media (max-width: 768px) {
    .post_list {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
    }
}

.collection_card_layer {
    width: 100%;
}

.image {
    width: 100%;
    height: 100%;
    background-color: #444444;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 8px;
}

.blur {
    width: 100%;
    height: calc(100% - 30px);
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0.7));
    z-index: 1;
}

// modal
.modal_container {
    border-radius: 8px;
    // border: 1px solid #ff0086;
    width: 100%;
    max-width: 328px;
    overflow: hidden;

    .head {
        padding: 16px 24px;
    }

    .body {
        padding: 12px 24px 36px;
        text-align: center;

        input {
            width: 100%;
            border: solid 1px rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            font-size: 14px;
            line-height: 16px;
            padding: 8px 12px;
        }
    }

    .footer {
        width: 100%;

        button {
            width: 50%;
            border-radius: 0;
            height: 48px;

            &:first-child {
                background-color: #999;
            }

            &:last-child {
                background-color: #ff0086;
            }
        }
    }
}
