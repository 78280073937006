.header_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    .select_wrapper {
        flex-shrink: 0;
        min-width: 104px;
        display: flex;
        justify-content: center;
        align-items: center;

        .select {
            width: 100%;
        }
    }
}

.tag_filter_wrapper {
    min-width: 200px;
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

    .tag_filter_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tag_filter_title {
            flex: 1;
            font-size: 16px;
        }

        .more_button {
            height: 24px;
            padding: 0 8px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .more_button_text {
                font-size: 12px;
            }
        }
    }

    .tag_list_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
    }
}

@media (max-width: 768px) {
    .tag_filter_wrapper {
        flex: 3;

        .tag_list_wrapper {
            flex-wrap: nowrap;
            overflow: scroll;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }
}

.hashtag {
    width: fit-content;
    padding: 4px 12px;
    border: 1px solid #aaaaaa;
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    color: #aaaaaa;
    white-space: nowrap;
    transition: 0.4s;
    cursor: pointer;

    &:hover {
        border-color: #444;
        color: #444;
    }
}

.active {
    border-color: #ff0086;
    color: #ff0086;
}

.extra_select_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    .select {
        width: 100%;
        min-width: 20%;
    }
}

@media (max-width: 768px) {
    .extra_select_wrapper {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
}
