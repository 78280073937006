.collection_main {
    width: 100%;
    padding: 96px 0 0;
}

@media (max-width: 768px) {
    .collection_main {
        padding: 64px 0 0;
    }
}

.title {
    padding: 0 12px;
}

.tab_list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-size: 16px;
    font-weight: 600;
    color: #666 !important;
    transition: 0.3s;
    padding: 18px 12px;
    &:hover {
        color: #fff !important;
    }
}

.notice_list_tab_header {
    padding: 0;
    margin: 0;
}

.notice_list_tab_header a {
    color: #fff !important;
}

.notice_list_tab_header .col_3_list > a {
    display: inline-block;
    padding: 14px 48px;
    width: 25%;
    position: relative;
    border: 1px solid #333;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.7;
    color: #fff;
    transition: 0.3s;
}

.notice_list_tab_header .col_3_list {
    [class~='active'] {
        border: 1px solid #fff;
        color: #fff;
    }
}
