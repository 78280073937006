.guide_box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    .button {
        width: 200px;
        height: 40px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobile_button {
        width: 100%;
    }

    .go_list {
        border: 1px solid #fff;
        background-color: #141414;
    }

    .vote_guide {
        background-color: #ff0086;
    }
}
