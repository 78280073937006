.wrapper {
    width: 100%;
    padding: 220px 12px 0;
}

.collection_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 37px;

    .title_left {
        h3 {
            margin-right: 8px;
            font-size: 24px;
            width: auto;
        }

        .active {
            display: flex;
            align-items: center;

            .selected {
                margin-left: 12px;
                font-size: 14px;
                line-height: 33px;
                color: #ff0086;
            }

            .on {
                span {
                    background: #ff0086;
                    border: 0;

                    &::after {
                        display: block;
                    }
                }
            }

            .off {
                input:checked ~ span {
                    border: 1px solid darkgray;
                    background: none;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .title_right {
        span {
            font-size: 16px;
            line-height: 18px;
            color: #aaa;
            cursor: pointer;
        }

        .active {
            display: flex;
            align-items: center;
            gap: 24px;

            span:first-child {
                // background-color: red;
                position: relative;

                &::before {
                    content: '';
                    width: 1px;
                    height: 12px;
                    background-color: #333;
                    position: absolute;
                    top: 2px;
                    right: -12px;
                }
            }
        }
    }
}

.collection_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    row-gap: 24px;
    column-gap: 16px;

    .card-item {
        position: relative;
        cursor: pointer;

        .card-img {
            width: 184px;
            height: 260px;
            border-radius: 8px;
        }

        .card-title {
            margin-top: 8px;
        }

        .card-check {
            position: absolute;
            z-index: 2;
            top: 30px;
            left: 8px;

            span {
                border: 0;
                background: rgba(0, 0, 0, 0.5);
            }

            &.active {
                span {
                    background: #ff0086;

                    &::after {
                        display: block;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .collection_list {
        grid-template-columns: repeat(3, minmax(100px, auto));
    }
}

.empty_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;

    p {
        margin-top: 16px;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: -0.3px;
        text-align: center;
        color: #333;
    }
}
