//ArtistCard
.artist_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 240px;
    padding: 24px 13px 20px;
    border-radius: 8px;
    background-color: #222;
}

.title {
    font-size: 16px;
    font-weight: bold;
    margin: 16px 0;
}

.buttons {
    display: flex;
    width: 100%;
    justify-content: space-around;

    div {
        flex: 1;
    }
}

.col {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.button_text {
    max-width: 54px;
    font-size: 10px;
    color: #999;
}
