.wrapper {
    padding: 96px 12px 0;
    max-width: 1280px;
    margin: 0 0 36px 0;

    .header {
        display: inline-flex;
        align-items: center;
        margin-bottom: 24px;

        h2 {
            font-size: 24px;
            display: inline-block;
            margin-right: 24px;
        }

        .point_box {
            padding-left: 24px;
            background: url(../../../assets/icon/icon-point-20-circle.svg) no-repeat;
            font-size: 24px;
            color: #ffcc00;
            line-height: 20px;
            height: 20px;
            width: 20px;
            background-size: 20px;
            display: inline-block;
            letter-spacing: -0.3px;
            font-weight: 600;
        }
    }

    .sub_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .wrapper {
        padding: 64px 12px 0;

        .sub_header {
            flex-direction: column;
        }
    }
}

.select_wrapper {
    position: relative;
    width: 144px;
    height: 36px;
    padding: 6px 12px;
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: -0.3;
    color: #ffffff;
    border-radius: 4px;
    text-align: left;
    display: flex;
    align-items: center;

    &:after {
        content: '';
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background: url(../../../assets/icon/icon-input-dropdown-down-on-black.svg) no-repeat;
    }

    .picker {
        color: white;
    }
}

.separator {
    display: inline-block;
    height: 12px;
    border-right: 1px solid #333;
    padding-left: 12px;
}

.row {
    p {
        font-size: 14px;
        display: block;
        color: #666;
    }
}

//PointsPane
.points_pane {
    border-top: 1px solid #2a2a2a;
    padding: 24px 0;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-of-type {
        border-bottom: 1px solid #2a2a2a;
    }
}
