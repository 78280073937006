.button_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

@media (max-width: 768px) {
    .button_wrapper {
        gap: 12px;
    }
}
