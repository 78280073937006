.fanclub_card {
    width: 100%;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    .fanclub_image {
        width: 100%;
        border-radius: 50%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
    }

    .fanclub_title {
        font-size: 1em;
    }
}

@media (max-width: 768px) {
    .fanclub_card {
        padding: 0 4px;
    }
}
