.vote_list_wrapper {
    padding: 220px 12px 60px;
}

.card_list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 12px;
}

@media (max-width: 768px) {
    .card_list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
    }
}

.link {
    width: 100%;
}
