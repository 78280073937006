.modal_setting {
    z-index: 5;
}

.modal_wrapper {
    max-width: 90%;
    margin: auto;
    border-radius: 10px;
}

.contents {
    max-width: 400px;
    display: flex;
    flex-direction: column;
}

.content_text {
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    color: #000000;
    text-align: center;
    white-space: pre-line;
}

.content_img {
    width: 100%;
    object-fit: cover;
}

.content_img:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
