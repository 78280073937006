.video_wrapper {
    position: relative;
    height: 100%;
    [class~='shaka-scrim-container'] {
        background: none;
    }
}

.live_badge {
    position: absolute;
    top: 14px;
    left: 14px;
    z-index: 1;
}

.progress_bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    background-color: #ff0086;
    border-radius: 0 0 0 8px;
    z-index: 1;
    max-width: 100%;
}
