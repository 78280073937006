* {
    box-sizing: border-box;
}

.editor-attachment-toolbar {
    width: 100%;
    background-color: #333;
    padding: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.attachment-toolbar-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.editor-format-toolbar {
    width: 100%;
    display: flex;
    background-color: #333;
    padding: 4px 10px;
    border-top: 1px solid #3d3d3d;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .editor-attachment-toolbar {
        overflow-x: auto;
        flex-wrap: nowrap;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
    }

    .editor-format-toolbar {
        height: 68px;
        margin-top: 10px;
        overflow-x: auto;
        flex-wrap: nowrap;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
    }
}

.format-toolbar-button {
    height: 100%;
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.attachment-toolbar {
    width: 100%;
    position: relative;
}

.insert-toolbar {
    position: relative;
    margin-bottom: 10px;
}

.attachment-button,
.attachment-input {
    padding: 0 10px;
    border: none;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    text-align: center;
}

.attachment-button p,
.attachment-input p {
    height: 16px;
    font-size: 10px;
}
.attachment-input label {
    cursor: pointer;
}

.upload-status {
    margin: 5px;
    display: flex;
    justify-content: flex-end;
}
#upload-status-icon {
    font-size: 22px;
}

.progress-circle {
    transition: all 0.7s ease-in-out;
}

#progressInput {
    margin: 20px auto;
    width: 30%;
}

.disabled,
.disabled > * {
    cursor: not-allowed !important;
    opacity: 0.3;
}

.heading-options:hover {
    color: #ff0086;
}

/* ShopLink */
.shop-link-modal {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.shop-link-modal .shop-form-wrapper {
    background-color: #222;
    color: white;
    padding: 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    opacity: 1;
}
.shop-link-modal .shop-form-wrapper .link_form {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.shop-link-modal .shop-form-wrapper .link_form h1 {
    text-align: center;
}
.shop-link-modal .shop-form-wrapper .link_form h2 {
    text-align: center;
}
.shop-link-modal .shop-form-wrapper .link_form input {
    border: 1px solid #3a3a3a;
    border-radius: 8px;
    padding: 4px 12px;
    font-size: 18px;
    color: black;
    background-color: white;
}
.shop-link-modal .shop-form-wrapper .link_form input::placeholder {
    color: #3a3a3a;
    font-size: 18px;
}
.shop-link-modal .shop-form-wrapper .link_form .inputs {
    display: flex;
    align-items: center;
    gap: 8px;
}
.shop-link-modal .shop-form-wrapper .link_form .del-button {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 1px solid white;
    color: white;
    background-color: #3a3a3a;
    cursor: pointer;
}
.shop-link-modal .shop-form-wrapper .link_form .link_add_button {
    background-color: transparent;
    width: 100%;
    color: #3a3a3a;
    border: 1px solid #3a3a3a;
    border-radius: 8px;
    padding: 4px 12px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}
.shop-link-modal .shop-form-wrapper .link_form .submit-button {
    border: 1px solid #3a3a3a;
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
}
