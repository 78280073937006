// VoteButton
.vote_button {
    width: 100%;
}

.ott_effect_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.plan_text {
    color: #ff0086;
    font-size: 18px;
    font-weight: bold;
}

.effect_text {
    color: black;
    white-space: pre-line;
}
