.album_section {
    width: 100%;
    padding: 246px 12px 24px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.album_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.album_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .img {
        width: 50%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
    }
}

@media (max-width: 768px) {
    .album_info {
        .img {
            width: 100%;
        }
    }
}

.album_detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;

    .title {
        font-size: 24px;
        font-weight: bold;
    }

    .album_desc {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .authors {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
}

.status_wrapper {
    width: 100%;
    height: 48px;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tracks {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .track_wrapper {
        width: 100%;

        .track {
            width: 100%;
            height: 60px;
            padding: 0 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .duration {
                font-size: 14px;
                color: #aaa;
            }
        }
    }
}
