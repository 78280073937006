.modal_container {
    border-radius: 8px;
    // border: 1px solid #ff0086;
    width: 100%;
    max-width: 339px;
    overflow: hidden;

    .head {
        padding: 16px 24px;
        //height: 56px;
    }

    .body {
        display: flex;
        text-align: center;
        height: 300px;

        .wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding-left: 24px;

            .popover_card {
                display: inline-block;
                width: 86px;
                height: 86px;
                margin-right: 16px;
            }
        }
    }

    .footer {
        width: 100%;

        button {
            width: 100%;
            border-radius: 0;
            height: 48px;
            background-color: #999;
        }
    }
}

//AddCollectionModal
.input {
    width: 300px;
    height: 32px;
    padding: 0 8px;
    border: 1px solid #999;
    margin: 24px 24px;
    border-radius: 2px;
}

.cancle_btn {
    background-color: #999;
    display: inline-block;
    width: 50%;
    border-radius: 0;
    color: white;
    font-weight: 600;
    height: 48px;
}
.okbutton {
    background-color: #ff0086;
    display: inline-block;
    color: white;
    font-weight: 600;
    width: 50%;
    border-radius: 0;
    height: 48px;
}
