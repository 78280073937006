.chat_subtitle_container {
    width: 100%;
    padding: 0 2.5%;
    position: absolute;
    left: 0;
    bottom: 6%;
    display: flex;
    justify-content: center;
}

.move_up {
    transform: translateY(-36px);
}

.full_screen_container {
    width: calc(100% - 360px);
}

.content {
    display: inline;
    font-size: 20px;
    line-height: 1.4;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    white-space: pre-line;
    word-break: break-all;
    text-align: center;
}

.full_screen_content {
    font-size: 4.4vmin;
}
