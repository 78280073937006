.layer {
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}
.modal {
    z-index: 2;
    margin: auto;
}
