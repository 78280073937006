.artist_item {
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .artist_title {
        width: 100%;
        text-align: center;
        color: #aaaaaa;
        font-size: 1em;
        text-overflow: ellipsis;
    }
}
