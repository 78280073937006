//SettionLandscape
.player_setting {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    max-width: 360px;
    height: 100%;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 3;
}
.tit {
    margin-bottom: 36px;
}
.menu {
    margin-bottom: 12px;
}
.close_btn {
    width: 42px;
    height: 42px;
    margin-right: 8px;
    background-size: contain;
    cursor: pointer;
}
