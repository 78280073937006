.dark {
    min-height: 100vh;
    overflow: scroll;
    background-color: black;
    color: white;
}

.white {
    min-height: 100vh;
    overflow: scroll;
    background-color: white;
    color: black;
}