.vote_label {
    width: 100%;
    height: 56px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
        width: 100%;
        text-align: center;
        color: white;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        word-break: break-all;
    }
}
