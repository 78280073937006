.badge {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    cursor: pointer;
}
.main {
    background-color: #ff0086;
    color: #fff;
}
.black {
    background-color: #000;
    color: #fff;
}
.gray {
    background-color: #2b2b2b;
    color: #fff;
}
.whitegray {
    background-color: #555;
}
.border {
    min-width: 50px;
    color: #fff;
    border: solid 1px #fff;
}

.xs {
    padding: 2px 8px;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.6;
}
.sm {
    padding: 8px;
    font-size: 12px;
    font-weight: 400;
}
.md {
    padding: 6px 24px;
    font-size: 14px;
}
.lg {
    padding: 12px 24px;
    font-size: 18px;
}
.xl {
    padding: 14px;
    font-size: 22px;
    font-weight: 700;
}

.color_main {
    border-color: #ff0086;
    color: #ff0086;
}
