.roulette_section {
    max-width: 450px;
    height: 100%;
    max-height: 950px;
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000000;
    overflow: hidden;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.roulette_wrapper {
    width: 100%;
    padding: 30px 0 0;
    position: relative;
}

.roulette_name {
    width: 200px;
    position: absolute;
    top: 20px;
    left: 20px;
}

.wheel_center {
    width: 240px;
    position: absolute;
    top: calc(50% - 90px);
    right: -120px;
    animation: rotate_center_idle 10s linear 0s infinite forwards;
}

@keyframes rotate_center_idle {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-720deg);
    }
}

.spin {
    animation: rotate_center_spin-1 2.6s cubic-bezier(0.64, 0, 0.78, 0) 0s 1 normal forwards running, rotate_center_spin-2 0.75s linear 2.6s 1 normal forwards,
        rotate_center_spin-3 4s cubic-bezier(0, 0, 0.35, 1.02) 3.35s 1 normal forwards;
}

@keyframes rotate_center_spin-1 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(720deg);
    }
}
@keyframes rotate_center_spin-2 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(720deg);
    }
}
@keyframes rotate_center_spin-3 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(1440deg);
    }
}

.wheel_arrow {
    width: 90px;
    position: absolute;
    top: 140px;
    right: 260px;
}

.btn_box_wrapper {
    width: 100%;
    height: 154px;
    border-radius: 16px;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #141414;
}

.btn_box_line {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: fill;
}

.point_wrapper {
    width: 86%;
    padding: 0 6px 4px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.3px;
}

.point_box_wrapper {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.point_box {
    padding-left: 20px;
    margin-left: 10px;
    display: inline-block;
    background: url(../../assets/icon/icon-point-20-circle.svg) no-repeat;
    background-size: 16px;
    line-height: 16px;
    color: orange;
}

.point_acquisition_btn {
    color: #ffffff;
    font-size: 16px;
    text-decoration: underline;
}

.spin_btn {
    width: 86%;
    height: 96px;
    position: relative;
    cursor: pointer;
}

.point_consumption {
    padding-left: 18px;
    position: absolute;
    top: 20px;
    left: 50%;
    display: inline-block;
    background: url(../../assets/icon/icon-point-20-circle.svg) no-repeat;
    background-size: 14px;
    line-height: 14px;
    color: orange;
    transform: translate(-50%);
}

.spin_btn_img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
