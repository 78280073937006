.header {
    display: flex;
    justify-content: center;
    border-radius: 8px;

    div {
        max-height: 360px;
    }

    img {
        position: relative;
        width: 100%;
    }

    &.gradient {
        width: auto;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 84%, rgba(0, 0, 0, 0.5));
        }
    }
}
