.container {
    padding: 0 48px;
}

@media (max-width: 1024px) {
    .container {
        padding: 0 15px;
    }
}

.header {
    border-bottom: 1px solid #222;
}

.title {
    font-size: 24px;
    white-space: pre-line;
    word-break: break-all;
}

@media (max-width: 1024px) {
    .title {
        font-size: 20px;
    }
}

.date {
    margin-block: 20px;
    color: #666;
}
.main {
    margin: 20px auto;
}

.main > p {
    font-size: 14px;
    color: #eee;
    &:nth-child(2) {
        margin-bottom: 24px;
    }
}
.main img {
    width: auto;
    padding: 0px;
}

.footer {
}
.footer span {
    display: inline-block;
    margin: 0px;
    margin-bottom: 24px;
    color: #fff;
}
.btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #222;
    margin-top: 24px;
    padding: 72px 0px 90px;
}

@media (max-width: 1024px) {
    .btn_container {
        padding: 0;
    }
}

.button {
    width: 240px;
    height: 54px;
    border-radius: 40px;
    background-color: transparent;
    border: 1px solid #fff;
    &:hover {
        background-color: #fff;
        color: #000;
        transition: all 0.15s ease-in;
    }
}
