// MainHeader
.header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 96px;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
    transition: all 0.3s ease 0s;
}

.on {
    background-color: #141414;
}

.wrraper {
    width: 1280px;
    max-width: 1280px;
    padding: 28px 48px;
    display: flex;
    justify-content: space-between;
}

.left {
    display: flex;
    align-items: center;
}

.nav_list {
    display: flex;
    margin-left: 42px;

    [class~='active'] {
        color: #ff0086;

        &::after {
            width: 100%;
        }
    }

    li {
        margin-right: 28px;
    }
}

.nav_item {
    display: block;
    line-height: 42px;
    height: 42px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: #fff;
    text-decoration: none;
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #ff0086;
        transition: 0.3s;
    }

    &:hover {
        &::after {
            width: 100%;
        }
    }
}

.right {
    display: flex;
    align-items: center;
}

// SubHeader
.sub_title_wrap {
    margin-top: 95px;
    padding: 32px 0 0;
    border-top: 1px solid #222;
    margin-bottom: 36px;
}

.st_box {
    margin: 0 auto 30px;

    h2 {
        font-size: 32px;
        font-weight: 700;
        color: #fff;
    }
}

.sec_tab_box {
    border-bottom: 1px solid #222;
    background-color: #141414;
}

.sub_title_wrap .wrapper {
    margin: 0 auto 0;
    max-width: 1280px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: center;

    [class~='active'] {
        border-bottom: 2px solid #fff;
        color: #fff;
        font-weight: 600;

        div {
            color: #fff;
        }
    }

    a {
        width: 100%;
        text-align: center;
        padding: 18px 12px;
        color: #666;
    }
}

//TapHeader
.header_wrapper {
    max-width: 1280px;
    margin: 0px auto;
    padding: 0px 48px;
}

.title_box {
    margin-bottom: 24px;
    display: flex;

    h3 {
        display: inline-block;
        margin-right: 24px;
        font-size: 24px;
        width: auto;
    }
}

.tab_box {
    margin-bottom: 24px;
}

.col_3_list {
    display: flex;
}

.col_3_list > a {
    display: inline-block;
    padding: 14px 18px;
    position: relative;
    border: 1px solid #333;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.7;
    color: #666;
    transition: 0.3s;
    flex: 1;
}

.col_3_list > a:hover {
    color: #fff;
}

.col_3_list > a:first-of-type {
    border-radius: 8px 0 0 8px;
}

.col_3_list > a:last-of-type {
    border-radius: 0 8px 8px 0;
}

.col_3_list > div {
    display: inline-block;
    padding: 14px 18px;
    flex: 1;
    position: relative;
    border: 1px solid #333;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.7;
    color: #fff;
    cursor: pointer;
}

.col_3_list > div:first-of-type {
    border-radius: 8px 0 0 8px;
}

.col_3_list > div:last-of-type {
    border-radius: 0 8px 8px 0;
}

.col_3_list > *:only-child {
    border-radius: 8px !important;
}

.col_3_list {
    [class~='active'] {
        border: 1px solid #fff;
        color: #fff;
    }
}

// TabHeader
.col_4 > a {
    width: 25%;
}

.col_list {
    text-align: center;
}

.col_list > a {
    display: inline-block;
    padding: 14px 18px;
    position: relative;
    border: 1px solid #333;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.7;
    color: #666;
    transition: 0.3s;
}

.col_list > a:hover {
    color: #fff;
}

.col_list > a:first-of-type {
    border-radius: 8px 0 0 8px;
}

.col_list > a:last-of-type {
    border-radius: 0 8px 8px 0;
}

.col_list {
    > div {
        display: inline-block;
        padding: 14px 18px;
        position: relative;
        border: 1px solid #333;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.7;
        color: #fff;
    }
}

.col_list > div:first-of-type {
    border-radius: 8px 0 0 8px;
}

.col_list > div:last-of-type {
    border-radius: 0 8px 8px 0;
}

.col_list {
    [class~='active'] {
        border: 1px solid #fff;
        color: #fff;
    }
}

.notice_list_tab_header {
    padding: 0 48px;
}

.notice_list_tab_header a {
    color: #fff !important;
}

.notice_list_tab_header .col_3_list > a {
    display: inline-block;
    padding: 14px 48px;
    width: 25%;
    position: relative;
    border: 1px solid #333;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.7;
    color: #fff;
    transition: 0.3s;
}

.notice_list_tab_header .col_3_list {
    [class~='active'] {
        border: 1px solid #fff;
        color: #fff;
    }
}

.tab_header_wrapper {
    max-width: 768px;
    margin: 0px auto;
}

.tab_header_title_box {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    h3 {
        display: inline-block;
        font-size: 20px;
        width: auto;
    }
}

// StickySubHeader
.sticky_sec_tab_box {
    border-bottom: 1px solid #222;
    background-color: #141414;
    width: 100%;
    color: #666;

    &.sticky {
        position: fixed;
        top: 64px;
        width: 100%;
        max-width: 768px;
        z-index: 2;
        border-top: 1px solid #222;
        border-bottom-color: transparent;

        & [class~='active'] {
            color: #ff0086 !important;
            border-bottom-color: #ff0086;
        }
    }
}

.empty_area {
    height: 62px;
}

.sticky_wrapper {
    margin: 0 auto 0;
    padding: 0 48px;
    max-width: 1280px;
    width: 100%;
    display: flex;
    justify-content: center;
    // justify-content: space-between;
    [class~='active'] {
        border-bottom: 2px solid #fff;
        color: #fff !important;

        div {
            color: #fff;
        }
    }

    a {
        // width: 100%;
        text-align: center;
        // padding: 18px 12px;
        color: #666 !important;

        &:hover {
            color: white !important;
        }
    }
}

.sticky_st_box {
    margin: 0 auto 30px;

    h2 {
        font-size: 24px;
        font-weight: 700;
        color: #fff;
    }

    & > div {
        position: relative;

        .setting {
            position: absolute;
            top: 0;
            right: 0;

            display: flex;
            align-items: center;
            gap: 12px;

            .icon_wrapper {
                display: flex;
                align-items: center;
                gap: 2px;

                img {
                    width: 24px;
                }

                span {
                    font-size: 14px;
                    line-height: 17px;
                    color: #999;
                }
            }
        }
    }

    @media (min-width: 640px) {
        h2 {
            font-size: 32px;
        }
    }
}

@media (max-width: 768px) {
    .header {
        display: none;
    }
}

.mobile_header {
    width: 100%;
    height: 70px;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
    transition: all 0.3s ease 0s;

    .mobile_header_wrapper {
        width: 100%;
        padding: 15px 15px;
    }
}

.mobile_sub_wrapper {
    margin: 0 auto 0;
    padding: 0 15px;
    max-width: 1280px;
    width: 100%;
    display: flex;
    justify-content: center;

    [class~='active'] {
        border-bottom: 2px solid #fff;
        color: #fff;
        font-weight: 600;

        div {
            color: #fff;
        }
    }

    a {
        width: 100%;
        text-align: center;
        padding: 18px 12px;
        color: #666;
    }
}
