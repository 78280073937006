.frame {
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
}

.card {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
        width: 100%;
        height: 100%;
        aspect-ratio: 16 / 9;
        object-fit: cover;
    }
}

.info {
    width: 100%;
    height: 88px;
    padding: 12px;
    background-color: #222;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

    .info_header {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .remain_time {
            font-size: 12px;
            font-weight: bold;
            color: #ff0086;
        }
    }

    .title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
