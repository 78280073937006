.schedule_item {
    position: relative;
    height: 84px;
    margin: 0;
    border: none;
    border-radius: 0;
    padding: 14px 20px;

    &:hover {
        border-color: #666;
    }
}

.schedule_img {
    width: 100px;
    height: 56px;
    border-radius: 4px;
    object-fit: cover;
}

.default_image {
    width: 100px;
    height: 56px;
    object-fit: contain;
    padding: 16px;
    background-color: #aaa;
}

.schedule_live_badge {
    position: absolute;
    top: 14px;
    left: 20px;
}

.schedule_info {
    flex: 1;
    margin-left: 8px;
}

.schedule_title {
    width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// EvnetDetailInfobox
.info_box {
    width: 384px;
    height: 440px;
    padding: 14px 14px;
    background-color: #151515;
}
.artist_list {
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.artist_item {
    margin: 4px 8px;
}
.artist_title {
    max-width: 56px;
    word-break: break-all;
    text-align: center;
}
