//Program
.section {
    margin-top: 95px;
    padding-top: 35px;
    display: flex;
    flex-direction: column;
}

.aggregation_period {
    display: flex;
    align-items: center;
    color: #ff0086;
    font-size: 14px;
    margin-top: 24px;
    margin-bottom: 42px;

    i {
        display: inline-block;
        margin-right: 6px;
        width: 12px;
        height: 12px;
        background-image: url('/assets/img/icon_clock_12_pink.png');
        vertical-align: middle;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.program_list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 72px;
    margin: 0 -6px;
}
.program_card {
    width: calc(100% / 6 - 12px);
    margin: 0 6px;
}

.goback {
    width: 240px;
    height: 54px;
    padding: 12px;
    margin: 0 auto;
    border-radius: 30px;
    border: solid 1px #f9f9f9;
    font-size: 16px;
    font-weight: bold;
    color: #f9f9f9;
    text-align: center;
}

//Artist

.artist_section {
    margin-top: 128px;
    display: flex;
    flex-direction: column;
}

.ranking_artist_list {
    flex-wrap: wrap;
    margin: 0 -28px;
}
.artist_card {
    margin: 0 28px 48px;
    width: calc(100% / 6 - 56px);
    height: 250px;
}

.image_wrapper {
    position: relative;
    margin-bottom: 12px;
}

.rank_no {
    position: absolute;
    line-height: 0.6;
    bottom: 0;
    left: 0;
    font-size: 48px;
}

.artist_title {
    width: 144px;
}

// Post
.post_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 72px;
}
