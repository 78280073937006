.landscape {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}
.img_wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .blur_image {
        width: 100%;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        filter: blur(5px);
        -webkit-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        opacity: 0.3;
    }

    .bg {
        position: absolute;
        height: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        background-position: center;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
