.dialog {
    .content {
        .inputarea {
            width: 100%;
            height: 500px;
            overflow-x: hidden;
            overflow-y: auto;

            .check_label {
                margin: 0 24px;
                padding: 12px 0;
                color: black;
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid #f3f3f3;
            }

            textarea {
                width: 100%;
                border: 1px solid rgba(0, 0, 0, 0.2);
                margin-bottom: 24px;
            }

        }
    }
}
