.post_section_wrapper {
    width: 100%;
    min-height: calc(100vh - 224px);
    padding-top: 222px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width: 768px) {
    .post_section_wrapper {
        min-height: 100vh;
    }
}

.editor {
    width: 100%;
    height: 100%;
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    ul > li {
        list-style: disc;
        margin-left: 14px;
    }
    ol > li {
        list-style: decimal;
        margin-left: 14px;
    }
}

.button_group {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .title_input {
        font-size: 24px;
        font-weight: bold;
        color: white;

        &::placeholder {
            color: #555;
        }

        flex: 1;
    }

    .save_buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        align-items: center;

        .save_button {
            flex: 1;
            border-radius: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            color: #fff;
            border: none;
        }
    }
}

@media (max-width: 768px) {
    .button_group {
        flex-direction: column;

        .title_input {
            width: 100%;
        }
    }
}
@media (min-width: 768px) {
    .save_button {
        max-width: 100px;
        flex: none;
    }
}

.loading {
    width: 24px;
    height: 24px;
    path {
        fill: white;
    }
}

// HashTagBox
.hashtags_wrapper {
    width: 100%;
    padding: 4px;
    border-radius: 4px;
    margin: 8px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;

    .hashtag {
        height: 100%;
        padding: 4px 4px 4px 8px;
        border-radius: 16px;
        margin-right: 4px;
        background-color: skyblue;
        display: flex;
        justify-content: center;
        align-items: center;

        .hashtag_close_icon {
            width: 20px;
            height: 20px;
        }
    }

    .hashtag_input {
        flex: 1;
        height: 28px;
        padding: 4px 4px 4px 0;
        border-radius: 4px;
    }

    .hashtag_input::placeholder {
        color: #666;
    }
}

.tag_box {
    width: 100%;
    position: relative;
}

.hashtag_search_box {
    position: absolute;
    left: 0;
    top: 46px;
    width: 100%;
    padding: 10px 0;
    border-radius: 8px;
    background-color: #444;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 11;

    :hover {
        background-color: #333;
    }

    .auto_complete_tag {
        padding: 8px 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }
}

.disable {
    visibility: hidden;
}

// PostWriteVote
.vote_write {
    width: 408px;
    border-radius: 4px;
    background-color: #222;
}

.title_wrapper {
    margin: 14px 24px 0 24px;
}

.vote_title_input {
    flex: 1;
    height: 24px;
    font-size: 16px;
    color: white;

    &::placeholder {
        color: #555;
    }
}

.buttons {
    margin: 0 24px 16px 24px;
}

.button {
    width: 115px;
    height: 36px;
    border-radius: 4px;
    background-color: #333;
    color: #888;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        stroke: #888;
    }
}

.active {
    background-color: #ff0086;
    color: white;

    & svg {
        stroke: white;
    }
}

.border {
    width: 360px;
    height: 44px;
    margin: 0 24px;
    padding: 0 24px;
    border-radius: 2px;
    border: solid 1px #3a3a3a;
    display: flex;
    justify-content: space-between;
}

.add_button {
    display: flex;
    align-items: center;
    border-radius: 2px;
    margin: 0 24px;
    height: 44px;
    padding: 0 24px;
    border: solid 1px #3a3a3a;
    font-size: 12px;
}

.end_day_title {
    justify-content: flex-start;
    margin: 0 24px 12px 24px;

    & svg {
        margin-right: 4px;
    }
}

.dupulicate_setting {
    margin: 0 24px 16px;
}

.dupulicate {
    flex: 1;
}

.save_btn {
    width: 170px;
    height: 36px;
}

.cancle_btn {
    width: 170px;
    height: 36px;
    border-radius: 4px;
    background-color: #333;
    color: #888;
}

//PostWriteVoteItem
.vote_item {
    width: 360px;
    height: 44px;
    margin: 0 24px 8px;
    border: solid 1px #3a3a3a;
    display: flex;
    justify-content: space-between;
    padding: 0 8px 0 24px;

    &::placeholder {
        color: #666;
    }
}

.item_input {
    color: white;

    &::placeholder {
        color: #666;
    }
}

//ArtistSearchModal
.artist_search_modal {
    width: 402px;
    height: 700px;
    padding: 16px 24px;
    border-radius: 8px;
    box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    position: relative;
}

.close_icon {
    position: absolute;
    right: 6px;
    top: 6px;

    & g {
        stroke: black;
    }
}

.artist_search_input {
    flex: 1;
    height: 36px;
    padding: 0 8px;
    border-radius: 18px;
    border: solid 1px #ccc;
    margin: 40px 0;
    transition: 0.3s;

    &:focus {
        border-color: #888 !important;
    }
}

.search_icon {
    margin: 0 8px;
}

.artist_list {
    flex-wrap: wrap;
    margin: 12px -6px;
    width: 100%;
    flex: 1;
    justify-content: flex-start;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

//ArtistItem
.artist_item {
    width: calc(100% / 3 - 12px);
    margin: 6px;
    padding: 16px 23px;
    border-radius: 8px;
    border: solid 1px #eee;
    transition: 0.3s;
    align-items: center;

    &:hover {
        border-color: #ff0086;
    }
}

//ContentSearchModal
.content_list {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -6px;
    flex: 1;
}

// ProgramItem
.program_item {
    width: calc(100% / 3 - 12px);
    margin: 6px;
}

.program_image {
    width: 100%;
    height: 158px;
    object-fit: cover;
    border-radius: 8px;
}

.program_title {
    color: black;
    font-size: 12px;
    width: 100%;
}

.select_wrapper {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.select {
    flex-grow: 1;
}
