.settings_main{
    max-width: 1280px;
    margin: 0 auto;
}

.tab_list {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 12px;
    width: 240px;
    height: auto;
    font-weight: 600;
    color: #666;
    transition: 0.3s;
    &:hover {
        color: #fff;
    }
}
