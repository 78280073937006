.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.live_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.live_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
}

.title {
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.descript {
    overflow: hidden;
    color: #666666;
    font-size: 14px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
}
