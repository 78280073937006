.dark {
    min-height: 100vh;
    overflow: scroll;
    background-color: black;
    color: white;
}

.next_button {
    width: 100%;
    height: 56px;
    padding: 8px 0;
    border-radius: 24px;
    margin-bottom: 24px;
}
