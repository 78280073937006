.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 80%;
    transform: translate(-50%, -50%);
    background-color: white;
    opacity: 1;
    border-radius: 8px;
    outline: none;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

:global {
    .ReactModal__Overlay {
        z-index: 9999;
    }
}
