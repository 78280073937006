.login_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
}

.mobile_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
}

.login_form {
    margin-top: 32px;
    width: 100%;
    max-width: 408px;
    height: 449px;
    padding: 36px 40px;
    border-radius: 8px;
    background-color: #222;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        padding: 12px;
    }
}

.p {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #aaa;
}

.extra_login_btn_box {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.underline {
    text-decoration: underline;
}

.social_logins {
    width: 100%;
    height: 80px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
}

.login_btn {
    width: 100%;
    margin: 16px 0 24px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    background-color: #ff0086;
    color: #fff;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    cursor: pointer;
}

.social_btn {
    cursor: pointer;
}

.signup_btn {
    width: 100%;
    margin: 16px 0 24px;
    color: #aaa;
}

.em {
    font-weight: bold;
    color: #fff;
    margin-left: 8px;
}
