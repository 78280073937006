.artist_card {
    width: 100%;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    position: relative;

    .artist_image {
        width: 100%;
        border-radius: 50%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
    }

    .title_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;

        .artist_title {
            font-size: 1em;
        }

        .sub_title {
            color: #aaaaaa;
            font-size: 0.85em;
        }
    }

    .new {
        position: absolute;
        top: 0;
        right: 0;
        color: #ff0086;
        font-size: 1em;
        font-weight: bold;
    }
}

@media (max-width: 768px) {
    .artist_card {
        padding: 0 4px;
    }

    .new {
        font-size: 0.85em;
    }
}
