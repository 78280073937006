// PlainCardModal
.buttons {
    padding: 8px 16px 16px;
    background-color: #222;
    border-radius: 0 0 14px 14px;

    & > div {
        width: 50%;
    }

    .separator {
        height: 70px;
        width: 1px;
        background-color: white;
        align-self: flex-end;
        margin: 0 12px;
    }

    .spacer {
        height: 17.3px;
    }

    .vote_button_image {
        width: 36px;
        height: 36px;
    }

    .button {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &:disabled {
            cursor: not-allowed;
        }
    }

    .link_btn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        p {
            line-height: 1.3;
        }
    }
}

.ott_effect_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.card_wrapper {
    position: relative;
    width: 310px;
}

.stamp {
    position: absolute;
    z-index: 50;
    animation-name: stamp;
    animation-fill-mode: forwards;
    opacity: 0;
}

.point_text {
    z-index: -1;
    opacity: 0;
}

.show {
    animation: show 0.5s ease-in-out forwards;
    animation-delay: 1.6s;
}

.mystery_box_link {
    background-color: #ff0086;
    color: white;
    border-radius: 4px;
    padding: 0 12px;
    min-width: 100px;
    min-height: 40px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes stamp {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        transform: scale(1.4);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes show {
    0% {
        transform: translateY(40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
