// SearchAll
.loading {
    display: block;
    margin: 300px auto;
    width: 84px;
    height: 84px;

    path {
        fill: #ff0086;
    }
}

// SearchResult
.wrapper {
    width: 100%;
    padding: 160px 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 36px;

    .section_wrapper {
        width: 100%;
        padding: 0 12px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .title_wrapping {
        gap: 16px;
    }
}

// ArtistList
.artist_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 12px;
    column-gap: 24px;
}

// ProgramList
.program_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 16px;
}

// EpisodeList
.episode_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 16px;
}

@media (max-width: 768px) {
    .artist_list {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .program_list {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        row-gap: 0;
    }

    .episode_list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: 0;
    }
}

// ScheduleList
.schedule_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

//ScheduleCard
.schedule_card {
    width: 100%;
    border-bottom: 1px solid #272727;

    &:last-child {
        border-bottom: 0;
    }
}

.post_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.vote_list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.chatroom_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}

.img_wrapper {
    position: relative;
}

.schedule_img {
    object-fit: cover;
    width: 142px;
    height: 82px;
    margin: 0 22px 0 0;
    border-radius: 4px;
}

.live_badge {
    position: absolute;
    top: 4px;
    left: 4px;
}

.info {
    flex: 1;
}

.default_image {
    width: 142px;
    height: 82px;
    margin: 0 22px 0 0;
    border-radius: 4px;
    object-fit: contain;
    background-color: #aaa;
    padding: 24px;
}

//NotFoundSearchResult
.not_found_search_result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
}

.result_txt {
    margin-top: 16px;
}

//Theme
.theme {
    width: 100%;
    height: 92px;
    padding: 12px;
    border-radius: 8px;
    background-color: #272727;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    .title_wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .title {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.3px;
            color: #fff;
        }

        .follower {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.3px;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .theme_button_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;

        .theme_button {
            width: 100%;
            height: 32px;
            padding: 0 12px;
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;

            .theme_button_text {
                color: #fff;
                font-size: 12px;
            }
        }
    }
}

.sub_wrapper {
    padding: 160px 12px 60px;
}
