.post_section {
    min-width: 400px;
    padding-top: 222px;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ranking_list_wrapper {
    padding: 20px 30px;
    border-radius: 8px;
    background-color: #222;
}

.post_item {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}

.ribbon_wrapper {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ribbon {
        width: 20px;
    }
}

.ranking_diff_wrapper {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hashtag_wrapper {
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .hashtag {
        width: fit-content;
        padding: 4px 12px;
        border: 1px solid #aaaaaa;
        border-radius: 14px;
        font-size: 12px;
        font-weight: 600;
        color: #aaaaaa;
        cursor: pointer;

        &:hover {
            color: #444;
            border-color: #444;
        }
    }
}
