.wrapper {
    width: 100%;
    min-height: 100px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.back_btn_box {
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.read_comment_list {
    width: 100%;
    min-height: 100px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.comment_input{
    width: 100%;
}
