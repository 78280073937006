//EpisodeCard
.episode_card {
    width: 100%;
    padding: 0 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .img {
        width: 100%;
        aspect-ratio: 16 / 9;
        border-radius: 8px;
        object-fit: cover;
    }
}
