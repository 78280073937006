/* 이모지 아이콘 */
.icon {
    display: flex;
    align-items: center;
    i {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    span {
        margin-left: 2px;
        font-size: 12px;
        font-weight: 600;
    }
}
.heart {
    span {
        color: #555555;
    }
    &.on {
        span {
            color: #ff0086;
        }
    }
}

.like {
    span {
        color: #555555;
    }
    &.on {
        span {
            color: #ffe000;
        }
    }
}

.unlike {
    span {
        color: #555555;
    }
    &.on {
        span {
            color: #a900ff;
        }
    }
}

.comment {
    span {
        color: #555555;
    }
    &.on {
        span {
            color: #35ccff;
        }
    }
}

.small {
    i {
        width: 20px;
        height: 20px;
    }
    span {
        line-height: 20px;
    }
}

.middle {
    i {
        width: 24px;
        height: 24px;
    }
}

.big {
    i {
        width: 32px;
        height: 32px;
    }
}

/* 공유 링크 */
.share-icon {
    display: inline-block;
    margin-right: 2px;
    width: 24px;
    height: 24px;
    color: transparent;
    text-indent: inherit;
    background-image: url('/assets/img/icon_content_share.png');
}
.icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
