.input_wrapper {
    position: relative;
    margin: 6px 0;
    width: 100%;
}
.label {
    display: none;
}
.input {
    width: 100%;
    // width: 328px;
    height: 36px;
    padding: 8px 48px 8px 12px;
    border-radius: 2px;
    background-color: #fff;
}
.icon_wrapper {
    position: absolute;
    top: 6px;
    right: 6px;
    height: fit-content;
}
.icon {
    width: 24px;
    height: 24px;
}
