.thumbnail {
    margin-right: 12px;
    width: 42px;
    height: 42px;
}

.comment_form {
    display: flex;
    flex: 1;
}

.comment_input {
    background-color: #fff;
    border-radius: 18px;
    flex: 1;
    margin-right: 8px;
    align-items: center;
    display: flex;
    padding: 0 16px;

    input {
        width: 100%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.3px;
        color: #000;
    }
}

.input_submit {
    all: unset;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-image: url(../../assets/icon/btn-comment-send-off.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.input_submit_on {
    background-image: url(../../assets/icon/btn-comment-send-on.svg);
}
