.term_box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.term_text {
    margin-left: 12px;
    color: #666;
}

.picker_month {
    color: black;

    &.react-datepicker__month-text--keyboard-selected {
        color: white;

        &:hover {
            color: black;
        }
    }
}

.select_wrapper {
    position: relative;
    width: 144px;
    height: 36px;
    padding: 6px 12px;
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: -0.3;
    color: #ffffff;
    border-radius: 4px;
    text-align: left;
    display: flex;
    align-items: center;

    &:after {
        content: '';
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background: url(../../../assets/icon/icon-input-dropdown-down-on-black.svg) no-repeat;
    }

    .picker {
        color: white;
    }
}

.history_list {
    width: 100%;
    height: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow: scroll;
}

.history_list::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

.history {
    width: 100%;
    border-top: 1px solid #2a2a2a;
    padding: 24px 0;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-of-type {
        border-bottom: 1px solid #2a2a2a;
    }
}

.history_info {
    p {
        font-size: 14px;
        display: block;
        color: #666;
    }
}
