.shaka-hidden {
    display: none !important;
}
.shaka-video-container {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
}
.shaka-video-container .material-icons-round {
    font-family: 'Material Icons Round';
    font-size: 24px;
}
.shaka-video-container {
    width: 100%;
    height: 100%;
}
.shaka-video-container:fullscreen {
    width: 100%;
    height: 100%;
    background-color: #000;
}
.shaka-video-container:fullscreen .shaka-text-container {
    font-size: 4.4vmin;
}

.shaka-video-container:fullscreen .shaka-text-container span:not(.shaka-text-wrapper) {
    font-size: 4.4vmin;
}

.shaka-video-container:-webkit-full-screen {
    width: 100%;
    height: 100%;
    background-color: #000;
}
.shaka-video-container:-webkit-full-screen .shaka-text-container {
    font-size: 4.4vmin;
}

.shaka-video-container:-webkit-full-screen .shaka-text-container span:not(.shaka-text-wrapper) {
    font-size: 4.4vmin;
}

.shaka-video-container:-moz-full-screen {
    width: 100%;
    height: 100%;
    background-color: #000;
}
.shaka-video-container:-moz-full-screen .shaka-text-container {
    font-size: 4.4vmin;
}

.shaka-video-container:-moz-full-screen .shaka-text-container span:not(.shaka-text-wrapper) {
    font-size: 4.4vmin;
}

.shaka-video-container:-ms-fullscreen {
    width: 100%;
    height: 100%;
    background-color: #000;
}
.shaka-video-container:-ms-fullscreen .shaka-text-container {
    font-size: 4.4vmin;
}

.shaka-video-container:-ms-fullscreen .shaka-text-container span:not(.shaka-text-wrapper) {
    font-size: 4.4vmin;
}

.shaka-controls-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
}
.shaka-video-container:not([shaka-controls='true']) .shaka-controls-container {
    display: none;
}
.shaka-controls-container * {
    flex-shrink: 0;
}
.shaka-controls-container[casting='true'] .shaka-fullscreen-button {
    display: none;
}
.shaka-bottom-controls {
    width: 96%;
    padding: 0;
    padding-bottom: 2.5%;
    z-index: 1;
}
.shaka-controls-button-panel {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    min-width: 48px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    opacity: 0;
    transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
}
.shaka-controls-container[casting='true'] .shaka-controls-button-panel,
.shaka-controls-container[shown='true'] .shaka-controls-button-panel {
    opacity: 1;
}
.shaka-controls-button-panel > * {
    color: #fff;
    height: 32px;
    line-height: 0.5;
    margin: 1px 6px;
    padding: 0;
    background: 0 0;
    border: 0;
    cursor: pointer;
}
.shaka-controls-button-panel .shaka-overflow-menu-only {
    display: none;
}
.shaka-play-button-container {
    margin: 0;
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.shaka-scrim-container {
    margin: 0;
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0.4));
}
.shaka-controls-container[casting='true'] .shaka-scrim-container,
.shaka-controls-container[shown='true'] .shaka-scrim-container {
    opacity: 1;
}
.shaka-text-container {
    position: absolute;
    left: 0;
    bottom: 6%;
    pointer-events: none;
    width: 100%;
    min-width: 48px;
    transition: bottom cubic-bezier(0.4, 0, 0.6, 1) 0.1s;
    transition-delay: 0.5s;
}
.shaka-text-container span:not(.shaka-text-wrapper) {
    display: inline;
    font-size: 20px;
    line-height: 1.4;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
}
.shaka-text-container span.shaka-text-wrapper {
    display: inline;
    background: 0 0;
}
.shaka-spinner-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.shaka-video-container:not([shaka-controls='true']) .shaka-spinner-container {
    display: none;
}
.shaka-spinner {
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    box-sizing: border-box;
    padding: 7.8%;
    width: 0;
    height: 0;
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.5));
}
.shaka-play-button {
    box-sizing: border-box;
    padding: 7.5%;
    width: 0;
    height: 0;
    margin: 0;
    border-radius: 50%;
    border: none;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 20px 0;
    /* background-color: rgba(255, 255, 255, 0.9); */
    opacity: 0;
    transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
}
.shaka-controls-container[casting='true'] .shaka-play-button,
.shaka-controls-container[shown='true'] .shaka-play-button {
    opacity: 1;
}
.shaka-play-button[icon='play'] {
    /* background-image: url(data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M8%205v14l11-7z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E); */
    background-image: url(../assets/icon/pause_icon.svg);
}
.shaka-play-button[icon='pause'] {
    /* background-image: url(data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M6%2019h4V5H6v14zm8-14v14h4V5h-4z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E); */
    background-image: url(../assets/icon/play_icon.svg);
}
.shaka-current-time {
    font-size: 14px;
    color: #fff;
    height: auto;
    cursor: pointer;
}
.shaka-current-time[disabled] {
    background-color: transparent;
    color: #fff;
    cursor: default;
}
.shaka-controls-container button:focus,
.shaka-controls-container input:focus {
    outline: 1px solid;
}
.shaka-controls-container button::-moz-focus-inner,
.shaka-controls-container input::-moz-focus-outer {
    outline: 0;
    border: 0;
}
.shaka-controls-container:not(.shaka-keyboard-navigation) button:focus,
.shaka-controls-container:not(.shaka-keyboard-navigation) input:focus {
    outline: 0;
}
.shaka-range-container {
    position: relative;
    top: 0;
    left: 0;
    margin: 4px 6px;
    height: 4px;
    border-radius: 4px;
    background: #fff;
}
.shaka-volume-bar-container {
    width: 100px;
}
.shaka-range-element {
    -webkit-appearance: none;
    background: 0 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    height: 12px;
    top: -4px;
    z-index: 1;
}
.shaka-range-element::-webkit-slider-runnable-track {
    width: 100%;
    height: 12px;
    background: 0 0;
    color: transparent;
    border: none;
}
.shaka-range-element::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    border-radius: 12px;
    height: 12px;
    width: 12px;
    background: #fff;
}
.shaka-range-element::-moz-range-track {
    width: 100%;
    height: 12px;
    background: 0 0;
    color: transparent;
    border: none;
}
.shaka-range-element::-moz-range-thumb {
    -webkit-appearance: none;
    border: none;
    border-radius: 12px;
    height: 12px;
    width: 12px;
    background: #fff;
}
.shaka-seek-bar-container {
    opacity: 0;
    transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
}
.shaka-controls-container[casting='true'] .shaka-seek-bar-container,
.shaka-controls-container[shown='true'] .shaka-seek-bar-container {
    opacity: 1;
}
.shaka-ad-markers {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

/* sppiner */
.shaka-spinner-svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.shaka-spinner-path {
    stroke: #ff0086;
    stroke-dasharray: 20, 200;
    stroke-dashoffset: 0;
    animation: dash 1s ease-in-out infinite;
    stroke-linecap: round;
}
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
.shaka-spacer {
    cursor: default;
    flex-shrink: 1;
    flex-grow: 1;
    margin: 0;
}
.shaka-overflow-menu,
.shaka-settings-menu {
    overflow-x: hidden;
    overflow-y: auto;
    white-space: nowrap;
    background: #fff;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    max-height: 250px;
    min-width: 180px;
    opacity: 0;
    transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    right: 15px;
    bottom: 30px;
}
.shaka-controls-container[casting='true'] .shaka-overflow-menu,
.shaka-controls-container[casting='true'] .shaka-settings-menu,
.shaka-controls-container[shown='true'] .shaka-overflow-menu,
.shaka-controls-container[shown='true'] .shaka-settings-menu {
    opacity: 1;
}
.shaka-overflow-menu button,
.shaka-settings-menu button {
    font-size: 14px;
    background: 0 0;
    color: #000;
    border: none;
    min-height: 30px;
    padding: 3.5px 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.shaka-overflow-menu button:hover,
.shaka-settings-menu button:hover {
    background: #e0e0e0;
}
.shaka-overflow-menu button label,
.shaka-settings-menu button label {
    cursor: pointer;
}
.shaka-keyboard-navigation .shaka-overflow-menu button:focus,
.shaka-keyboard-navigation .shaka-settings-menu button:focus {
    background: #e0e0e0;
}
.shaka-overflow-menu i,
.shaka-settings-menu i {
    padding-left: 10px;
    padding-right: 10px;
}
.shaka-overflow-menu.shaka-low-position,
.shaka-settings-menu.shaka-low-position {
    bottom: 15px;
}
.shaka-overflow-menu span {
    text-align: left;
}
.shaka-overflow-button-label {
    position: relative;
    display: flex;
    flex-direction: column;
}
.shaka-current-selection-span {
    color: rgba(0, 0, 0, 0.54);
}
.shaka-settings-menu span {
    margin-left: 54px;
}
.shaka-back-to-overflow-button span {
    margin-left: 0;
}
.shaka-back-to-overflow-button i {
    padding-right: 20px;
}
.shaka-auto-span {
    left: 17px;
}
.shaka-captions-on {
    color: #000;
}
.shaka-captions-off {
    color: grey;
}
.shaka-controls-container[ad-active='true'] {
    pointer-events: none;
}
.shaka-controls-container[ad-active='true'] .shaka-bottom-controls {
    pointer-events: auto;
}
.shaka-client-side-ad-container,
.shaka-server-side-ad-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.shaka-video-container[shaka-controls='true'] .shaka-client-side-ad-container iframe,
.shaka-video-container[shaka-controls='true'] .shaka-server-side-ad-container iframe {
    height: 90%;
}
.shaka-server-side-ad-container {
    width: 100%;
    height: 100%;
    flex-shrink: 1;
}
.shaka-server-side-ad-container:not([ad-active='true']) {
    pointer-events: none;
}
.shaka-ad-controls {
    display: flex;
    flex-direction: row;
    z-index: 1;
    padding-bottom: 1%;
}
.shaka-video-container:not([shaka-controls='true']) .shaka-ad-controls {
    display: none;
}
.shaka-ad-controls button,
.shaka-ad-controls div {
    color: #fff;
    font-size: initial;
}
.shaka-ad-controls div:not(.shaka-skip-ad-counter) {
    margin: 1px 6px;
}
.shaka-ad-counter,
.shaka-ad-position {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    text-shadow: 1px 1px 4px #000;
}
.shaka-skip-ad-container {
    position: relative;
    right: -2%;
    display: flex;
    flex-direction: row;
    margin: 0;
}
.shaka-skip-ad-button {
    padding: 5px 15px;
    background: rgba(0, 0, 0, 0.7);
    border: none;
    cursor: pointer;
}
.shaka-skip-ad-button:disabled {
    background: rgba(0, 0, 0, 0.3);
}
.shaka-skip-ad-counter {
    padding: 5px 5px;
    background: rgba(0, 0, 0, 0.7);
    margin: 0;
}
@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype');
}
@font-face {
    font-family: 'Material Icons Round';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconsround/v80/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmM.otf) format('opentype');
}
.material-icons-round {
    font-family: 'Material Icons Round';
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    /* color: transparent; */
    color: white;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}

.shaka-bottom-controls {
    display: flex;
    flex-direction: column;
}
.shaka-seek-bar-container {
    order: -1;
    background: violet;
}

/* .shaka-small-play-button {
    order: -3;
}
.shaka-mute-button {
    order: -2;
}
.shaka-volume-bar-container {
    order: -1;
} */
.shaka-fullscreen-button {
    background-image: url(../assets/icon/full_screen_icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: transparent;
    /* order: 1; */
}
.shaka-overflow-menu-button {
    position: absolute;
    top: 12px;
    right: 12px;
    color: transparent;
    background-image: url(../assets/icon/setting_icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.shaka-overflow-menu {
    position: absolute;
    top: 48px;
    right: 12px;
    height: fit-content;
    background-color: #333;
    color: white;
}
.shaka-overflow-menu span {
    color: white;
}
.shaka-overflow-menu button:hover {
    background-color: #2a2a2a;
}
.shaka-current-selection-span {
    color: white;
}

.shaka-settings-menu {
    position: absolute;
    top: 48px;
    right: 12px;
    background-color: #333;
    color: white;
}
.shaka-settings-menu span {
    color: white;
}
.shaka-settings-menu button:hover {
    background-color: #2a2a2a;
}
