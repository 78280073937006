.candidate_card {
    display: flex;
    align-items: center;
}
.rank_number {
    width: 10%;
    font-size: 24px;
    text-align: center;
}
.cover_image {
    margin: 0px 16px;
    border-radius: 8px;
    width: 86px;
    height: 86px;
}
.info_wrapper {
    width: 100%;
}
.detail_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}
.artist {
    font-size: 14px;
    color: #666;
}
.rating {
    font-size: 20px;
    font-weight: 600;
    color: #ff0086;
}
.progressbar {
    position: relative;
    height: 8px;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #333;
}
.guage {
    position: absolute;
    height: 8px;
    border-radius: 8px;
    background-color: #ff0086;
}

// props
.big {
    zoom: 1.3;
}

// for_result
.artist_home_button {
    display: flex;
    align-items: center;
    border-radius: 24px;
    background-color: #2b2b2b;
    padding: 8px 20px 8px 16px;
    zoom: unset;
    color: white;

    span {
        font-size: 14px;
    }
}
.artist_home_icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    object-fit: contain;
    background-image: url(/assets/img/btn-chart-artisthome.png);
}
