.modal__wrapper {
    position: fixed;
    inset: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    transition: background 0.25s ease;

    &.overlay {
        background: rgba(0, 0, 0, 0.8);
    }
}

.bg-white {
    background-color: white;
}

.bg-dark {
    background-color: #252525;
}
