.vote_pool_section {
    width: 100%;
    padding: 220px 12px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.vote_pool_image {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 8px;
    object-fit: cover;
}

.banner_img {
    width: 100%;
    aspect-ratio: 9/2;
    object-fit: contain;
}

.vote_pool_descript {
    white-space: pre-line;
    word-break: break-all;
}

.next_pool {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 14px;
}

.next_pool_info {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 7px;
}

.next_pool_title_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.vote_btn_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .vote_btn {
        width: 100%;
        height: 56px;
        border-radius: 28px;
        background-color: '##ff0086';
        font-size: 16px;
        font-weight: bold;
    }

    .vote_disable_btn {
        width: 100%;
        height: 56px;
        background-color: #444444;
        font-size: 16px;
        font-weight: bold;
    }

    .daily_remain_time {
        color: yellow;
        font-size: 14px;
        font-weight: bold;
    }
}

.stage_wrapper {
    width: 100%;
    padding: 0 12px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.stage_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .stage_item {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}

.comment_input_wrapper {
    width: 100%;
    height: 60px;
    padding: 0;
    margin: 0;
    background-color: #141414;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    .comment_input {
        width: 100%;
        max-width: 768px;
        padding: 0 12px;
    }
}

@media (max-width: 768px) {
    .comment_input_wrapper {
        position: fixed;
        bottom: 0;
    }
}

.comments_wrapper {
    padding: 0 12px 60px;
}
