.wrapper {
    width: 100%;
    height: 100vh;
    padding: 64px 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

//ChatRoomInfo
.chatroom_info {
    width: 100%;
    padding: 10px;
    background-color: #222;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .prev_icon {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }

    .title {
        min-width: 100px;
        flex: 1;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        text-overflow: ellipsis;
    }
}

// Settingbox
.selected_image_lable {
    cursor: pointer;
    width: 104px;
    height: 104px;
    margin: 0 auto 18px;
    border-radius: 52px;
    background-color: #555;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selected_image {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 52px;
    object-fit: cover;
}

.camera_icon_bg {
    position: absolute;
    bottom: 0;
    right: 0;
}

.input_wrapper {
    position: relative;
}

.input {
    width: 328px;
    height: 36px;
    margin: 8px 0 8px;
    padding: 8px 64px 8px 8px;
    border-radius: 4px;
    background-color: #fff;
}

.title_length {
    position: absolute;
    right: 8px;
}

.button {
    width: 142px;
}

//Modal
.box {
    width: 408px;
    height: 524px;
    padding: 32px 40px 34px;
    margin: 32px auto;
    border-radius: 8px;
    background-color: #222;
}

.modal_box {
    padding-top: 24px;
    border-radius: 8px 8px 0 0;
    background-color: #fff;
}

.imgae_presets_list {
    display: flex;
    flex-wrap: wrap;
    width: 336px;
}

//CircleImage
.circle_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.circle_image {
    width: 72px;
    height: 72px;
    margin: 20px;
    border-radius: 50%;
}

.circle_layer {
    position: absolute;
    background-color: #ff0086;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    width: 72px;
    height: 72px;
    margin: 20px;
    border-radius: 50%;
    z-index: 1;
}

.file_input {
    width: 72px;
    height: 72px;
    margin: 20px;
    border-radius: 50%;
    border: 1px solid #666;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cancle {
    height: 48px;
    width: 50%;
    border-radius: 0 0 0 8px;
    background-color: #999;
    color: white;
    font-weight: bold;
}

.ok {
    width: 50%;
    height: 48px;
    border-radius: 0 0 8px 0;
    background-color: #ff0086;
    color: white;
    font-weight: bold;
}

/////
.image_input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

//ChatBox
.chat_box {
    width: 100%;
    min-height: 100px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.chat_list {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
    object-fit: contain;
    border-radius: 8px;
    background-color: #191919;
    padding: 0 12px 12px;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.chat_input_box {
    width: 100%;
    height: 60px;
    padding: 12px;
    border-radius: 0 0 8px 8px;
    background-color: #222;
}

.image_input_label {
    cursor: pointer;
}

.image_input {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
}

.chat_input {
    display: flex;
    flex: 1;

    .input_wrapper {
        display: flex;
        align-items: center;
        flex: 1;
        height: 36px;
        padding: 8px 16px;
        margin: 0 8px;
        border-radius: 18px;
        background-color: #fcfcfc;
        input {
            flex: 1;
        }
    }
    .emoji_icon {
        cursor: pointer;
    }
    .submit_button {
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }
    }
}

//chatItem
.message {
    width: 100%;
    max-width: 744px;
    display: flex;
    gap: 10px;

    .blank {
        width: 32px;
        height: 32px;
    }

    .message_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;

        .message_item_body {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            gap: 8px;
        }
    }

    .image {
        position: relative;
        margin: 0 8px;
        width: 440px;
        height: auto;
        object-fit: contain;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-radius: 8px;
        overflow: hidden;

        img {
            display: flex;
            flex: 1;
            max-width: 100%;
            object-fit: contain;
            //width: 100%;
            //height: 100%;
        }
    }

    .video {
        margin: 0 8px;
        max-width: 440px;
        height: auto;
    }

    .loading {
        path {
            fill: #ff0086;
        }
    }
}

@media (max-width: 768px) {
    .message {
        max-width: calc(100vw - 24px);
    }
}

.my_message {
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
}

.others_message {
    justify-content: flex-start;
    align-items: flex-start;
}

.others_content {
    background: #fff;
    border-radius: 8px;
    position: relative;
    color: black;
    padding: 8px;
    word-break: break-all;
    margin: 0 0 0 8px;
    align-self: flex-start;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border: 14px solid transparent;
        border-right-color: #fff;
        border-left: 0;
        border-top: 0;
        margin-left: -7px;
    }
}

.my_content {
    position: relative;
    background: #8f00ff;
    border-radius: 8px;
    word-break: break-all;
    padding: 8px;
    margin: 0 8px 0 0;
    color: white;
    align-self: flex-end;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border: 14px solid transparent;
        border-left-color: #8f00ff;
        border-right: 0;
        border-top: 0;
        margin-right: -7px;
    }
}

//ChatRoomMembers
.chatmembers_box {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .member_count_box {
        width: 100%;
        height: 60px;
        padding: 0 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .member_header_title {
            font-size: 18px;
            font-weight: bold;
            color: #000000;
        }

        .member_count {
            font-size: 14px;
            color: #000000;
        }
    }

    .user_list {
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 12px 0 36px;

        .user_li {
            width: 100%;
            padding: 8px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .user_info {
                min-width: 100px;
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                .user_title {
                    span {
                        flex: 1;
                        margin-left: 8px;
                        font-size: 13px;
                        color: #000000;
                    }
                }
            }
        }
    }

    .leave_button {
        width: 100%;
        padding: 20px 0;
        border-radius: 0;
    }
}

//chatroom memberitem
.option_wrapper {
    display: flex;
    position: relative;
    align-items: center;
}

.member_options {
    min-width: 150px;
    background-color: #666666;
    padding: 12px;
}
