.wrapper {
    width: 100%;
    padding: 96px 12px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.list_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 16px;
}

@media (max-width: 768px) {
    .wrapper {
        padding: 64px 12px 0;
    }

    .list_wrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 8px;
    }
}

.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    .card_image {
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        object-fit: cover;
    }

    .card_title {
        font-size: 1em;
    }
}
