.wrapper {
    width: 100%;
    padding: 184px 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.header {
    width: 100%;
    padding: 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tab_header {
    width: 100%;
    margin: 0;
}

.list_wrapper {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    :first-child {
        border-top: 0;
    }
}

.card {
    width: 100%;
    padding: 8px 0;
    border-top: 1px solid #222;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .praline_info {
        min-width: 100px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 4px;

        .title_wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
