.sign_main_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
}
.select_box {
    margin-top: 32px;
    width: 100%;
    max-width: 408px;
    height: auto;
    padding: 24px 40px 34px;
    border-radius: 8px;
    background-color: #222;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    @media (max-width: 768px) {
        padding: 12px;
    }
}
.sub {
    color: #aaa;
    text-align: center;
}
.badge {
    width: 100%;
    margin-top: 12px;
    position: relative;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        width: 42px;
        height: 42px;
    }
}
