.card_grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 8px;
    @media (max-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
.skiplink {
    font-size: 16px;
    line-height: 48px;
    text-align: right;
    color: #aaa;
    letter-spacing: -0.3px;
    // position:absolute;
    right: 48px;
    // top:0;
    @media (max-width: 768px) {
        display: none;
    }
}

.contentscont {
    height: 36px;
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 12px;

    p {
        font-size: 16px;
        line-height: 20px;
        color: #aaa;
        letter-spacing: -0.3px;
    }
}

.next_btn {
    width: 240px;
    display: block;
    margin: 72px auto 0;

    // display:inline-block;
    // width:158px;
    line-height: 42px;
    font-size: 14px;
    letter-spacing: -0.3px;
    color: #fff;
    // margin:0 6px;
    border-radius: 30px;
    text-align: center;
    background-color: #ff0086;
    cursor: pointer;

    // display: block;
    // width: 30%;
    // height: 56px;
    // border-radius: 28px;
    // margin-top: 36px;
    // background-color: #ff0086;
    // text-align: center;
    // font-size: 16px;
    // font-weight: 600;
    // line-height: 56px;
    // letter-spacing: -.3px;
    // color: #fff;
}
