// VoteInfo
.info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.total {
    display: flex;
    align-items: center;
    font-size: 14px;

    p {
        margin-right: 12px;
    }
}

.voted {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.3px;
    text-align: right;
    color: #666;
}

.em {
    color: #ff0086;
}

// Candidate
.candidate {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.candidate_info {
    display: flex;
    align-items: center;
    flex: 1;
}

.rank {
    font-size: 20px;
    margin-right: 12px;
    min-width: 20px;
    text-align: center;
}

.artist_info {
    margin-left: 12px;
}

.master_name {
    font-size: 13px;
    color: #aaa;
}

.progressbar {
    position: relative;
    height: 8px;
    margin-top: 10px;
    border-radius: 4px;
    margin-left: 12px;
    background-color: #333;
}

.gauge {
    position: absolute;
    height: 8px;
    border-radius: 8px;
    background-color: #ff0086;
}

.rating {
    position: absolute;
    right: 0;
    top: -24px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.3px;
    text-align: right;
    color: #ff0086;
}
