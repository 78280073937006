.image_wrapper {
    position: relative;
}

.episode_img {
    width: 100px;
    height: 56px;
    margin: 0 16px 0 0;
    border-radius: 4px;
    object-fit: cover;
}
