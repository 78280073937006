//MyAritsts
.h3 {
    text-align: center;
}

//PrivateMailArtist
.col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.artist_img {
    width: 144px;
    height: 144px;
    border-radius: 50%;
}

.artist_name {
    font-size: 16px;
    margin-top: 16px;
}

.parent_group {
    font-size: 14px;
    color: #aaa;
    margin-bottom: 12px;
}
