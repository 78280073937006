.comment_item {
    width: 100%;
    padding: 6px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;

    .edit_input {
        background-color: white;
        flex: 1;
        padding: 4px 12px;
        margin: 2px 0 4px;
        border-radius: 18px;
    }
}

.comment_detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;

    .comment_info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .comment_body {
        width: 100%;
        margin: 2px 0 4px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        overflow: hidden;
        word-break: break-all;
    }

    .comment_stat_info {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .stat_left {
            min-width: 100px;
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 24px;

            .like_icon_box {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 4px;

                .icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

.edit_btn {
    margin: 0 4px;
}
