//video root
.controller {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 1;
    padding: 2.5%;
    transition: opacity ease 1s;
    z-index: 2;
}
.unactive {
    opacity: 0;
    transition: opacity ease 1s;
}
.icon {
    cursor: pointer;
    width: 36px;
    height: 36px;
}
.is_casting {
    position: absolute;
    background-color: #555;
    padding: 8px 24px 8px 12px;
    top: calc(50% - 20px);
    left: calc(50% - 100px);
    width: 200px;
    height: 40px;
    border-radius: 8px;
    z-index: 5;
}

//Landcape buttons
.landscape_buttons {
    padding-top: 10%;
    padding-bottom: 4%;
    width: 100%;
}
.landscape_button {
    color: white;
    display: flex;
    align-items: center;
    border-radius: 18px;
    padding: 6px 14px;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 0 12px;
    transition: 0.3s;
    &:hover {
        background-color: rgba(0, 0, 0, 0.9);
    }
}
.disable {
    cursor: not-allowed;
}

//CommentLandscape
.setting_icon {
    align-self: flex-end;
    cursor: pointer;
}
.spacer {
    flex: 1;
}
.thread_list {
    position: absolute;
    top: 0;
    right: 0;
    width: 360px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 3;
}
.tit_box {
    padding: 16px;
}
.hidden_scroll_list {
    overflow-y: scroll;
    flex: 1;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}
.close_btn {
    width: 42px;
    height: 42px;
    margin-right: 8px;
    background-size: contain;
    cursor: pointer;
}
.comment_input_wrapper {
    padding: 0 12px;
}
//EpisodeLandscape
.pull_episode {
    position: absolute;
    top: 0;
    right: 0;
    width: 360px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 3;
}
.tit_box {
    position: relative;
}
.episode_list {
    padding: 0 26px;
    height: calc(100% - 74px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    overflow: auto;
}
.episode_list::-webkit-scrollbar {
    width: 6px;
}
.episode_list::-webkit-scrollbar-track {
    background-color: transparent;
}
.episode_list::-webkit-scrollbar-thumb {
    background-color: transparent;
}
.episode_list::-webkit-scrollbar-button {
    width: 0;
    height: 0;
}
.episode_item {
    padding: 12px 0;
}

//Setting
.player_setting {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    max-width: 360px;
    height: 100%;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 3;
}
.tit {
    margin-bottom: 24px;
}
.menu {
    margin-bottom: 12px;
}
.close_btn {
    width: 42px;
    height: 42px;
    margin-right: 8px;
    background-size: contain;
    cursor: pointer;
}
