.bg_layer {
    padding-top: 210px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .bg_empty {
        width: 100%;
        aspect-ratio: 16 / 9;
        background-color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.row_1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transform: translateY(-28px);

    .title {
        font-size: 36px;
        font-weight: bold;
    }
}

.badge_icon {
    margin-right: 6px;
}

.follow {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .follow_txt {
        font-size: 14px;
        font-weight: 600;
    }

    .txt {
        font-size: 12px;
    }
}

.row {
    width: 50%;
    padding: 2px 0;
    display: flex;
    align-items: center;
    color: white;

    .left {
        flex: 0.5;
        color: #666;
    }
    .right {
        flex: 1;
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .row {
        width: 100%;
    }
}

.descript {
    width: 100%;
    padding: 12px 0 0;
    font-size: 14px;
    color: white;
    white-space: pre-line;
    word-break: break-all;
}

.title_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .title {
        width: fit-content;
    }

    .member_count {
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        color: #ff0086;
    }
}

.flex_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, auto));
    gap: 24px;
}

@media (max-width: 768px) {
    .flex_list {
        grid-template-columns: repeat(2, minmax(100px, auto));
    }
}

//MemberCard
.col {
    position: relative;
    min-width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .artist_title_wrapper {
        width: 100%;
        height: 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .artist_group_name {
        color: #aaa;
        font-size: 12px;
    }

    .leader_badge {
        position: absolute;
        top: 0;
        left: 0;
    }
}

// AlbumSection
.section_title {
    width: 100%;
    padding: 0 12px;
}

.sub_header_wrapper {
    width: 100%;
    padding: 0;
    margin: 0 0 8px;

    .tab_button {
        width: 100%;
        flex: 1;
        height: 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
    }
}

.album_list {
    width: 100%;
    padding: 0 12px;
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    gap: 24px;
}

.mv_list {
    width: 100%;
    padding: 0 12px;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 24px;
}

@media (max-width: 768px) {
    .album_list {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
    }
}

// VODSection
.genre_lists {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
}

//AlbumCard
.album_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .album_image {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
    }
}

.card_info {
    width: 100%;
    height: 94px;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .card_title {
        width: 100%;
        margin: 4px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .desc {
        width: 100%;
    }
}

//MVCard
.mv_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mv_image {
        width: 100%;
        aspect-ratio: 16 / 9;
        border-radius: 8px;
        object-fit: cover;
    }
}

//Comment
.comment {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 24px;

    .wrapper {
        width: 100%;
        border-radius: 8px;
        background-color: #222;
        padding: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;

        .profile {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            object-fit: cover;
        }

        .info {
            min-width: 1px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            // p {
            //     word-break: break-word;
            //     white-space: normal;
            //     font-size: 14px;
            //     font-weight: normal;
            //     font-stretch: normal;
            //     font-style: normal;
            //     letter-spacing: -0.3px;
            //     color: #fff;
            // }

            .name {
                width: 100%;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: -0.3px;
                color: #fff;
            }

            .job {
                width: 100%;
                opacity: 0.6;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: -0.3px;
                color: #fff;
            }

            .desc {
                width: 100%;
                margin-top: 12px;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: -0.3px;
                color: #fff;
                white-space: pre-line;
                word-break: break-all;
            }
        }
    }
}

@media (max-width: 768px) {
    .comment {
        grid-template-columns: repeat(1, minmax(100px, 1fr));
    }
}
