.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .header {
        width: 100%;
        height: 58px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 1.14em;
            font-weight: bold;
        }

        .sort {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.hidden_scroll_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    .episode_item {
        width: 100%;
        padding: 14px;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .playing {
        border: 1px solid #ff0086;
    }
}

.episode_title_wrapper {
    min-width: 100px;
    flex: 1;

    .title {
        width: 100%;
        text-overflow: ellipsis;
        word-break: break-all;
    }
}

.more_button {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
