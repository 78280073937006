// Write
.container {
  padding: 16px 0 0;
}

.head_wrap {
    display: flex;
    justify-content: space-between;
}

.separator {
    display: inline-block;
    height: 12px;
    border-right: 1px solid #333;
    padding-left: 12px;
}

.active {
    color: #ff0086;
}

// Like
.like {
    fill: #ff0086;
    color: #ff0086;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
}

// Comment
.comment {
    fill: #555;
    color: #555;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
}

// WriteCard
.write_card {
    padding: 24px;
    background-color: #222;
    border-radius: 8px;
    flex-grow: 1;
}

.card_content {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}

.collection_wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 16px;
}

@media(max-width:768px) {
  .collection_wrapper {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }
}

.collection_card {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;

    &.comment {
        background-color: #222;
        padding: 0 24px;
    }
}

.card_placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 52px 0;
    color: #ffffff4d;
}

.card_head_link {
    &:hover {
        text-decoration: underline;
    }
}

.card_description {
    color: #777;
}

.card_img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
}

.flex_center {
    display: flex;
    align-items: center;
}
