//MyPageMain SubHeader
.tab_list {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 12px;
    width: 240px;
    height: auto;
    font-weight: 600;
    // color: #666;
    transition: 0.3s;

    &:hover {
        color: #fff;
    }
}

.wrapper {
    width: 100%;
    padding: 96px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
}

@media (max-width: 768px) {
    .wrapper {
        padding: 64px 0 0;
    }
}

// MyProfileBanner
.banner_wrapper {
    width: 100%;
    padding: 0 12px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;

    .banner_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .setting_button {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .setting_button_text {
                color: #999;
            }
        }
    }
}

.banner_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #222;
    padding: 12px;
    margin: 0;
    border-radius: 8px;
    gap: 16px;

    & > .banner_left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;

        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
        }

        .user_info {
            min-width: 100px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;

            .user_name {
                font-size: 16px;
                font-weight: bold;
            }

            .extra_info {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .point_box {
                    padding-left: 24px;
                    background: url('https://stam-p.com/@project/m3/img/icon-point-20-circle.png') no-repeat;
                    background-size: contain;
                    font-size: 14px;
                    font-weight: bold;
                    color: #ffcc00;
                }

                .edit_info {
                    color: white;
                }
            }

            .point_button {
                color: #aaaaaa;
                font-size: 12px;
            }
        }
    }
}

.button_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .plan {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .plan_name {
            color: #ff0086;
            font-size: 14px;
            font-weight: bold;
        }

        .plan_name_suffix {
            color: white;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .button {
        width: 100%;
        height: 48px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button_text {
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
        }
    }

    .profile_button {
        background-color: #ff0086;
    }
}

.list_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;

    .list_title {
        font-size: 1em;
        font-weight: bold;
    }

    .list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;

        .artist_button {
            width: 48px;
            aspect-ratio: 1 / 1;
            flex-shrink: 0;

            .plus_button {
                width: 100%;
                height: 100%;
                border-radius: 24px;
                background-color: #444444;
            }

            .artist_image {
                width: 100%;
                height: 100%;
                border-radius: 24px;
                object-fit: cover;
            }
        }
    }
}

@media (max-width: 768px) {
    .list_wrapper {
        .list {
            overflow-x: scroll;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            flex-wrap: nowrap;
        }
    }
}

.my_lists {
    width: 100%;
    padding: 0 12px;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .button_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;

        .button_image {
            width: 32px;
            height: 32px;
        }

        .button_title {
            font-size: 1em;
            color: #aaa;
        }
    }
}
