.wrapper {
    width: 100%;
    max-width: 768px;
    padding: 222px 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.header_wrapper {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    width: 10px;
    flex: 1;
}

.select_button {
    padding: 6px 12px;
    border-radius: 4px;
    background-color: #444444;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.drop_image {
    width: 24px;
    aspect-ratio: 1 / 1;
}

.empty_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category_list {
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

@media (max-width: 768px) {
    .category_list {
        overflow-x: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
        flex-wrap: nowrap;
    }
}

.category {
    padding: 4px 12px;
    border: 1px solid #aaaaaa;
    border-radius: 14px;
    white-space: nowrap;

    &:hover {
        border-color: #444;
        color: #444;
    }

    &.active {
        border-color: #ff0086;
        color: #ff0086;
    }
}

.month_wrapper {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.date_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.date {
    width: 10%;
    min-width: 60px;
    min-height: 140px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new {
    color: #ff0086;

    &.hidden {
        visibility: hidden;
    }
}

.date_schedule {
    flex: 1;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

.schedule {
    width: 100%;
    height: 120px;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #222222;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.schedule_category {
    width: 100%;
}

.schedule_title {
    width: 100%;
    overflow: hidden;
    white-space: pre-line;
    word-break: break-all;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
