.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.header_wrapper {
    width: 100%;
    gap: 12px;
}
