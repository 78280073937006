//CommentsSection
.main_comments {
    display: inline;
    flex-direction: column;
}

//CommentBox
.comment {
    display: flex;
    flex: 1;
    align-items: flex-start;
    position: relative;
}

.cmt_box {
    margin: 0 auto 0 12px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.name_date_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.date {
    font-size: 12px;
    color: #4d4d4d;
}

.content {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    word-break: keep-all;
    word-wrap: break-word;
    margin: 4px 0px 8px;
}

.stat_option_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.stats_btn {
    display: flex;
    align-items: center;
}

.stat_text {
    margin-right: 24px;
}

.option_btn {
    background-image: url(/assets/img/icon_comment_option.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.opt_pop {
    width: 152px;
    height: auto;
    position: absolute;
    top: 0px;
    right: 36px;
    z-index: 1;

    button {
        display: block;
        padding: 12px 16px;
        width: 100%;
        text-align: left;
        font-size: 14px;
        line-height: 1.6;
        color: #fff;
        background-color: #666;
        transition: 0.3s;
        z-index: 2;

        &:hover {
            background-color: #555;
        }
    }
}

.opt_layer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: transparent;
}

.sub_comments {
    padding: 16px 20px 12px;
    border-radius: 8px;
    background-color: #222;
    width: calc(100% - 54px);
    margin: 0 0 0 auto;

    &.box {
        width: 100%;
        padding: 0 4px;
        background-color: transparent;
    }
}
.icon {
    cursor: pointer;
}

//CommentEditor
.input_comment {
    margin: 0;

    fieldset {
        padding: 0;
    }

    input {
        display: block;
        padding: 8px 40px 8px 16px;
        margin: 12px 0;
        width: 100%;
        height: 36px;
        box-sizing: border-box;
        border-radius: 18px;
        font-size: 14px;
        color: #000;
        background-color: #fff;
    }

    button {
        display: inline-block;
        width: 100px;
        padding: 0 8px;
        height: 36px;
        border-radius: 18px;
        font-size: 14px;
        color: #fff;
    }
}

.emoji_toggle {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 26px;
    right: 52px;
    color: transparent;
    text-indent: -9999px;
    overflow: hidden;
    background-image: url('/assets/img/icon_input_emoticon_on_black.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.edit_btn {
    margin-right: 8px;
    background-color: #ff0086;
}

.cancle_btn {
    background-color: #444;
}

//
.comment_body {
    margin: 2px 0 4px;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.edit_input_wrapper {
    position: relative;
    display: flex;
    align-items: center;
}
.edit_input {
    background-color: white;
    flex: 1;
    padding: 4px 12px;
    margin: 2px 0 4px;
    border-radius: 18px;
}
.edit_btn {
    margin: 0 4px;
}
.spacer {
    flex: 1;
    position: relative;
}
.stat_text {
    margin: 0 24px 0 2px;
}
.comment_input {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #1f1f1f;
    padding: 12px;
}
.dropdown {
    width: 152px;
    padding: 8px 12px;
}
