.input_wrapper {
    width: 340px;
    height: 36px;
    margin: 0 8px 0 0;
    padding: 8px 8px 8px 16px;
    border-radius: 18px;
    background-color: #fff;
    position: relative;
}
.input {
    width: calc(100% - 40px);
}
.clear_icon {
    position: absolute;
    right: 8px;
}
.search_icon {
    fill: #ff0086;
}
.available_txt {
    margin: 0 8px 0 auto;
}
.hashtag_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
}
.chatroom_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}

//ChatRoomCard
.chatroom_card {
    width: 100%;
    height: 216px;
    padding: 32px;
    border-radius: 8px;
    background-color: #222;
}
.is_slide_item {
    width: unset;
    // width: 100%;
    // width: 320px;
}
.chatroom_title {
    width: 180px;
    word-break: break-all;
    margin-right: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.hashtag {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    margin: 4px;
    border-radius: 10px;
    border: solid 1px #aaaaaa;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
        border-color: #ff0086;
        color: #ff0086;
    }
    &.sm {
        height: 26px;
        margin: 0 4px 0 0;
        padding: 4px 12px 4px 12px;
        border-radius: 20px;
        border: 1px solid #666;
        font-size: 11px;
        color: #666;
        &:hover {
            border-color: #ff0086;
            color: #ff0086;
        }
    }
    &.xs {
        border: 1px solid #666;
        color: #666;
        font-size: 11px;
        height: 22px;
        &:hover {
            border-color: #ff0086;
            color: #ff0086;
        }
    }
}
.hashtag_small {
    height: 26px;
    margin: 0 4px 0 0;
    padding: 4px 12px 4px 12px;
    border-radius: 20px;
    border: 1px solid #666;
    font-size: 11px;
    color: #666;
}

.info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    .member {
        font-size: 12px;
        color: #aaa;
    }

    .time {
        font-size: 12px;
        color: #636363;
    }
}
