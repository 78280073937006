.header {
    position: fixed;
    width: 100%;
    max-width: 768px;
    margin: 64px 0 0;
    z-index: 10;
}

.nav_link {
    min-width: 100px;
    flex: 1;
    transition: 0.3s;

    &:hover {
        color: white;
    }
}
