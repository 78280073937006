// VideoFreeEnd
.btn_wrapper {
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.9);
    top: 0;
    left: 0;
    z-index: 5;
    white-space: pre-line;
}

.btn {
    margin-top: 20px;
    padding: 8px;
    width: 178px;
}
