// CreditCardSubmitForm
.saved_number_wrapper {
    width: 100%;
    padding: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    .saved_number {
        width: 100%;
        padding: 6px 12px;
        border: 1px solid #434343;
        border-radius: 5px;
        background-color: #fff;
        font-size: 1.5em;
        text-align: center;
        color: #000000;
    }
}

.next_button {
    width: 100%;
    padding: 20px 0;
    border-radius: 28px;

    .next_button_text {
        font-size: 2.14em;
        font-weight: 900;
        color: #fffefe;
    }
}

@media (max-width: 768px) {
    .saved_number_wrapper {
        padding: 18px;

        .saved_number {
            padding: 3px 6px;
            font-size: 0.75em;
        }
    }

    .next_button {
        padding: 10px 0;
        border-radius: 14px;

        .next_button_text {
            font-size: 1.07em;
        }
    }
}

// CreditCardSubmitForm
.card_info_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

// CardBrand
.card_img_box {
    width: 100%;
    padding: 24px 40px;
    vertical-align: top;
    display: flex;
    justify-content: flex-start;
    gap: 36px;

    .card_img {
        display: flex;
        justify-content: center;
        align-items: center;

        input[type='radio'] {
            display: none;
        }

        input[type='radio'] + label {
            outline: 1.2px solid #434343;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            .brand_img {
                width: 48px;
                height: 34px;
            }
        }

        input[type='radio']:checked + label {
            outline: 1.2px solid #ff0086;
        }
    }
}

@media (max-width: 768px) {
    .card_img_box {
        padding: 12px 20px;
        gap: 18px;
    }
}

.title {
    font-size: 1.78em;
    color: #232323;
}

.sub_title {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.4;
    color: #232323;
}

.explanation {
    font-size: 1.5em;
    line-height: 1.4;
    color: #232323;
    white-space: pre-line;
    word-break: break-word;
}

@media (max-width: 768px) {
    .title {
        font-size: 0.89em;
        color: #232323;
    }

    .sub_title {
        font-size: 0.75em;
    }

    .explanation {
        font-size: 0.75em;
    }
}

// CardNumber
.card_number_wrapper {
    width: 100%;
    padding: 28px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 36px;

    .numbers {
        width: 100%;
        padding: 0 24px;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));

        .number {
            width: 100%;
            padding: 6px 12px;
            border-top: 1px solid #434343;
            border-bottom: 1px solid #434343;
            background-color: #fff;
            font-size: 1.78em;
            text-align: center;
        }

        :first-child {
            border-left: 1px solid #434343;
            border-radius: 5px 0 0 5px;
        }

        :last-child {
            border-right: 1px solid #434343;
            border-radius: 0 5px 5px 0;
        }
    }
}

@media (max-width: 768px) {
    .card_number_wrapper {
        padding: 14px 6px;
        gap: 18px;

        .card_number_title {
            font-size: 0.89em;
        }

        .numbers {
            padding: 0 12px;

            .number {
                padding: 3px 6px;
                font-size: 0.89em;
            }
        }
    }
}

// CardInfo
.card_info_wrapper {
    width: 100%;
    padding: 28px 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 66px;

    .expire_wrapper {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 34px;

        .expire_numbers {
            width: 100%;
            padding: 0 0 0 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 42px;

            .expire {
                flex: 1;
                padding: 3px 12px;
                border: 0.6px solid #434343;
                border-radius: 5px;
                background-color: #fff;
                font-size: 1.78em;
            }
        }
    }

    .security_code_wrapper {
        padding: 0 24px 0 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 34px;

        .security_code {
            width: 100%;
            padding: 6px 12px;
            border: 1px solid #434343;
            border-radius: 5px;
            background-color: #fff;
            font-size: 1.78em;
            text-align: center;
        }
    }
}

@media (max-width: 768px) {
    .card_info_wrapper {
        padding: 14px 6px 0;
        gap: 33px;

        .expire_wrapper {
            flex: 2;
            gap: 17px;

            .expire_numbers {
                padding: 0 0 0 12px;
                gap: 21px;

                .expire {
                    flex: 1;
                    padding: 1px 6px;
                    font-size: 0.89em;
                }
            }
        }

        .security_code_wrapper {
            padding: 0 12px 0 0;
            flex: 1;
            gap: 17px;

            .security_code {
                padding: 3px 6px;
                font-size: 0.89em;
            }
        }
    }
}

// SecurityCodeExplanation
.security_code_explanation {
    width: 100%;
    padding: 36px 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

@media (max-width: 768px) {
    .security_code_explanation {
        padding: 18px 6px 0;
        gap: 8px;
    }
}

// CardHolder
.card_holder_wrapper {
    width: 100%;
    padding: 64px 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 36px;

    .holder_wrapper {
        width: 100%;
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .card_holder {
            width: 100%;
            padding: 6px 12px;
            border: 1px solid #434343;
            border-radius: 5px;
            background-color: #fff;
            font-size: 1.78em;
            text-align: center;
        }
    }

    .card_explanation {
        width: 100%;
        margin: 0 0 56px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
    }
}

@media (max-width: 768px) {
    .card_holder_wrapper {
        padding: 32px 6px 0;
        gap: 18px;

        .holder_wrapper {
            padding: 0 12px;

            .card_holder {
                padding: 3px 6px;
                font-size: 0.89em;
            }
        }

        .card_explanation {
            margin: 0 0 28px;
            gap: 6px;
        }
    }
}
