.title_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    a {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.3px;
        text-align: right;
        color: #aaa;
    }
}

.list_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.blog_col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}