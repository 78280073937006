// KeywordView
.keywords_box {
    width: 332px;
    padding: 10px 0;
    border-radius: 8px;
    box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.25);
    background-color: #444;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 60px;
}

.kc {
    padding: 8px 12px;
    color: #ff0086;
    font-size: 14px;
    font-weight: bold;
}

.keywords {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

// KeywordItem
.keyword {
    width: 100%;
    padding: 8px 12px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: #333;
        transition: all 0.2s ease-in-out;
    }
}

.keyword_name {
    margin-left: 4px;
}
