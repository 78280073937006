.profile_wrapper {
    padding: 48px 72px;
    border: 0.6px solid #434343;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .profile_title {
        flex: 1;
        font-size: 2.57em;
        font-weight: 900;
        color: #232323;
    }

    .logout {
        padding: 12px 24px;
        border-radius: 5px;
        background-color: #434343;

        .logout_text {
            font-size: 2em;
            font-weight: 900;
            color: #fefefe;
        }
    }
}

@media (max-width: 768px) {
    .profile_wrapper {
        padding: 24px 36px;
        border-radius: 14px;

        .profile_title {
            font-size: 1.28em;
        }

        .logout {
            padding: 6px 12px;

            .logout_text {
                font-size: 1em;
            }
        }
    }
}
