.wrapper {
    width: 100%;
    max-width: 768px;
    padding: 222px 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.schedule_title {
    width: 100%;
    overflow: hidden;
    white-space: pre-line;
    word-break: break-all;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.content {
    white-space: pre-line;
    word-break: break-all;
}

.date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
