.input_wrapper {
    position: relative;
    width: 328px;
    height: 36px;
    padding: 8px;
    border-radius: 4px;
    background-color: #fff;
    width: auto;
    display: flex;
    justify-content: flex-start;
}
.tag_list {
    display: flex;
    width: fit-content;
}
.input {
    flex: 1;
    width: 4px;
}
.tag {
    height: 24px;
    margin: 0 9px 0 0;
    padding: 0 8px;
    border-radius: 14px;
    font-size: 12px;
    background-color: #b8e6ff;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}
