@import 'styles/App.scss';

.wrapper {
    padding: 24px 12px 36px;

    .next_button {
        width: 100%;
        padding: 20px 0;
        border-radius: 28px;
        margin: 40px 0 0;
        background-color: #ff0086;

        .next_button_text {
            font-size: 2.14em;
            font-weight: 900;
            color: #fffefe;
        }
    }
}

@media (max-width: 768px) {
    .wrapper {
        padding: 12px 6px 18px;
        border-radius: 14px;

        .next_button {
            padding: 10px 0;
            border-radius: 14px;
            margin: 20px 0 0;

            .next_button_text {
                font-size: 1.07em;
            }
        }
    }
}

// PromotionPlan
.plan {
    position: relative;
    width: 100%;
    padding: 28px 0;
    border: 1px solid #eb0d6b;
    border-radius: 46px;
    margin: 0 0 44px;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.plan:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 35px 40px 0;
    border-color: #eb0d6b transparent;
    display: block;
    width: 0;
    z-index: 0;
    bottom: -36px;
    left: 50%;
    transform: translateX(-50%);
}

.plan::after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 35px 40px 0;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
}

.left {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    .detail_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 28px;

        .logo_wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .period_wrapper {
            justify-content: flex-start;
            gap: 24px;
        }

        .text {
            color: #eb497e;
            font-size: 2.14em;
            font-weight: bold;
        }
    }
}

.separator {
    width: 2px;
    height: 208px;
    background-color: #d9d9d9;
}

.right {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .plan_name {
        color: #444444;
        text-align: center;
        font-size: 2.85em;
    }

    .price_wrapper {
        justify-content: center;
        gap: 12px;
    }
}

.emphasis_wrapper {
    width: 100%;
    display: flex;
    align-items: flex-end;

    .emphasis {
        color: #eb497e;
        font-size: 5.35em;
        font-weight: 900;
    }

    .plain_text {
        color: #3b3b3b;
        font-size: 2.71em;
    }
}

@media (max-width: 768px) {
    .plan {
        border-radius: 23px;
    }

    .left {
        .detail_wrapper {
            gap: 14px;

            .period_wrapper {
                gap: 12px;
            }

            .text {
                font-size: 1.07em;
            }
        }
    }

    .separator {
        height: 118px;
        background-color: #d9d9d9;
    }

    .right {
        gap: 20px;

        .plan_name {
            font-size: 1.42em;
        }

        .price_wrapper {
            gap: 6px;
        }
    }

    .emphasis_wrapper {
        .emphasis {
            font-size: 2.67em;
        }

        .plain_text {
            font-size: 1.35em;
        }
    }
}

// Agreement
.agreements {
    width: 100%;
    padding: 36px 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 44px;

    .agreements_title {
        font-size: 3.57em;
        font-weight: 900;
        color: #232323;
    }

    .checkbox_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 40px;

        .check_box_wrapper {
            width: 100%;
            flex: 1;
        }

        .check_box_text {
            padding-left: 20px;
            margin: 0;
            font-size: 2.14em;
            font-weight: normal;
            color: #232323;
        }

        .agreement_wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .required_agree {
                color: #dd0000;
            }

            .term_button_text {
                font-size: 2.14em;
                font-weight: bold;
                color: #232323;
            }
        }
    }
}

@media (max-width: 768px) {
    .agreements {
        padding: 18px 12px 0;
        gap: 22px;

        .agreements_title {
            font-size: 1.78em;
        }

        .checkbox_list {
            gap: 20px;

            .check_box_text {
                padding-left: 10px;
                font-size: 1.07em;
            }

            .agreement_wrapper {
                .term_button_text {
                    font-size: 1.07em;
                }
            }
        }
    }
}

// Caution
.caution_wrapper {
    width: 100%;
    padding: 56px 12px 0;

    .caution {
        font-size: 1.5em;
        line-height: 1.4;
        color: #232323;

        span {
            font-weight: bold;
        }
    }
}

@media (max-width: 768px) {
    .caution_wrapper {
        padding: 28px 6px 0;

        .caution {
            font-size: 0.75em;
        }
    }
}
