.wrapper {
    width: 100%;
    padding: 220px 12px 0;
    margin: 0;
}

//MyArtistTab
.my-artist-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .title_left {
        h3 {
            margin-right: 8px;
            font-size: 24px;
            width: auto;
        }

        .active {
            display: flex;
            align-items: center;

            .selected {
                margin-left: 12px;
                font-size: 14px;
                line-height: 33px;
                color: #ff0086;
            }
        }
    }

    .title_right {
        span {
            font-size: 16px;
            line-height: 18px;
            color: #aaa;
            cursor: pointer;
        }

        .active {
            display: flex;
            align-items: center;
            gap: 24px;

            span:first-child {
                position: relative;

                &::before {
                    content: '';
                    width: 1px;
                    height: 12px;
                    background-color: #333;
                    position: absolute;
                    top: 2px;
                    right: -12px;
                }
            }
        }
    }
}

// MyArtistCollection > SortableList
.artist-list {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 8px;
    margin-right: -16px;
    transition: all 0.25s ease;

    &.on {
        overflow-x: hidden;
        margin-right: -28px;

        //li + li {
        //    &:after {
        //        content: '';
        //        position: absolute;
        //        top: 50%;
        //        transform: translateY(-50%);
        //        left: -14px;
        //        width: 2px;
        //        height: 96px;
        //        background-color: #222;
        //    }
        //}
    }
}

@media (max-width: 768px) {
    .artist-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

// SortableItem
.artist-item {
    position: relative;

    &.on {
        //margin-right: 12px;

        > div {
            margin-right: 13px;
            width: 174px;
            height: 176px;
            cursor: grab !important;

            div:nth-child(3) {
                display: none;
            }
        }
    }
}

.separator {
    position: relative;
    top: 40px;
    width: 2px;
    height: 96px;
    background-color: #222;
    margin-right: 13px;
}
