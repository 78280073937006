.sub_title_wrap {
    margin: 64px 0 0;
    background-color: #141414;
}

.st_box {
    height: 60px;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
        font-size: 18px;
        font-weight: bold;
        color: white;
    }
}

.sec_tab_box {
    border-bottom: 1px solid #222;
    margin: 0 0 1px;
}

.wrapper {
    width: 100%;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    [class~='active'] {
        border-bottom: 2px solid #fff;
        color: #fff;
        font-weight: 600;

        div {
            color: #fff;
        }
    }

    a {
        width: 100%;
        flex: 1;
        height: 58px;
        text-align: center;
        padding: 0;
        color: #666;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .wrapper {
        flex-wrap: nowrap;
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
