.plan_modal_container {
    padding-top: 16px;
    border-radius: 8px;
    background-color: #222;
}

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}

.plans {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.plans_info {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    line-height: 1.5;
    letter-spacing: -0.3px;

    span {
        color: #ff0086;
    }
}

.plans_info_vat {
    color: #999 !important;
}

.title {
    width: 100%;
    padding: 0 12px;
    text-align: center;
    font-size: 1.71em;
    font-weight: bold;
}

.plan_body {
    width: 100%;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.plan {
    cursor: pointer;
    width: 200px;
    padding: 8px;
    border-radius: 8px;
    border: solid 1px transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    &:last-of-type {
        margin-right: 0;
    }
}

.plan_detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.plan_type {
    width: 100%;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
}

@media (max-width: 768px) {
    .plan_modal_container {
        width: 80%;
    }

    .title {
        font-size: 1.28em;
    }

    .plans {
        flex-direction: column;
    }

    .plan {
        width: 100%;
        flex-direction: row;
    }

    .plan_type {
        text-align: start;
    }
}

.plan_list {
    width: 100%;
    padding: 0 0 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.plan_modal_footer {
    display: flex;
    flex: 1;
    width: 100%;

    button {
        width: 100%;
        border-radius: 0px;
        height: 48px;
    }
    a {
        width: 100%;
        border-radius: 0px;
        height: 48px;
    }
}

.plan_price {
    font-size: 1em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.3px;
    text-align: center;
    color: #666666;

    span {
        color: #ff0086;
        font-weight: bold;
    }
}

.cancel_btn {
    background-color: #666666;
}

.plan_list_item {
    text-align: start;
    list-style: disc;
    word-break: break-all;
    color: #999999;
    font-size: 0.85em;
    line-height: 20px;
    word-break: keep-all;
    word-wrap: break-word;
}
