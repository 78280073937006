// CandidateArtistCard
.artist_card_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

// ArtistCard
.artist_card {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    border: 1px solid #222;

    &.square {
        aspect-ratio: 1 / 1;
    }

    &.portrait {
        aspect-ratio: 2 / 3;
    }

    & .img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    & .blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        & .short {
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 24px;
        }

        & .fill {
            min-height: 22%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    & .voted_count {
        position: absolute;
        top: 8px;
        left: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        z-index: 3;

        & > img {
            width: 48px;
            height: 48px;
        }

        & > span {
            font-size: 17px;
            font-weight: 700;
        }
    }
}

// ArtistCardModal
.buttons {
    padding: 16px;

    & > div {
        width: 50%;
    }

    .separator {
        height: 70px;
        width: 1px;
        background-color: white;
        align-self: flex-end;
        margin: 0 12px;
    }

    .spacer {
        height: 17.3px;
    }

    .vote_button_image {
        width: 36px;
        height: 36px;
    }

    .button {
        &:disabled {
            cursor: not-allowed;
        }
    }
}

.card_wrapper {
    position: relative;
    width: 310px;
}

.stamp {
    position: absolute;
    z-index: 50;
    animation-name: stamp;
    animation-fill-mode: forwards;
    opacity: 0;
}

.point_text {
    z-index: -1;
    opacity: 0;
}

.show {
    animation: show 0.5s ease-in-out forwards;
    animation-delay: 1.6s;
}

.mystery_box_link {
    background-color: #ff0086;
    color: white;
    border-radius: 4px;
    padding: 0 12px;
    min-width: 100px;
    min-height: 40px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes stamp {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        transform: scale(1.4);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes show {
    0% {
        transform: translateY(40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
