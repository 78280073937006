.switch {
    width: 40px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: flex-start;
    border-radius: 100px;
    padding: 4px;
    align-items: center;
    cursor: pointer;
    &[data-isOn='true'] {
        justify-content: flex-end;
        background-color: #ff0086;
    }
}
.switch .handle {
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 40px;
}
