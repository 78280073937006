.wrapper {
    width: 100%;
    min-height: 100px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.comment_box_title_wrapper {
    width: 100%;
    height: 58px;
    padding: 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .left {
        min-width: 100px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .comment_box_title {
            font-size: 1.14em;
            font-weight: bold;
        }

        .comment_count {
            font-size: 0.85em;
            color: #ff0086;
        }
    }

    .close_icon {
        cursor: pointer;
    }
}

.hidden_scroll_comment_list {
    width: 100%;
    min-height: 100px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }
}

.comment_input {
    width: 100%;
    padding: 12px;
    background: #1f1f1f;
}
