@import 'styles/App.scss';

.praline_header {
    padding: 16px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(127, 44, 240);
}
.member_card {
    width: 100%;
}
.member_wrapper {
    position: relative;
    width: fit-content;
    height: fit-content;
}
.artist_image {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    object-fit: cover;
}
.selected {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: rgba(255, 0, 134, 0.7);
}
.disabled {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: rgba(128, 128, 128, 0.6);
}

.option_list {
    display: flex;
    gap: 12px;
    & button {
        flex: 1;
        border-radius: 8px;
    }
}

.info_row {
    font-weight: 500;
    padding: 16px 0;
    position: relative;
    & strong {
        color: $gray;
    }
}
.discount_text {
    position: absolute;
    right: 0;
    bottom: -8px;
}

.next_button {
    padding: 12px 0;
    border-radius: 28px;
    width: 100%;
}

.method_list {
    display: flex;
    flex-direction: column;

    li {
        align-items: center;
    }
    [type~='radio'] {
        border: 1.5px solid gray;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: transparent;
        margin-right: 10px;

        &:checked {
            border: 6px solid $main;
            background-color: white;
        }
    }
}

.member_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 26px;
}

.checkboxes {
    gap: 12px;
}

.dimm {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: black;
    top: 0;
    left: 0;
    opacity: 0.9;
    z-index: 99;
}

.loading {
    display: block;
    margin: 300px auto;
    width: 84px;
    height: 84px;

    path {
        fill: #ff0086;
    }
}

// TODO rmove
.caution {
    margin: 24px 0;
    word-break: break-all;
    line-height: 1.3;
    border-radius: 8px;
    background-color: #444;
    padding: 12px;
    font-size: 12px;
}

.termsection {
    word-wrap: break-word;
    p {
        line-height: 1.5;
        word-break: break-all;
    }
    h2 {
        margin: 24px 0 12px 0;
    }
    br {
        margin: 12px 0;
        line-height: 2;
    }
}
