.info_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.title_wrapper {
    min-width: 100px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

    .title {
        width: 100%;
        font-size: 1.71em;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        text-overflow: ellipsis;
    }

    .sub_title {
        width: 100%;
        font-size: 1em;
        color: #666666;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        text-overflow: ellipsis;
    }

    .schedule {
        width: 100%;
        color: #666666;
        font-size: 0.85em;
    }

    .descript {
        width: 100%;
        font-size: 1em;
        color: #666666;
        overflow: hidden;
        white-space: pre-line;
        word-break: break-all;
    }
}

@media (max-width: 768px) {
    .title_wrapper {
        .title {
            font-size: 1.28em;
        }

        .sub_title {
            font-size: 0.85em;
        }

        .descript {
            font-size: 0.85em;
        }
    }
}

.my_list_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .my_list {
        font-size: 0.85em;
    }
}
