.card_img_box {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    .card_img {
        display: flex;

        input[type="radio"] {
            width: 0 !important;
            height: 0 !important;
            padding: 0;
            border: 0;
            border-radius: 0;
            margin: 0;
            color: transparent;
            background-color: transparent;
            font-size: 0;
            line-height: inherit;
            letter-spacing: 0;
        }

        input[type="radio"]:focus {
            box-shadow: none;
            -webkit-box-shadow: none;
        }

        input[type="radio"]+label {
            display: block;
            position: relative;
            width: 100%;
            height: 44px;
            border: 2px solid transparent;
            border-radius: 5px;
            text-align: center;
        }

        input[type="radio"]:checked+label {
            border: 2px solid #ff0086;
            border-radius: 5px;
        }
    }
}

.inp_box {
    margin-top: 24px;

    input {
        width: 25%;
        height: 40px;
        border-top: 1pt solid #eee;
        border-bottom: 1pt solid #eee;
        border-left: 0;
        border-right: 0;
        margin-top: 12px;
        background-color: #fff;
        text-align: center;
        font-size: 14px;
        line-height: 38px;
        letter-spacing: -0.3px;
        color: #000;
    }

    input:first-of-type {
        width: 25%;
        padding-left: 12px;
        border-top: 1pt solid #eee;
        border-bottom: 1pt solid #eee;
        border-left: 1px solid #eee;
        border-right: 0;
    }

    input:last-of-type {
        width: 25%;
        padding-left: 12px;
        border-top: 1pt solid #eee;
        border-bottom: 1pt solid #eee;
        border-left: 0;
        border-right: 1px solid #eee;
    }

    input.big_size {
        width: 50%;
    }

    input.holder_name_input {
        width: 100%;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: #fff;
    }

    .noti_txt {
        display: block;
        position: relative;
        margin-top: 8px;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -.3px;
        color: #999;
    }

    .noti_txt:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin: 0 6px;
        background-color: #aaa;
        vertical-align: middle;
    }
}

.white .inp_box p {
    color: #000;
}

.row_box {
    .inp_box {
        display: inline-block;
        vertical-align: top;
        width: 66.6666%;

        select {
            width: calc(50% - 6px);
            height: 40px;
            padding: 0 12px;
            border: 1px solid #eee;
            margin-top: 12px;
            background-color: #fff;
            text-align: left;
            font-size: 14px;
            line-height: 38px;
            letter-spacing: -.3px;
            color: #000;
        }

        select:nth-child(3) {
            margin-left: 12px;
        }

        input {
            width: 100%;
            height: 40px;
            border-top: 1pt solid #eee;
            border-bottom: 1pt solid #eee;
            border-left: 0;
            border-right: 0;
            margin-top: 12px;
            background-color: #fff;
            text-align: center;
            font-size: 14px;
            line-height: 38px;
            letter-spacing: -0.3px;
            color: #000;
        }
    }

    .inp_box:nth-child(2) {
        width: calc(33.3334% - 12px);
        margin-left: 12px;
    }

    .noti_box {
        margin-top: 24px;

        p {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -.3px;
            color: #666;
            word-break: keep-all;
            word-wrap: break-word;

            strong {
                display: inline-block;
                font-size: 12px;
                line-height: 18px;
                color: #666;
                letter-spacing: -.3px;
            }
        }
    }
}

.white .row_box .noti_box p {
    color: #999;

    strong {
        color: #999;
    }
}

.card_noti_box {
    margin-top: 24px;

    p {
        display: block;
        position: relative;
        margin-top: 8px;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -.3px;
        color: #999;
    }
}

.next_btn {
    display: block;
    width: 100%;
    height: 56px;
    border-radius: 28px;
    margin-top: 36px;
    background-color: #ff0086;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -.3px;
    color: #fff;
}