// ExtraCommunity
.post_wrapper {
    padding: 222px 12px 0;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.select_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}

@media (max-width: 768px) {
    .select_wrapper {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
}

.select {
    width: 100%;
    min-width: 20%;
}

.post_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.notice {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

// ExtraCommunityPostCard
.main_blog_list {
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    width: 100%;
    position: relative;
    top: 0;
    border-radius: 8px;
    background-color: #222;
    transition: 0.2s;

    &:hover {
        top: -8px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    }
    &.main {
        width: calc(100% - 16px);
    }
}

.inner_col_2 {
    display: flex;
    flex-direction: column;
}

.title,
.txt {
    max-width: 426px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    transition: 0.3s;
}

.title {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
}

.txt {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #aaa;
}

.user_info {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 8px 0;
}

.user_profile {
    span {
        margin: 0 12px 0 4px;
        color: #aaa;
    }
}

.thumbnail_box {
    width: 88px;
    height: 88px;
    object-fit: cover;
    position: relative;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.row_2 {
    display: flex;
    align-items: center;
}

.count_txt {
    margin-right: 8px;
}

.hashtag_wrapper {
    margin-bottom: 8px;
}
