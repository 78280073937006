.col {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.selector_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrow_button {
    width: 36px;
    height: 36px;
    object-fit: contain;
}
.prev_button {
    background-image: url(/assets/img/btn-chart-slideleft.png);
}
.next_button {
    background-image: url(/assets/img/btn-chart-slideright.png);
}
.broadcast_time {
    margin-top: 8px;
}
//AlbumList
.broadcast_time {
    text-align: center;
    margin-bottom: 24px;
}
