// ScheduleList
.schedules_wrapper {
    width: 100%;
    padding: 256px 12px 0;
}

.smart_schedules_wrapper {
    padding-top: 184px;
}

.schedule_wrapper {
    width: 100%;
    border-bottom: 1px solid #272727;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &:last-child {
        border-bottom: 0;
    }
}

// ScheduleCard
.schedule_card {
    width: 100%;
    height: 80px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.img_wrapper {
    position: relative;
    width: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 4px;
    background-color: #aaa;
    object-fit: cover;
}

.default_image {
    width: 100%;
    aspect-ratio: 16 / 9;
    padding: 16px;
    border-radius: 4px;
    object-fit: contain;
    background-color: #aaa;
}

.live_badge {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 34px;
    height: 18px;
}
.hover {
    &:hover {
        border-color: #ff0086;
    }
}

.info {
    min-width: 100px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.schedule_title {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}
