.announce {
    width: 100%;
    padding: 36px 0 52px;
    border-radius: 28px;
    background-color: #161616;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;

    .announce_title_wrapper {
        width: 100%;
        padding: 0 28px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .announce_title {
            width: 100%;
            text-align: center;
            font-size: 3.92em;
            font-weight: 900;
            color: #ff0086;
        }

        .announce_description {
            width: 100%;
            text-align: center;
            color: #fffefe;
            font-size: 2.57em;
            font-weight: 500;
        }
    }

    .card_list {
        width: 100%;
        padding: 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        .announce_card {
            width: 20%;
            padding: 0 4px;
            aspect-ratio: 3 / 4;
            object-fit: cover;
        }
    }
}

@media (max-width: 768px) {
    .announce {
        padding: 18px 0 26px;
        border-radius: 14px;
        gap: 18px;

        .announce_title_wrapper {
            padding: 0 14px;
            gap: 10px;

            .announce_title {
                font-size: 1.96em;
            }

            .announce_description {
                font-size: 1.28em;
            }
        }

        .card_list {
            padding: 0 6px;

            .announce_card {
                padding: 0 2px;
            }
        }
    }
}
