.header {
    padding: 16px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    font-size: 16px;
    font-weight: bold;
}

.section {
    min-height: 100vh;
    padding: 24px;
}

.thumbnail {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 9;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 24px 0;
    & .button {
        display: block;
        padding: 16px 0;
        border-radius: 24px;
        text-decoration: none;
        color: white;
        text-align: center;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
}

.content {
    white-space: pre-line;
}

.bottom_button {
    width: 240px;
    border-radius: 24px;
    padding: 8px 0;
    font-size: 16px;
    font-weight: bold;
    margin: 24px auto 48px;
    align-self: center;
}
