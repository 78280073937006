.body {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo {
    margin-bottom: 72px;
}
.h1 {
    font-size: 96px;
    margin-bottom: 24px;
}
.h2 {
    word-break: break-all;
    text-align: center;
    padding: 0 12px;
}
.button {
    margin-top: 36px;
    padding: 8px 24px;
    border-radius: 24px;
    font-size: 24px;
}
