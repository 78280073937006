//ArtistCard
.card_wrapper {
    width: 100%;
    padding: 16px 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.artist_card {
    position: relative;
    width: 100%;
    min-width: 20%;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.effect {
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-16px);
    }
}

.image_wrapper {
    width: 100%;
    position: relative;

    .img {
        width: 100%;
        aspect-ratio: 1 / 1;
        opacity: 0.6;
        object-fit: cover;
    }
}

.blur {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .short {
        width: 100%;
        height: 36px;
    }
}

.content {
    width: 100%;
    height: 54px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bot {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .artist_name {
            flex: 1;
            font-size: 14px;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
        }

        .follow_wrapper {
            width: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .follow_text {
                font-size: 8px;
                font-weight: 500;
                text-align: center;
            }
        }
    }
}
