.download_wrapper {
    width: 100%;
    height: 200px;
    margin: 89px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app_download_wrapper {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.app_download_buttons {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

@media (max-width: 768px) {
    .app_download_buttons {
        flex-direction: column;
    }
}

.qr_wrapper {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.qr_code {
    width: 100px;
    height: 100px;
}
