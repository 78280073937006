// Coupon
.wrapper {
    padding: 96px 12px 0;
    margin: 0;

    .header {
        font-size: 24px;
        margin-bottom: 24px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        padding: 64px 12px 0;
    }
}

.form {
    max-width: 720px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input_row {
    display: flex;
    width: 100%;
    align-items: center;

    > div {
        flex-grow: 1;
        margin-right: 2rem;
    }

    button {
        padding: 12px 24px;
        border-radius: 24px;
        width: 100%;
        height: 36px;
        line-height: 12px;
    }
    input {
        background-color: white;
        color: black;
        padding: 12px 24px;
        margin-right: 16px;
    }
}

.list_items {
    margin-top: 20px;
    margin-bottom: 100px;

    li {
        width: 100%;
        position: relative;
        opacity: 0.5;
        padding-left: 20px;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        word-break: break-all;

        &:before {
            content: '';
            width: 4px;
            height: 4px;
            background-color: #aaa;
            top: 7px;
            left: 6px;
            position: absolute;
            border-radius: 50%;
        }
    }
}
