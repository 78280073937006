.dark {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
}

.h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #fff;
}

.white .h2 {
    color: #000;
}

.plan_box {
    width: 100%;
}

.plan_box .plan_inner {
    min-height: 210px;
    padding: 20px 24px 24px;
    border-radius: 8px;
    background-color: #272727;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.white .plan_box .plan_inner {
    background-color: #f6f6f6;
}

.plan_box .plan_inner .plan_top_box {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #272727;
}

.white .plan_box .plan_inner .plan_top_box {
    border-bottom: 1px solid #e3e3e3;
}

.plan_box .plan_inner .plan_top_box .tit_box {
    margin-bottom: 6px;
}

.plan_box .plan_inner .plan_top_box .tit_box strong {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #fff;
}

.white .plan_box .plan_inner .plan_top_box .tit_box strong {
    color: #000;
}

.plan_box .plan_inner .plan_top_box .tit_box p {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #fff;
}

.white .plan_box .plan_inner .plan_top_box .tit_box p {
    color: #000;
}

.plan_box .plan_inner .plan_top_box .tit_box p span {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #666;
}

.plan_box .plan_inner .plan_top_box .noti_txt {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #ff0086;
}

.p_color {
    color: #ff0086;
}

.plan_box .plan_inner .plan_bottom_box dl {
    display: block;
}

.plan_box .plan_inner .plan_bottom_box dl dt {
    display: inline-block;
    vertical-align: top;
    width: 80px;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.3px;
    color: #888;
}

.plan_box .plan_inner .plan_bottom_box dl dd {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 80px);
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.3px;
    color: #fff;
}

.white .plan_box .plan_inner .plan_bottom_box dl dd {
    color: #000;
}

.plan_box .plan_inner .plan_bottom_box .change_btn {
    display: block;
    width: 100%;
    border-radius: 4px;
    margin-top: 12px;
    background-color: #ff0086;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 36px;
    color: #fff;
}

.plan_box .plan_inner .plan_bottom_box .cancel_btn {
    display: block;
    width: 100%;
    border-radius: 4px;
    margin-top: 12px;
    background-color: #444;
    font-size: 14px;
    text-align: center;
    line-height: 36px;
    color: #fff;
}

.white .plan_box .plan_inner .plan_bottom_box .cancel_btn {
    background-color: #aaa;
}

.plan_box .plan_inner .plan_bottom_box p {
    padding: 8px 0 24px;
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.3px;
    color: rgba(255, 255, 255, 0.3);
}

.white .plan_box .plan_inner .plan_bottom_box p {
    color: #666;
}

.modal_container {
    width: 320px;
    padding-top: 16px;
    border-radius: 8px;
    background-color: #222;
    // max-height: 600px;
    overflow-y: scroll;
}

.modal_content {
    padding: 0 10px 20px;
}

.modal_footer {
    display: flex;
    flex: 1;
    width: 100%;

    button {
        width: 100%;
        border-radius: 0px;
        height: 48px;
    }
}
