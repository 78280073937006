/* total width */
.scrollbar::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
    background-color: #191919;
}

//.scrollbar::-webkit-scrollbar-track:hover {
//    background-color: #191919;
//}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #191919;
}

//.scrollbar::-webkit-scrollbar-thumb:hover {
//    background-color: #a0a0a5;
//    border: 4px solid #191919;
//}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
    display: none
}
